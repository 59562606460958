import React from "react";

export const SCROLL_BOTTOM_TOLERANCE = 30;

export const handleScroll = (
  scrollableContainerRef: React.MutableRefObject<HTMLDivElement | null>,
  setIsAutoScrollEnabled: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (scrollableContainerRef?.current) {
    const { scrollTop, scrollHeight, clientHeight } = scrollableContainerRef.current;

    setIsAutoScrollEnabled(scrollTop + clientHeight > scrollHeight - SCROLL_BOTTOM_TOLERANCE);
  }
};

export const scrollToTheBottom = (
  scrollableContainerRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  if (scrollableContainerRef?.current) {
    scrollableContainerRef.current?.scrollTo({
      top: scrollableContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  }
};
