import React from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { ASSETS } from "@/constants";
import Pages from "./Pages";

const Tos: React.FC = React.memo(() => {
  return (
    <Pages>
      <div className="[&_.rpv-core\_\_inner-pages]:!overflow-hidden">
        <Viewer fileUrl={ASSETS.TOS_PDF} />
      </div>
    </Pages>
  );
});

export default Tos;
