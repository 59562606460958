import { rem } from "@mantine/core";
import React from "react";

interface AddressBookIconProps extends React.ComponentPropsWithoutRef<"svg"> {
  size?: number | string;
}

export function Logo({ size, style, ...others }: AddressBookIconProps) {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <g clipPath="url(#clip0_3391_7237)">
        <rect width="45" height="45" rx="4" fill="#E35336" />
        <path
          d="M20.924 18.022L19.186 23.016H22.706L20.924 18.022ZM19.252 28.076V29H14.808V28.076H16.216L20.55 16.196H22.244L26.71 28.076H28.074V29H22.926V28.076H24.532L23.036 23.918H18.856L17.404 28.076H19.252ZM29.2538 27.79C29.2538 26.998 29.8918 26.36 30.6838 26.36C31.4758 26.36 32.1138 26.998 32.1138 27.79C32.1138 28.582 31.4758 29.22 30.6838 29.22C29.8918 29.22 29.2538 28.582 29.2538 27.79Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3391_7237">
          <rect width="45" height="45" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
