import React from "react";

interface TextBlockProps {
  title: string;
  subtitle: string;
  text: string;
}

const TextBlock: React.FC<TextBlockProps> = React.memo(
  ({ title, subtitle, text }) => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-[1280px] mt-6 mb-8 md:mt-32 md:mb-24 px-4 lg:px-8 xl:px-1 z-10">
      <div className="text-3xl lg:text-4xl xl:text-5xl">
        <div className="font-semibold leading-10 lg:leading-[4rem] dark:text-gray-100">
          {title}
        </div>
        <div className="font-light leading-10 lg:leading-[4rem]">
          {subtitle}
        </div>
      </div>
      <span className="text-lg xl:text-2xl !leading-10">{text}</span>
    </div>
  ),
);

export default TextBlock;
