import React from "react";
import dayjs from "dayjs";
import {
  ActionIcon,
  Checkbox,
  RenderTreeNodePayload,
  Tooltip,
  Tree,
  TreeNodeData,
} from "@mantine/core";
import {
  IconChevronDown,
  IconDownload,
  IconTrash,
  IconFilePlus,
  IconMailForward,
} from "@tabler/icons-react";
import { FileTreeItem, SelectedFileTreeItem } from "@common/types";

import { UseFormRegister } from "react-hook-form";
import { openUpload } from "@/components/modals";
import { useGetAppSettingsQuery } from "@/redux/api";

type SelectedRecord = Record<string, SelectedFileTreeItem>;

type FormValues = {
  search: string;
  selected: SelectedRecord;
};

interface CollectionsProps {
  // eslint-disable-next-line
  data: FileTreeItem[] | any; // TODO(Ihor Dubas): Replace any by real type
  register: UseFormRegister<FormValues>;
  onDelete: (file: FileTreeItem) => void;
  onDocumentClick: (file: FileTreeItem) => void;
  onDownload: (file: FileTreeItem) => void;
  onSendCopyClick: (document: FileTreeItem) => void;
  onSelectionChange: (
    id: string,
    parentFolderId: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  showDataAndActionButtons?: boolean;
  onFolderClick?: (folderName: string | React.ReactNode, folderId: string) => void;
}

const collectionMapFn = (file: FileTreeItem): TreeNodeData => {
  const { id, name, children } = file;

  return {
    value: id,
    label: name,
    children: Array.isArray(children) ? children.map(collectionMapFn) : children,
    nodeProps: { file },
  };
};

export const Folders: React.FC<CollectionsProps> = ({
  data,
  register,
  onDelete,
  onDocumentClick,
  onDownload,
  onSendCopyClick,
  onSelectionChange,
  showDataAndActionButtons = true,
  onFolderClick,
}) => {
  const collectionsTreeData = data.map(collectionMapFn);

  const { data: appSettings } = useGetAppSettingsQuery(undefined);

  const renderNodeFn = React.useCallback(
    ({ node, expanded, hasChildren, elementProps }: RenderTreeNodePayload) => {
      const file = node?.nodeProps?.file ?? {};
      const { id, parent_folder_id, created_at, updated_at } = file;

      const customElementProps = {
        ...elementProps,
        onClick: (e: React.MouseEvent) => {
          elementProps.onClick(e);

          if ("children" in file && onFolderClick) {
            onFolderClick(node.label, node.value);
          }
        },
      };

      return (
        <div
          {...customElementProps}
          className={`
            ${hasChildren ? "bg-slate-300 border border-gray-400 ml-0 py-4" : "bg-slate-100 ml-12 py-2"}
            grid ${showDataAndActionButtons || !("children" in file) ? "grid-cols-[auto_1fr_auto_auto_10rem_auto]" : "grid-cols-[1fr_auto]"}
            justify-start content-center gap-4 hover:bg-opacity-50 border-b border-slate-300 border-t border-t-slate-100 px-4 group
          `}
        >
          {/* We need to disable possibillity to select Folders for Improrting them from providers */}
          {showDataAndActionButtons || !("children" in file) ? (
            <div className="place-self-center">
              <Checkbox
                variant="outline"
                size="lg"
                {...register(`selected.${node.value}.isSelected`, {
                  onChange: onSelectionChange.bind(null, id, parent_folder_id),
                })}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          ) : null}
          <div
            className="justify-self-start self-center text-bottom hover:underline"
            onClick={() => onDocumentClick(file)}
          >
            {node.label}
          </div>
          {showDataAndActionButtons && (
            <>
              <div className="invisible group-hover:visible flex flex-row justify-center items-center gap-2">
                {hasChildren ? (
                  <>
                    <Tooltip label="Add Documents to Folder">
                      <ActionIcon
                        size="xl"
                        variant="transparent"
                        onClick={() => openUpload({ isFolderInputForced: true, collection: file })}
                      >
                        <IconFilePlus color="gray" size="2rem" stroke={1.5} />
                      </ActionIcon>
                    </Tooltip>
                    {appSettings?.FEATURE_COPY_COLLECTION_ENABLED ? (
                      <Tooltip label="Send copy to another user">
                        <ActionIcon
                          size="xl"
                          variant="transparent"
                          onClick={() => onSendCopyClick(file)}
                        >
                          <IconMailForward color="gray" size="2rem" stroke={1.5} />
                        </ActionIcon>
                      </Tooltip>
                    ) : null}
                  </>
                ) : (
                  <Tooltip label="Download">
                    <ActionIcon size="xl" variant="transparent" onClick={() => onDownload(file)}>
                      <IconDownload color="gray" size="2rem" stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                )}
                <Tooltip label="Delete">
                  <ActionIcon size="xl" variant="transparent" onClick={() => onDelete(file)}>
                    <IconTrash color="gray" size="2rem" stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </div>
              <div className="flex flex-col justify-center items-start w-full border-l border-slate-300 pl-2">
                <span className="text-gray-500">Uploaded</span>
                <div>{dayjs(created_at).format("L")}</div>
              </div>
              <div className="flex flex-col justify-center items-start w-full border-l border-slate-300 pl-2">
                <span className="text-gray-500">Edited</span>
                <div>{dayjs(updated_at).format("L, HH:mm")}</div>
              </div>
            </>
          )}
          <div className={`${expanded ? "rotate-180" : "rotate-0"} place-self-center`}>
            {hasChildren ? <IconChevronDown /> : null}
          </div>
        </div>
      );
    },
    [
      onDelete,
      onDocumentClick,
      onDownload,
      onSelectionChange,
      onSendCopyClick,
      onFolderClick,
      register,
      showDataAndActionButtons,
      appSettings,
    ]
  );

  return <Tree data={collectionsTreeData} renderNode={renderNodeFn} />;
};
