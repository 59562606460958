import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "@mantine/core";
import { LocalStorageService } from "@/services";
import { notifications } from "@mantine/notifications";
import api, { useLazyLogoutQuery } from "@/redux/api";
import { useAppDispatch } from "@/redux/hooks.ts";

interface SessionTimeoutProviderProps {
  children: React.ReactNode;
}

const FIFTEEN_MINUTES_MS = 15 * 60 * 1000;
const ONE_SECOND_MS = 1000;
const ONE_MINUTE_MS = 60 * 1000;

const SessionTimeoutProvider = ({ children }: SessionTimeoutProviderProps) => {
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [showCountdown, setShowCountdown] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  const dispatch = useAppDispatch();

  const [logout] = useLazyLogoutQuery();

  const navigate = useNavigate();

  useEffect(() => {
    let countdownIntervalId: NodeJS.Timeout | null = null;

    const clearCountdownInterval = () => {
      if (countdownIntervalId) {
        clearInterval(countdownIntervalId);
        countdownIntervalId = null;
      }
    };

    const handleTokenExpiry = () => {
      setSessionExpired(true);
      setShowCountdown(false);
      setTimeRemaining(null);
      clearCountdownInterval();
    };

    const checkTokenExpiry = () => {
      const timeLeftMs = LocalStorageService.getAuthTokenExpiryInMs();

      if (!timeLeftMs) {
        // No token expiry timestamp available
        setSessionExpired(false);
        setShowCountdown(false);
        setTimeRemaining(null);
        clearCountdownInterval();
        return;
      }

      if (timeLeftMs <= 0) {
        // Token has already expired
        handleTokenExpiry();
      } else if (timeLeftMs <= FIFTEEN_MINUTES_MS) {
        // Less than 15 minutes remaining
        setShowCountdown(true);
        setTimeRemaining(timeLeftMs);

        // Start or continue countdown interval if not already running
        if (!countdownIntervalId) {
          countdownIntervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
              if (!prevTime) {
                return null;
              }
              const newTime = prevTime - ONE_SECOND_MS;
              if (newTime <= 0) {
                // Time has run out
                handleTokenExpiry();
                return null;
              }
              return newTime;
            });
          }, ONE_SECOND_MS);
        }
      } else {
        // More than 15 minutes remaining
        setShowCountdown(false);
        setTimeRemaining(null);
        clearCountdownInterval();
      }
    };

    // Initial check
    checkTokenExpiry();

    // Set interval to check token expiry every minute
    const tokenCheckIntervalId = setInterval(() => {
      checkTokenExpiry();
    }, ONE_MINUTE_MS);

    // Cleanup function
    return () => {
      // Clear intervals on component unmount
      if (countdownIntervalId) {
        clearInterval(countdownIntervalId);
      }
      clearInterval(tokenCheckIntervalId);
    };
  }, []);

  // Helper function to format time remaining into "X minutes Y seconds"
  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.max(0, Math.floor(milliseconds / 1000));
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ${seconds} second${seconds !== 1 ? "s" : ""}`;
  };

  // TODO(Reinis,Aleksey): This is copy-pasted from another place. Refactor to use a common function.
  const handleLogOut = async () => {
    try {
      if (!sessionExpired) {
        await logout(undefined);
      }

      notifications.show({
        title: "Sign out successful.",
        message: "You have successfully signed out. Farewell.",
        color: "green",
      });

      LocalStorageService.removeAuthToken();
      LocalStorageService.removeMeta();
      LocalStorageService.removeSelectedDocumentId();
      // Reset the API state (to clear the cache) after a timeout
      // This is so that the API state reset triggers after we have already navigated to "/"
      // Otherwise, if we reset it before navigating, the API state reset will trigger a re-fetch
      // for the current page, which we don't want because we have removed auth and want to log out
      setTimeout(() => {
        dispatch(api.util.resetApiState());
      }, 1000);

      navigate("/");
    } catch (error) {
      console.error((error as Error).message);
    }
  };

  return (
    <>
      {showCountdown && timeRemaining !== null && (
        <Modal
          title={<span className="text-4xl">Your session is about to expire!</span>}
          size="md"
          radius="md"
          padding="xl"
          centered={true}
          closeOnClickOutside={false}
          withCloseButton={false}
          opened={showCountdown}
          onClose={() => setShowCountdown(false)}
        >
          <div className="flex flex-col gap-10">
            <span className="text-xl">
              Your session will expire in: <strong>{formatTime(timeRemaining)}</strong>.
            </span>
            <span className="text-base">
              You will be unable to continue your work once your session expires.{" "}
              <strong>Please sign out and sign back in to continue.</strong>
            </span>
            <div className="flex flex-row justify-end gap-4">
              <Button
                variant="outline"
                color={LocalStorageService.isOldUI() ? "violet" : "ar-accent"}
                onClick={() => setShowCountdown(false)}
              >
                Continue work for now
              </Button>
              <Button
                color={LocalStorageService.isOldUI() ? "violet" : "ar-accent"}
                onClick={() => {
                  setShowCountdown(false);
                  handleLogOut();
                }}
              >
                Sign Out now
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {sessionExpired && (
        <Modal
          title={<span className="text-4xl">Session Expired!</span>}
          size="md"
          radius="md"
          padding="xl"
          centered={true}
          closeOnClickOutside={false}
          withCloseButton={false}
          opened={sessionExpired}
          onClose={() => setSessionExpired(false)}
        >
          <div className="flex flex-col gap-10">
            <span className="text-base">
              Your session has expired. Please sign in again to continue your work.
            </span>
            <div className="flex flex-row justify-end gap-4">
              <Button
                variant={LocalStorageService.isOldUI() ? "default" : "accent"}
                color={LocalStorageService.isOldUI() ? "violet" : "primaryColor"}
                onClick={() => {
                  setSessionExpired(false);
                  handleLogOut();
                }}
              >
                Back to Homepage
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {children}
    </>
  );
};

export default SessionTimeoutProvider;
