import React, { memo } from "react";
import { ActionIcon, Tooltip } from "@mantine/core";
import { FileTreeItem } from "@common/types/File.ts";
import { useGetAppSettingsQuery } from "@/redux/api";

interface FileActionProps {
  document: FileTreeItem;
  onDelete: (document: FileTreeItem) => void;
  onDownload?: (document: FileTreeItem) => void;
  onSendCopy: (document: FileTreeItem) => void;
  onUpload?: (document: FileTreeItem) => void;
  className?: string;
}

export const FileActions: React.FC<FileActionProps> = memo(
  ({ document, className, onDelete, onDownload, onSendCopy, onUpload }) => {
    const { data: appSettings } = useGetAppSettingsQuery(undefined);

    return (
      <div
        className={`invisible group-hover:visible flex flex-row justify-center items-center gap-2 ${className}`}
      >
        {typeof onUpload === "function" ? (
          <Tooltip label="Add document" openDelay={1000}>
            <ActionIcon size="xl" variant="transparent" onClick={() => onUpload(document)}>
              <span className="material-symbols-outlined text-ar-dark">post_add</span>
            </ActionIcon>
          </Tooltip>
        ) : null}
        {typeof onDownload === "function" ? (
          <Tooltip label="Download" openDelay={1000}>
            <ActionIcon size="xl" variant="transparent" onClick={() => onDownload(document)}>
              <span className="material-symbols-outlined text-ar-dark">download</span>
            </ActionIcon>
          </Tooltip>
        ) : null}
        {appSettings?.FEATURE_COPY_COLLECTION_ENABLED && !document.parent_folder_id ? (
          <Tooltip label="Send copy" openDelay={1000}>
            <ActionIcon size="xl" variant="transparent" onClick={() => onSendCopy(document)}>
              <span className="material-symbols-outlined text-ar-dark">forward_to_inbox</span>
            </ActionIcon>
          </Tooltip>
        ) : null}
        <Tooltip label="Delete" openDelay={1000}>
          <ActionIcon size="xl" variant="transparent" onClick={() => onDelete(document)}>
            <span className="material-symbols-outlined text-ar-dark">delete</span>
          </ActionIcon>
        </Tooltip>
        {document.is_redacted ? (
          <Tooltip label="Redacted document, cleared from sensitive information">
            <span className="material-symbols-outlined text-ar-dark">text_compare</span>
          </Tooltip>
        ) : null}
      </div>
    );
  }
);
