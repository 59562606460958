import React from "react";
import { Viewer } from "@react-pdf-viewer/core";
import Pages from "./Pages";
import { ASSETS } from "@/constants";

const DPA: React.FC = React.memo(() => {
  return (
    <Pages>
      <div className="[&_.rpv-core\_\_inner-pages]:!overflow-hidden">
        <Viewer fileUrl={ASSETS.DPA_PDF} />
      </div>
    </Pages>
  );
});

export default DPA;
