import { FC } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Alert, Anchor, Button, PasswordInput, TextInput } from "@mantine/core";
import { FormLayout, PrivacyTosLinks } from "@/components/New/components";
import { useLazyRequestResetPasswordQuery, useLazyResetPasswordQuery } from "@/redux/api";
import { notifications } from "@mantine/notifications";
import { DEFAULT_HEADER, DEFAULT_HEADER_PREFIX, ROUTES } from "@/constants";
import { getErrorMessage } from "@/utils";
import { Helmet } from "react-helmet";

interface ResetPasswordForm {
  email: string;
  password: string;
  confirmPassword: string;
  details: string;
}

export const ResetPassword: FC = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const token = searchParams.get("token");

  const [
    requestResetPassword,
    { isFetching: isRequestPasswordFetching, isSuccess: isRequestPasswordSuccess },
  ] = useLazyRequestResetPasswordQuery();

  const [resetPassword, { isFetching: isResetPasswordFetching }] = useLazyResetPasswordQuery();

  const {
    clearErrors,
    control,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm<ResetPasswordForm>();

  const onNewPasswordSubmit = async ({ password, confirmPassword }: ResetPasswordForm) => {
    if (!token) {
      setError("details", {
        type: "manual",
        message: "The password reset link you used is invalid or has expired",
      });

      return;
    }

    if (password !== confirmPassword) {
      setError("details", {
        type: "manual",
        message: "Passwords do not match",
      });

      return;
    }

    const { isSuccess, error } = await resetPassword({ token, password });

    if (isSuccess) {
      notifications.show({
        title: "Password Reset Successful.",
        message:
          "Your password was updated successfully. You may now sign in with the new password.",
        color: "green",
      });

      navigate(ROUTES.SIGN_IN);
    }

    if (error) {
      setError("details", {
        type: "manual",
        message: getErrorMessage(error) || "Unknown error",
      });
    }
  };

  const onResetPasswordRequestSubmit = async ({ email }: ResetPasswordForm) => {
    const { error } = await requestResetPassword(email);

    if (error) {
      setError("details", {
        type: "manual",
        message: getErrorMessage(error) || "Unknown error",
      });
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {DEFAULT_HEADER_PREFIX}
          {DEFAULT_HEADER}
        </title>
      </Helmet>
      <FormLayout footer={<PrivacyTosLinks />}>
        {token ? (
          <form
            className="grid grid-row-[minmax(0,1fr)_auto_auto_auto] gap-10 max-w-96"
            onSubmit={(event) => {
              clearErrors();
              handleSubmit(onNewPasswordSubmit)(event);
            }}
          >
            <div className="font-medium text-4xl text-ar-dark">Confirm Password</div>
            <p className="text-ar-dark">Enter your new password and confirm it to process</p>
            <div className="flex flex-col gap-10">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <PasswordInput
                    {...field}
                    label={<span className="text-sm">New Password</span>}
                    size="lg"
                    required
                    error={errors.password?.message}
                    className="w-full"
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <PasswordInput
                    {...field}
                    label={<span className="text-sm">Confirm New Password</span>}
                    size="lg"
                    required
                    error={errors.password?.message}
                    className="w-full"
                  />
                )}
              />
              {errors.details ? (
                <Alert color="red" radius="md" title="Password Reset Failed" variant="light">
                  {errors.details.message}
                </Alert>
              ) : null}
            </div>
            <Button
              variant="accent"
              type="submit"
              loading={isResetPasswordFetching}
              size="lg"
              className="-mt-5"
            >
              Reset Password
            </Button>
          </form>
        ) : (
          <form
            className="grid grid-row-[minmax(0,1fr)_auto_auto_auto] gap-10 max-w-96"
            onSubmit={(event) => {
              clearErrors();
              handleSubmit(onResetPasswordRequestSubmit)(event);
            }}
          >
            <div className="font-medium text-4xl text-ar-dark">Reset Password</div>
            <p className="text-ar-dark">
              Enter the verified email address associated with your account
            </p>
            <div className="flex flex-col gap-5">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Please enter a valid email address",
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    size="lg"
                    tabIndex={1}
                    className="[&_.mantine-TextInput-label]:text-base"
                  />
                )}
              />
              {errors.email ? (
                <Alert color="red" radius="md" title="Email is invalid" variant="light">
                  <span>{errors.email.message}</span>
                </Alert>
              ) : null}
              {isRequestPasswordSuccess ? (
                <div>
                  <Alert color="green" title="Your password reset request was sent successfully!">
                    Please check your email to reset your password. In case you have any questions
                    or need assistance, please contact us at &nbsp;
                    <Anchor href="mailto:support@aracor.ai">support@aracor.ai</Anchor>.
                  </Alert>
                </div>
              ) : null}
              <Button variant="accent" type="submit" size="lg" loading={isRequestPasswordFetching}>
                Reset Password
              </Button>
            </div>
            <Link to={ROUTES.SIGN_IN} className="text-center text-ar-link">
              Go back to Sign in page
            </Link>
          </form>
        )}
      </FormLayout>
    </>
  );
};
