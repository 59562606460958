import React from "react";
import { TextBlock } from "@/components/Home/components/common";

const HowItWorks: React.FC = React.memo(() => {
  return (
    <div className="flex flex-col items-center w-full">
      <TextBlock
        title="How it works"
        subtitle="Streamlined document mastery in four steps"
        text="Aracor RIO streamlines the complexities of common business contracts.
          Elevate your business with precision and ease, as Aracor RIO becomes
          your right hand in reviewing and negotiating commercial agreements."
      />
      <div className="flex flex-col items-center w-full px-0 lg:px-8 xl:px-1">
        <div className="arc-info-card grid-rows-2 place-content-center justify-items-center w-full max-w-[1280px]">
          <div className="flex justify-center items-center overflow-x-auto">
            <div className="min-w-full h-full">
              <img
                alt="app example"
                src="/images/app-example.png"
                className="hidden sm:block max-w-[700px] md:max-w-full rounded-2xl"
              />
              <img
                alt="app example"
                src="/images/app-example-mobile.png"
                className="block sm:hidden w-full rounded-2xl"
              />
            </div>
          </div>
          <div className="grid grid-flow-col-2 gap-16 mt-16 mb-2">
            <div className="grid grid-cols-1 md:grid-cols-[20%_80%] mr-0 md:mr-12">
              <div className="place-self-start md:place-self-center bg-[url(/images/home-docx-pdf.png)] bg-center bg-cover h-28 lg:h-32 xl:h-40 w-28 lg:w-32 xl:w-40 mb-4 md:mb-0" />
              <div className="flex flex-col justify-center bg-gray-100 dark:bg-white/15 rounded-2xl p-8 w-full">
                <p className="text-xl xl:text-2xl font-semibold mb-4 my-0">
                  Upload documents for secure processing
                </p>
                <p className="lg:text-lg xl:text-xl text-slate-600 dark:text-slate-300 my-0">
                  Effortlessly enhance your document workflow by securely
                  uploading files for streamlined processing through RIO's
                  advanced AI capabilities.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-[20%_80%] mr-0 md:mr-12">
              <div className="place-self-start md:place-self-center bg-[url(/images/home-context.png)] bg-center bg-cover h-28 lg:h-32 xl:h-40 w-28 lg:w-32 xl:w-40 mb-4 md:mb-0" />
              <div className="flex flex-col justify-center bg-gray-100 dark:bg-white/15 rounded-2xl p-8 w-full">
                <p className="text-xl xl:text-2xl font-semibold mb-4 my-0">
                  Trust RIO to analyze document context
                </p>
                <p className="lg:text-lg xl:text-xl text-slate-600 dark:text-slate-300 my-0">
                  Experience the power of automated analysis as RIO delves into
                  document context, delivering valuable insights for informed
                  decision-making.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-[20%_80%] mr-0 md:mr-12">
              <div className="place-self-start md:place-self-center bg-[url(/images/home-chat.png)] bg-center bg-cover h-28 lg:h-32 xl:h-40 w-28 lg:w-32 xl:w-40 mb-4 md:mb-0" />
              <div className="flex flex-col justify-center bg-gray-100 dark:bg-white/15 rounded-2xl p-8 w-full">
                <p className="text-xl xl:text-2xl font-semibold mb-4 my-0">
                  Engage with RIO by asking questions and receiving prompt
                  answers
                </p>
                <p className="lg:text-lg xl:text-xl text-slate-600 dark:text-slate-300 my-0">
                  Foster efficient collaboration by engaging seamlessly with
                  RIO’s AI capabilities, posing questions and receiving prompt
                  answers that elevate your understanding of the documents at
                  hand.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default HowItWorks;
