import { Link, useMatch } from "react-router-dom";
import { DEFAULT_HEADER, DEFAULT_HEADER_PREFIX, ROUTES } from "@/constants";
import { Helmet } from "react-helmet";
import { PublicLayout } from "@/components/New/components";

export const SupportContent = () => (
  <div className="flex flex-row justify-center items-start p-10 pt-20">
    <div className="flex flex-col gap-4 w-2/3">
      <h1 className="text-4xl">Support</h1>

      <section>
        <h2 className="text-3xl my-4">Need Help? We’re Here for You!</h2>

        <p className="text-lg py-4">
          Thank you for using our web app and Word plugin (add-in)! Whether you have a question,
          need assistance, or want to provide feedback, we’re ready to help.
        </p>
      </section>

      <section>
        <h2 className="text-3xl my-4">How to Contact Us</h2>
        <p className="text-lg py-4">
          📧 Email us at{" "}
          <a href="mailto:support@aracor.ai" className="text-ar-link underline">
            support@aracor.ai
          </a>
        </p>
        <p className="text-lg py-4">
          Our team will respond to your query as quickly as possible, typically within 24–48 hours.
        </p>
      </section>

      <section>
        <h2 className="text-3xl my-4">How We Can Help</h2>
        <ul className="flex flex-col gap-4 list-disc ml-4">
          <li>Troubleshooting issues with the web app or Word plugin</li>
          <li>Guidance on using features effectively</li>
          <li>Reporting bugs or errors</li>
          <li>Offering feedback or requesting new features</li>
        </ul>
      </section>

      <section>
        <h2 className="text-3xl my-4">Looking For Quick Answers?</h2>
        <p className="text-lg py-4">
          Be sure to check out our{" "}
          <Link to={ROUTES.FAQ} className="text-ar-link underline">
            FAQ
          </Link>{" "}
          section for solutions to common questions.
        </p>
        <p className="text-lg py-4">
          Your feedback helps us improve, so don’t hesitate to reach out. Thank you for being part
          of our journey!
        </p>
      </section>
    </div>
  </div>
);

export const Support = () => {
  const isPublic = useMatch(ROUTES.SUPPORT_PUBLIC);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {DEFAULT_HEADER_PREFIX}
          {DEFAULT_HEADER}
        </title>
      </Helmet>
      {isPublic ? (
        <PublicLayout palette="light">
          <div className="h-full w-2/3">
            <SupportContent />
          </div>
        </PublicLayout>
      ) : (
        <SupportContent />
      )}
    </>
  );
};
