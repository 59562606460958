import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Accordion,
  TextInput,
  Checkbox,
  Table,
  Text,
  Tooltip,
  Button,
  List,
  Loader,
  HoverCard,
} from "@mantine/core";
import {
  useGetAppSettingsQuery,
  useLazyGetCollectionSendCopyEventsQuery,
  useSendCollectionCopyToUserMutation,
} from "@/redux/api";
import { FileTreeItem, SendCollectionCopyToUserForm } from "@common/types";
import { DEFAULT_ERROR_MESSAGE } from "@/components/common/constants";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import dayjs from "dayjs";

type SendCopyFormProps = {
  fileTreeItem: FileTreeItem;
  isFolder: boolean;
  itemName: string;
};

export const SendCopyForm: React.FC<SendCopyFormProps> = ({ fileTreeItem, isFolder, itemName }) => {
  const [sendCollectionCopyToUser, { isLoading }] = useSendCollectionCopyToUserMutation();

  const { data: appSettings } = useGetAppSettingsQuery(undefined);

  const [
    getCollectionSendCopyEventsQuery,
    { currentData: events, isFetching: isGetCollectionSendCopyEventsFetching },
  ] = useLazyGetCollectionSendCopyEventsQuery();

  const [errorText, setErrorText] = useState("");

  const { register, handleSubmit } = useForm<SendCollectionCopyToUserForm>({
    defaultValues: {
      recipientEmail: "",
      includeChatHistory: false,
      includeMarkupData: false,
      includeDueDiligenceSummary: false,
      includeDueDiligenceComparison: false,
    },
  });

  const onSubmit = async (data: SendCollectionCopyToUserForm) => {
    try {
      const { collection_id } = fileTreeItem;
      const {
        recipientEmail,
        includeChatHistory,
        includeMarkupData,
        includeDueDiligenceSummary,
        includeDueDiligenceComparison,
      } = data;
      const response = await sendCollectionCopyToUser({
        recipient_email: recipientEmail,
        collection_id: collection_id,
        include_chat_history: includeChatHistory,
        include_markup_data: includeMarkupData,
        include_due_diligence_summary: includeDueDiligenceSummary,
        include_due_diligence_comparison: includeDueDiligenceComparison,
      }).unwrap();
      notifications.show({
        title: "Copy sent successfully.",
        message: (
          <div>
            <p>
              A copy of the {isFolder ? "folder" : "document"} has been sent to {recipientEmail}.
            </p>
            <p>The following data was included in the copy:</p>
            <List size="xs">
              <List.Item>Folders: {response.data.copied_folder_count}</List.Item>
              <List.Item>Documents: {response.data.copied_document_count}</List.Item>
              <List.Item>Chat Messages: {response.data.copied_chat_message_count}</List.Item>
              <List.Item>References: {response.data.copied_reference_count}</List.Item>
              <List.Item>
                Review Suggestions: {response.data.copied_review_redlines_count}
              </List.Item>
              {appSettings?.FEATURE_DUE_DILIGENCE_SUMMARY_ENABLED ? (
                <>
                  <List.Item>
                    Due Diligence Summaries: {response.data.copied_due_diligence_summary_count}
                  </List.Item>
                  <List.Item>
                    Due Diligence Comparisons: {response.data.copied_due_diligence_comparison_count}
                  </List.Item>
                </>
              ) : null}
            </List>
          </div>
        ),
        color: "green",
      });
      modals.closeAll();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setErrorText(e.data.data.detail || DEFAULT_ERROR_MESSAGE);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4">
        <div className="text-lg">
          <span className="italic">Selected {isFolder ? "folder" : "document"}: </span>
          <Tooltip label={itemName}>
            <strong>{itemName}</strong>
          </Tooltip>
        </div>
        <Accordion
          onChange={(value) => {
            if (value === "sentCopyHistory") {
              getCollectionSendCopyEventsQuery({ collection_id: fileTreeItem.collection_id });
            }
          }}
        >
          <Accordion.Item key="sentCopyHistory" value="sentCopyHistory" className="bg-slate-200">
            <Accordion.Control className="hover:!bg-slate-300">
              Click here to show/hide sent copy history
            </Accordion.Control>
            <Accordion.Panel>
              {isGetCollectionSendCopyEventsFetching ? (
                <div className="flex gap-2 items-center">
                  <Loader size="xs" />
                  <Text size="sm">Loading...</Text>
                </div>
              ) : Array.isArray(events) && events.length ? (
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Recipient Email</Table.Th>
                      <Table.Th>Date Sent</Table.Th>
                      <Table.Th>More Details</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {events.map((event) => (
                      <Table.Tr key={event.id}>
                        <Table.Td>{event.recipient_user_email}</Table.Td>
                        <Table.Td>{dayjs(event.created_at).format("L, HH:mm")}</Table.Td>
                        <Table.Td>
                          <HoverCard>
                            <HoverCard.Target>
                              <Text td="underline">Details</Text>
                            </HoverCard.Target>
                            <HoverCard.Dropdown>
                              <List>
                                <List.Item>
                                  Copied folder count: {event.copied_folder_count}
                                </List.Item>
                                <List.Item>
                                  Copied document count: {event.copied_document_count}
                                </List.Item>
                                <List.Item>
                                  Copied chat message count: {event.copied_chat_message_count}
                                </List.Item>
                                <List.Item>
                                  Copied reference count: {event.copied_reference_count}
                                </List.Item>
                                <List.Item>
                                  Copied review redlines count: {event.copied_review_redlines_count}
                                </List.Item>
                                {appSettings?.FEATURE_DUE_DILIGENCE_SUMMARY_ENABLED ? (
                                  <>
                                    <List.Item>
                                      Copied due diligence summary count:{" "}
                                      {event.copied_due_diligence_summary_count}
                                    </List.Item>
                                    <List.Item>
                                      Copied due diligence comparison count:{" "}
                                      {event.copied_due_diligence_comparison_count}
                                    </List.Item>
                                  </>
                                ) : null}
                              </List>
                            </HoverCard.Dropdown>
                          </HoverCard>
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
              ) : (
                <Text size="sm">
                  You have not sent any copies of this {isFolder ? "folder" : "document"} yet.
                </Text>
              )}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
        {isFolder ? (
          <Text size="sm">
            The recipient will receive a copy of the folder, including all documents inside, as they
            are now. Any further changes to the folder or documents will not be reflected in the
            recipient's copy.
          </Text>
        ) : (
          <Text size="sm">
            The recipient will receive a copy of the document, as it is now. Any further changes to
            the document will not be reflected in the recipient's copy.
          </Text>
        )}
        <Text size="sm">
          Once the copy is sent, the recipient will receive an email notification.
        </Text>
        <TextInput
          {...register("recipientEmail", { required: "Recipient email is required" })}
          label="Recipient Email address"
          placeholder="Enter recipient's email"
          required
          type="email"
        />
        <Checkbox
          color="ar-accent"
          {...register("includeChatHistory")}
          label="Include Brief tool Chat History"
        />
        <Checkbox
          color="ar-accent"
          {...register("includeMarkupData")}
          label="Include Redline tool Suggestion data"
        />
        {appSettings?.FEATURE_DUE_DILIGENCE_SUMMARY_ENABLED ? (
          <>
            <Checkbox
              color="ar-accent"
              {...register("includeDueDiligenceSummary")}
              label="Include Due Diligence Summary data"
            />
            <Checkbox
              color="ar-accent"
              {...register("includeDueDiligenceComparison")}
              label="Include Due Diligence Comparison data"
            />
          </>
        ) : null}
        {errorText ? (
          <Text c="red" size="md">
            {errorText}
          </Text>
        ) : null}
        <Button variant="accent" type="submit" loading={isLoading}>
          Send
        </Button>
      </div>
    </form>
  );
};
