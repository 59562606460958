import React from "react";
import { ActionIcon, Textarea } from "@mantine/core";
import { useAddFeedbackMutation } from "@/redux/api";
import { Controller, useForm } from "react-hook-form";
import { useAppSelector } from "@/redux/hooks.ts";
import { getSelectedDocument } from "@/redux/slices";

export const FeedbackMessage: React.FC = () => {
  const [addFeedback, { isLoading }] = useAddFeedbackMutation();

  const { control, getValues, reset } = useForm<{
    feedback: string;
  }>();

  const selectedDocument = useAppSelector(getSelectedDocument);

  const handleSendFeedback = async (
    reaction: "LIKE" | "DISLIKE" | "NO_REACTION" = "NO_REACTION"
  ) => {
    if (selectedDocument) {
      await addFeedback({
        document_id: selectedDocument.id,
        feedback: getValues("feedback"),
        reaction,
      });

      reset({ feedback: "" });
    }
  };

  return (
    <>
      <span className="text-lg text-white">Feedback</span>
      <div className="grid grid-cols-[1fr_auto] gap-2 mt-2 p-2 bg-white rounded-md">
        <Controller
          name="feedback"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              placeholder="Please provide detailed feedback here about your experience with this tool to help us improve."
              disabled={isLoading}
              minRows={1}
              maxRows={3}
              className="[&_.mantine-Textarea-input]:border-none [&_.mantine-Textarea-input]:h-[100px] [&_textarea:focus]:bg-white [&_.mantine-Textarea-input::placeholder]:text-base [&_.mantine-Textarea-input::placeholder]:text-stone-500"
            />
          )}
        />
        <div className="flex flex-row gap-2 justify-end self-end">
          <ActionIcon variant="transparent" onClick={() => handleSendFeedback("DISLIKE")}>
            <span className="material-symbols-outlined text-ar-red">thumb_down</span>
          </ActionIcon>
          <ActionIcon variant="transparent" onClick={() => handleSendFeedback("LIKE")}>
            <span className="material-symbols-outlined text-ar-turquoise">thumb_up</span>
          </ActionIcon>
        </div>
      </div>
    </>
  );
};
