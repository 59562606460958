import dayjs from "dayjs";
import { saveAs } from "file-saver";
import api from "@/api.ts";

import { FileTreeItem } from "@common/types";
import { LocalStorageService } from "@/services/index.ts";

const stream_chat = async (
  url: string,
  onError?: (url: string) => void
): Promise<ReadableStreamDefaultReader<Uint8Array> | null> => {
  const token = LocalStorageService.getAuthToken();

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "text/plain",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      return response.body ? response.body.getReader() : null;
    } else {
      if (onError) onError(url);
      return null;
    }
  } catch {
    if (onError) onError(url);
    return null;
  }
};

const downloadChatHistory = async (document: FileTreeItem): Promise<boolean> =>
  api
    .get("/qa/download_chat_history", {
      headers: {
        accept: "application/json",
      },
      params: {
        collection_id: document.collection_id,
      },
      responseType: "blob",
    })
    .then(({ data, headers }) => {
      try {
        const date = dayjs().format("YYYYMMDD_HHmmss");

        // If there is a filename in the header, use that instead
        const regExpFilename = /filename="(?<filename>.*)"/;
        const headerFilename: string | null =
          regExpFilename.exec(headers["content-disposition"])?.groups?.filename ?? null;
        const filename =
          headerFilename ??
          `${
            document.name.substring(0, document.name.lastIndexOf(".")) || document.name
          }_chat_history_${date}.docx`;
        saveAs(data, filename);
      } catch {
        (() => {})();
      }

      return true;
    })
    .catch(() => false);

export default {
  downloadChatHistory,
  stream_chat,
};
