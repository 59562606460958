export interface UserSettings {
  preferred_brief_llm: string | null;
  preferred_redline_llm: string | null;
  preferred_app_llm: string | null;
  chat_mode: string | null;
  has_seen_welcome_tour: boolean | null;
  is_demo_account: boolean;
}

export interface UserSettingsUpdate {
  preferred_brief_llm?: string | null;
  preferred_redline_llm?: string | null;
  preferred_app_llm?: string | null;
  chat_mode?: string | null;
  reloadSuggestions?: boolean;
  has_seen_welcome_tour?: boolean | null;
}

export enum AvailableLLMs {
  GPT_4O = "gpt_4o",
  GPT_4O_MINI = "gpt_4o_mini",
  O1_PREVIEW = "o1_preview",
  O1_MINI = "o1_mini",
  CLAUDE_3_5_HAIKU = "claude_3_5_haiku",
  CLAUDE_3_5_SONNET_V1 = "claude_3_5_sonnet_v1",
  CLAUDE_3_5_SONNET = "claude_3_5_sonnet",
  MISTRAL_LARGE_2 = "mistral_large_2",
  LLAMA_3_2_90B_VISION = "llama-3.2-90b-vision-preview",
  GEMINI_1_5_PRO = "gemini-1.5-pro-latest",
  GEMINI_1_5_FLASH = "gemini-1.5-flash-latest",
  ARACOR_SLM = "aracor_slm",
}

export enum ChatMode {
  DISCOVERY_MODE = "DISCOVERY_MODE",
  PRO_MODE = "PRO_MODE",
}

export interface ModelChoice {
  label: string | JSX.Element;
  value: AvailableLLMs | null;
}
