import React from "react";
import { TextBlock } from "@/components/Home/components/common";

const AracorRIO: React.FC = React.memo(() => {
  return (
    <div className="relative grid grid-cols-1 place-content-center justify-items-center w-full rounded-b-3xl bg-gradient-to-br from-sky-200/50 via-sky-400/50 dark:via-purple-500/30 to-lime-400/50 dark:to-fuchsia-400/75">
      <div className="absolute top-0 h-4/6 w-full bg-gradient-to-b from-60% from-sky-100 dark:from-slate-700/100 to-sky-100/0" />
      <TextBlock
        title="Aracor RIO"
        subtitle="is the legal assistant you need"
        text="Introducing Aracor RIO, your virtual paralegal. Named in honor of
          flight officers who occupy the rear seat of aircraft such as the F-14
          Tomcat, and are responsible for communication and navigation while the
          pilot flies the plane."
      />
      <div className="grid grid-cols-1 md:grid-cols-2 place-content-between justify-items gap-4 w-full max-w-[1280px] px-4 lg:px-8 xl:px-1 pb-4 md:pb-8 lg:pb-16">
        <div className="arc-info-card grid grid-cols-1 z-10">
          <span className="text-lg xl:text-xl font-semibold">Aracor RIO for Lawyers</span>
          <ul className="flex-1 list-disc text-lg md:text-xl p-4">
            <li>Created by lawyers for lawyers</li>
            <li>Secure, private, accurate</li>
            <li>Contract review, drafting and editing</li>
            <li>Translate into different languages</li>
            <li>Streamlined client communications</li>
          </ul>
          <div className="bottom-0 bg-[url(/images/lawyers.jpg)] bg-cover w-full min-h-52 md:min-h-40 lg:min-h-72 rounded-3xl"></div>
        </div>
        <div className="arc-info-card grid grid-cols-1 z-10">
          <span className="text-lg xl:text-xl font-semibold">
            Aracor RIO for individuals and businesses
          </span>
          <ul className="flex-1 list-disc text-lg md:text-xl p-4">
            <li>Self-service contract review tools</li>
            <li>Document review and editing assistance</li>
            <li>Collaborate with your lawyer in real time</li>
            <li>Save time and money on legal expenses</li>
            <li>24/7 support</li>
          </ul>
          <div className="relative bottom-0 bg-[url(/images/business.jpg)] bg-cover min-h-52 md:min-h-40 lg:min-h-72 rounded-3xl"></div>
        </div>
      </div>
    </div>
  );
});

export default AracorRIO;
