import React from "react";
import { Button } from "@mantine/core";
import { IconCloudDownload } from "@tabler/icons-react";

interface DownloadButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

const DownloadButton = ({ onClick, children }: DownloadButtonProps) => (
  <Button
    color="#8b5cf6"
    rightSection={<IconCloudDownload stroke={1.5} />}
    size="md"
    onClick={onClick}
    className="min-h-10 my-4"
  >
    <span className="uppercase text-sm max-w-fit">{children}</span>
  </Button>
);

export default DownloadButton;
