import { useGetUserDataQuery, useGetUserSettingsQuery } from "@/redux/api";
import { Upload } from "@/components/New/components/General/components/Upload";
import { useTour } from "@reactour/tour";
import ActionCard from "./components/ActionCard";
import { isVisibleForDemoUser } from "@/utils/user";

export const Home = () => {
  const { data } = useGetUserDataQuery(undefined);

  const { setIsOpen } = useTour();

  const { data: userSettings } = useGetUserSettingsQuery();

  const isDemo = isVisibleForDemoUser(userSettings);

  return (
    <div className="flex flex-col gap-6 p-10 pt-20">
      <div className="w-2/3 mb-20">
        <span className={`${data ? "opacity-100" : "opacity-0"} transition-opacity`}>
          <span className="text-5xl leading-[3rem]">
            {data?.full_name ? `Hello, ${data?.full_name}. ` : "Hello."}
            <span>How may Aracor help you today?</span>
            {/* TODO(Ihor, Alexey, Mykola) Add 2 scenarios: for new and recurring users */}
            {/* {data?.full_name ? `Welcome, ${data?.full_name}. ` : "Welcome to Aracor. "} */}
            {/* <span>This is your first time here, so below are the things you can start with</span> */}
          </span>
        </span>
      </div>
      <div className={`grid ${isDemo ? "grid-cols-3" : "grid-cols-1"} gap-5`}>
        <ActionCard
          onClick={() => setIsOpen(true)}
          title="Run product demo"
          description="A quick interactive onboarding to show you around"
          icon="tooltip_2"
        />
        {isDemo && (
          <>
            <ActionCard
              title="Watch Instructional Videos"
              description="Product discovery with help of the people behind it"
              icon="play_arrow"
            />
            <ActionCard
              title="See what’s new"
              description="The latest release checklist with up-to-date platform’s features showcase"
              icon="description"
            />
          </>
        )}
      </div>
      <Upload />
    </div>
  );
};
