import { FC, PropsWithChildren, ReactNode } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "@/constants";

interface FormLayoutProps {
  footer?: ReactNode;
  customContent?: boolean;
}

export const FormLayout: FC<PropsWithChildren<FormLayoutProps>> = ({
  children,
  customContent,
  footer,
}) => {
  return (
    <div className="h-dvh w-dvw bg-ar-dark overflow-auto">
      <div className="h-full flex flex-col justify-center items-center">
        <div className="self-start absolute top-4 left-5 z-0">
          <Link to={ROUTES.APP} className="font-['FinancierText'] text-2xl mx-4 my-16">
            <span className="text-white">Aracor</span>
            <span className="text-ar-accent">.</span>
          </Link>
        </div>
        <div className="h-full flex flex-col justify-start items-center gap-0.5 mt-10 z-10">
          {customContent ? (
            children
          ) : (
            <div className="self-start px-20 py-10 bg-white rounded-md">{children}</div>
          )}
          <div className="flex-grow content-end">
            <div className="mt-auto">{footer || <div className="py-4" />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
