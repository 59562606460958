import api from "./api.ts";
import {
  ApiPayload,
  FileTreeItem,
  GetCollectionSendCopyEventsResponse,
  SendCollectionCopyToUserRequest,
  SendCollectionCopyToUserResponse,
  Token,
} from "@common/types";
import { saveAs } from "file-saver";
import { setSelectedDocument } from "@/redux/slices";
import { selectDocument } from "@/redux/thunk";
import { sortDates } from "@/utils";
import { LocalStorageService } from "@/services";
import { reviewApi } from "@/redux/api/review.ts";

export const documentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addDocumentsToCollection: builder.mutation<
      ApiPayload<void>,
      { collection_id: string; data: FormData }
    >({
      query: ({ collection_id, data }) => ({
        url: "documents/add_documents_to_collection",
        method: "POST",
        params: {
          collection_id,
        },
        body: data,
      }),
      invalidatesTags: ["Documents"],
    }),
    deleteDocument: builder.mutation<ApiPayload<void>, { id: string; parentId?: string }>({
      query: ({ id }) => ({
        url: "documents/delete_document",
        method: "DELETE",
        params: {
          document_id: id,
        },
      }),
      invalidatesTags: (_result, _error, { id, parentId }) =>
        parentId ? [{ type: "Documents", parentId }] : [{ type: "Documents", id }],
    }),
    deleteDocumentFolder: builder.mutation<ApiPayload<void>, { id: string }>({
      query: ({ id }) => ({
        url: "documents/delete_document_folder",
        method: "DELETE",
        params: {
          folder_id: id,
        },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: "Documents", id }],
    }),
    getDocumentBlob: builder.query<Blob, { id: string; name: string; storage_file_type: string }>({
      query: ({ id, name, storage_file_type }) => ({
        url: "documents/get_document_blob",
        params: {
          document_id: id,
          storage_file_type,
        },
        responseHandler: async (response) => saveAs(await response.blob(), name),
      }),
    }),
    getUserFileTree: builder.query<FileTreeItem[], void>({
      query: () => "documents/get_user_file_tree",
      transformResponse: (response: ApiPayload<{ file_tree: FileTreeItem[] }>): FileTreeItem[] =>
        response?.data
          ? response.data?.file_tree.sort((a, b) => sortDates(a.created_at, b.created_at))
          : [],
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          if (Array.isArray(data) && data.length) {
            const selectedDocumentId = LocalStorageService.getSelectedDocumentId();

            if (!selectedDocumentId) {
              dispatch(setSelectedDocument(data[0]));
            }

            try {
              data.forEach((document) => {
                if (document.id === selectedDocumentId) {
                  throw document;
                }

                if (Array.isArray(document.children)) {
                  const documentChild = document.children.find(
                    ({ id }) => id === selectedDocumentId
                  );

                  if (documentChild) {
                    throw documentChild;
                  }
                }
              });
            } catch (document) {
              if (document) {
                const selectedDocument = document as FileTreeItem;
                dispatch(
                  selectDocument({
                    id: selectedDocument.id,
                    collection_id: selectedDocument.collection_id,
                  })
                );
              }
            }
          }
        });
      },
      providesTags: (data) =>
        data
          ? [
              ...data.map(({ id }) => ({ type: "Documents" as const, id })),
              { type: "Documents", id: "LIST" },
            ]
          : [{ type: "Documents", id: "LIST" }],
    }),
    uploadDocument: builder.mutation({
      query: ({ data, ...options }) => ({
        url: "documents/upload_document",
        method: "POST",
        headers: {
          accept: "application/json",
        },
        params: { ...options },
        body: data,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data.data) {
            dispatch(
              // @ts-expect-error : TS issue for injected endpoints
              reviewApi.util.updateQueryData("getUserFileTree", undefined, (draft) => {
                const documents = data.data.documents.map((document: FileTreeItem) => ({
                  ...document,
                  name: document.document_name || document.name,
                }));

                if (data.data.folder) {
                  const folder = data.data.folder;

                  draft.push({
                    ...folder,
                    name: folder.folder_name || folder.name,
                    children: documents,
                  });
                } else {
                  draft.push(...documents);
                }
              })
            );
          }
        } catch {
          /* empty */
        }
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Documents", id }],
    }),
    generateOnlyOfficeToken: builder.query({
      query: ({ documentId }) => ({
        url: "documents/generate_onlyoffice_token",
        method: "POST",
        params: { document_id: documentId },
      }),
      transformResponse: (response: ApiPayload<Token>) => response?.data,
      providesTags: ["OnlyOfficeToken"],
    }),
    sendCollectionCopyToUser: builder.mutation<
      ApiPayload<SendCollectionCopyToUserResponse>,
      SendCollectionCopyToUserRequest
    >({
      query: ({
        recipient_email,
        collection_id,
        include_chat_history,
        include_markup_data,
        include_due_diligence_summary,
        include_due_diligence_comparison,
      }) => ({
        url: "documents/send_collection_copy_to_user",
        method: "POST",
        params: {
          recipient_email,
          collection_id,
          include_chat_history,
          include_markup_data,
          include_due_diligence_summary,
          include_due_diligence_comparison,
        },
      }),
    }),
    getCollectionSendCopyEvents: builder.query({
      query: ({ collection_id }) => ({
        url: "documents/get_collection_send_copy_events",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<GetCollectionSendCopyEventsResponse>) =>
        response?.data.collection_send_copy_events ?? [],
    }),
  }),
});

export const {
  useAddDocumentsToCollectionMutation,
  useDeleteDocumentMutation,
  useDeleteDocumentFolderMutation,
  useGetUserFileTreeQuery,
  useLazyGetDocumentBlobQuery,
  useLazyGetUserFileTreeQuery,
  useUploadDocumentMutation,
  useLazyGenerateOnlyOfficeTokenQuery,
  useSendCollectionCopyToUserMutation,
  useLazyGetCollectionSendCopyEventsQuery,
} = documentsApi;
