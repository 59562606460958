import axios from "axios";
import { matchPath } from "react-router-dom";

import { router } from "@/router";
import { LocalStorageService } from "@/services";
import { ROUTES } from "@/constants";

const api = axios.create({
  baseURL: "/api",
  timeout: 5 * 60 * 1000, // 5 minutes
});

const URLS_WITHOUT_TOKEN = [
  "/users/signup",
  "/users/token",
  "/users/auth/google/callback",
  "/users/confirm_email",
];

api?.interceptors.request.use((config) => {
  const token = LocalStorageService.getAuthToken();

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  } else if (config.url && URLS_WITHOUT_TOKEN.some((url) => config.url?.startsWith(url))) {
    return config;
  }

  throw new axios.Cancel(`Permission denied: ${config.url}`);
});

api?.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const navigate = router.navigate;
      const pathname = router.state.location.pathname;

      LocalStorageService.removeAuthToken();

      if (matchPath(pathname, ROUTES.SIGN_IN) || matchPath(pathname, ROUTES.SIGN_UP)) {
        return Promise.reject(error);
      }

      await navigate(ROUTES.SIGN_IN);
    }

    return Promise.reject(error);
  }
);

export default api;
