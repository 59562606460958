import { useState } from "react";
import {
  EmailConfirmationOverlay,
  FilesTree,
  Logo,
  Menu,
} from "@/components/New/components/General/components";
import { ActionIcon, ScrollArea, Tooltip } from "@mantine/core";
import { Link, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "@/constants";
import { useGetUserDataQuery } from "@/redux/api";
import clsx from "clsx/lite";

export const General = () => {
  const [isNavigationCollapsed, setIsNavigationCollapsed] = useState(false);

  const { pathname } = useLocation();

  const isApplication = pathname.startsWith(ROUTES.VIEWER);

  useGetUserDataQuery(undefined);

  return (
    <div className="h-dvh max-h-dvh w-dvw grid grid-cols-[auto_1fr] overflow-hidden">
      <div
        className={clsx(
          isNavigationCollapsed ? " w-16 min-w-16" : "w-72 min-w-72",
          "transition-all bg-ar-dark"
        )}
      >
        {/* Nav Collapsed */}
        <div className={clsx(!isNavigationCollapsed && "hidden", "p-2.5")}>
          <Tooltip label="Show menu" position="right" openDelay={1000}>
            <ActionIcon
              color="ar-accent"
              size="xl"
              onClick={() => setIsNavigationCollapsed(!isNavigationCollapsed)}
            >
              <Logo />
            </ActionIcon>
          </Tooltip>
        </div>
        {/* Nav Shown */}
        <div
          className={clsx(
            isNavigationCollapsed && "hidden",
            "h-dvh flex flex-col gap-12 p-5 pb-0.5"
          )}
        >
          <div className="flex flex-row justify-between items-center h-14 pr-2 pb-4 pl-4 border-b border-white border-opacity-10">
            <Link to="/" className="font-['FinancierText'] text-2xl">
              <span className="text-white">Aracor</span>
              <span className="text-ar-accent">.</span>
            </Link>
            <Tooltip label="Hide menu" position="right" openDelay={1000}>
              <ActionIcon
                variant="subtle"
                size="lg"
                className="hover:!bg-ar-gray/10"
                onClick={() => setIsNavigationCollapsed(!isNavigationCollapsed)}
              >
                <span className="material-symbols-outlined text-white">left_panel_close</span>
              </ActionIcon>
            </Tooltip>
          </div>
          {isApplication ? <FilesTree /> : <Menu />}
        </div>
      </div>
      <div className="relative h-dvh max-h-dvh overflow-hidden">
        <ScrollArea type="auto" h="100dvh">
          <Outlet />
        </ScrollArea>
        <EmailConfirmationOverlay />
      </div>
    </div>
  );
};
