import React, { Dispatch } from "react";
import { ChatHeader } from "../ChatHeader";
import Loader from "../Loader";
import { ActionIcon, Button } from "@mantine/core";
import { IconReload, IconX } from "@tabler/icons-react";
import MessageItem from "../MessageItem";
import { DueDiligenceMessage } from "@/common/types";

type Props = {
  isLoading: boolean;
  errorText: string | undefined;
  setErrorText: Dispatch<React.SetStateAction<string>>;
  handleRetry?: () => void;
  text?: string | undefined;
  documentSummaries?: DueDiligenceMessage[] | undefined;
};

const DueDiligenceTabContent: React.FC<Props> = ({
  isLoading,
  errorText,
  text,
  documentSummaries,
  setErrorText,
  handleRetry,
}: Props) => {
  return (
    <div className="grid grid-rows-[auto_auto_1fr_auto] h-full min-h-full w-full min-w-full bg-gradient-to-br from-indigo-500 to-sky-400 rounded-l-xl">
      <ChatHeader />
      {errorText && !isLoading && (
        <div
          className={`grid grid-cols-[1fr_auto] gap-2 mx-4 my-2 p-4 rounded-xl animate-[fade-in-keyframe_0.5s_ease-in-out] bg-red-200`}
        >
          <div>{errorText}</div>
          <div className="flex flex-row justify-between items-center">
            <ActionIcon
              key="close"
              variant="transparent"
              onClick={() => {
                setErrorText("");
              }}
            >
              <IconX className="text-gray-400" />
            </ActionIcon>
            {handleRetry && (
              <Button
                key="reload"
                rightSection={<IconReload className="text-gray-400" />}
                variant="transparent"
                onClick={handleRetry}
              >
                <span className="font-semibold uppercase">Retry</span>
              </Button>
            )}
          </div>
        </div>
      )}
      {isLoading && <Loader />}
      <div
        className="
          flex flex-col gap-3 flex-1
          overflow-y-auto mx-4
          [-webkit-scrollbar]:w-0.5 [-webkit-scrollbar-thumb]:!bg-slate-400"
      >
        {text && <MessageItem text={text} />}
        {documentSummaries &&
          documentSummaries.map((doc) => <MessageItem key={doc.document_id} text={doc.text} />)}
      </div>
    </div>
  );
};

export default DueDiligenceTabContent;
