import React from "react";
import { ActionIcon, Button } from "@mantine/core";
import { IconFileDescription, IconSettings, IconCloudDownload } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { openUpload } from "@/components/modals";
import { modals } from "@mantine/modals";
import ProviderModal from "@/components/modals/ProviderModal/ProviderModal";
import { providers } from "@/components/CloudProvider/components/ProviderIcons";
import { useGetAppSettingsQuery, useGetUserDataQuery } from "@/redux/api";
import { Modals } from "@/common/types";
import { ROUTES } from "@/constants";

export const Top: React.FC = React.memo(() => {
  const { data: appSettings } = useGetAppSettingsQuery(undefined);

  const { data: user } = useGetUserDataQuery(undefined, {});

  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate(ROUTES.SETTINGS);
  };

  const handleFileUploadClick = () => {
    openUpload();
  };

  const handleImportFromProviderClick = () => {
    {
      if (user) {
        navigate("../folders", { relative: "path" });

        modals.open({
          modalId: Modals.PROVIDERS,
          title: "Import from provider",
          size: "lg",
          children: (
            <ProviderModal
              providers={providers}
              connectedProviders={user?.external_providers_connected}
            />
          ),
        });
      }
    }
  };

  return (
    <>
      <div className="grid grid-cols-[12rem_1fr_1fr] content-center h-24">
        <div className="text-4xl font-semibold">Dashboard</div>
        <div className="flex flex-row justify-start items-center gap-4 px-4">
          <Button
            color="#8b5cf6"
            rightSection={<IconFileDescription stroke={1.5} />}
            size="md"
            onClick={handleFileUploadClick}
          >
            <span className="uppercase text-sm">Upload Document</span>
          </Button>
          {appSettings?.FEATURE_FILE_INTEGRATIONS_ENABLED && (
            <Button
              color="#8b5cf6"
              rightSection={<IconCloudDownload stroke={1.5} />}
              size="md"
              onClick={handleImportFromProviderClick}
            >
              <span className="uppercase text-sm">Import from provider</span>
            </Button>
          )}
        </div>
        <div className="flex flex-row justify-end items-center gap-4" style={{ zIndex: 200 }}>
          <ActionIcon color="#8b5cf6" size="xl" aria-label="Settings" onClick={handleSettingsClick}>
            <IconSettings stroke={1.5} />
          </ActionIcon>
        </div>
      </div>
    </>
  );
});
