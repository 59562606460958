export enum FetchingStatus {
  failed = "failed",
  idle = "idle",
  loading = "loading",
  succeeded = "succeeded",
}

export type TApplicationMode = "brief" | "redline";

export enum ThunkState {
  failed = "failed",
  idle = "idle",
  loading = "loading",
  succeeded = "succeeded",
}

export enum NotificationStatus {
  error = "error",
  info = "info",
  ghost = "ghost",
  success = "success",
  warning = "warning",
}

export enum ModeEnum {
  REDLINE = "redline",
  BRIEF = "brief",
  SUMMARY = "summary",
  COMPARISON = "comparison",
}
