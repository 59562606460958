import { Link, useLocation, useNavigate, useMatches } from "react-router-dom";
import api, {
  useGetAppSettingsQuery,
  useGetUserSettingsQuery,
  useLazyLogoutQuery,
} from "@/redux/api";
import { DEFAULT_HEADER, ROUTES } from "@/constants";
import { notifications } from "@mantine/notifications";
import { useAppDispatch } from "@/redux/hooks";
import { isVisibleForDemoUser } from "@/utils/user";
import { IconWordPlugin } from "@/components/icons";
import { Helmet } from "react-helmet";
import { HoverCard, NavLink } from "@mantine/core";

export const Menu = () => {
  const dispatch = useAppDispatch();
  const { data: appSettings } = useGetAppSettingsQuery(undefined);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const matches = useMatches();

  const [logout] = useLazyLogoutQuery();

  const { data: userSettings } = useGetUserSettingsQuery();

  const isActive = (id: string, pathnameMatches: string[]) => {
    return (
      (id == "home" && (pathname == ROUTES.APP || pathname == `${ROUTES.APP}/`)) ||
      (id != "home" && pathnameMatches.some((match) => pathname.startsWith(match)))
    );
  };

  const getTitle = (): string => {
    if (matches.length) {
      for (let i = matches.length - 1; i >= 0; i--) {
        const handle: unknown = matches[i]?.handle;
        const title = (handle as unknown as { title?: string })?.title;

        if (title) {
          return "Aracor AI — " + title;
        }
      }
    }

    return DEFAULT_HEADER;
  };

  //TODO (Mykola): move to separate utils or helpers and reuse it
  const handleLogout = async () => {
    await logout(undefined);

    notifications.show({
      title: "Sign out successful.",
      message: "You have successfully signed out. Farewell.",
      color: "green",
    });

    // Reset the API state (to clear the cache) after a timeout
    // This is so that the API state reset triggers after we have already navigated to "/"
    // Otherwise, if we reset it before navigating, the API state reset will trigger a re-fetch
    // for the current page, which we don't want because we have removed auth and want to log out
    setTimeout(() => {
      dispatch(api.util.resetApiState());
    }, 1000);
    navigate(ROUTES.HOME);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getTitle()}</title>
      </Helmet>
      <div className="flex flex-col justify-between items-stretch h-full">
        <div className="flex flex-col gap-3 mb-2">
          <NavLink
            active={isActive("home", [ROUTES.APP])}
            label="Home"
            variant="accent"
            rightSection={<span className="material-symbols-outlined">dashboard</span>}
            onClick={() => {
              navigate(ROUTES.APP);
            }}
          />
          <NavLink
            active={isActive("documents", [ROUTES.DOCUMENTS])}
            label="Files & Folders"
            variant="accent"
            rightSection={<span className="material-symbols-outlined">home_storage</span>}
            className="first-step"
            onClick={() => {
              navigate(ROUTES.DOCUMENTS);
            }}
          />
          {isVisibleForDemoUser(userSettings) && (
            <>
              <NavLink
                active={isActive("research", [ROUTES.RESEARCH])}
                label="Research"
                variant="accent"
                rightSection={<span className="material-symbols-outlined">manage_search</span>}
                onClick={() => {
                  navigate(ROUTES.RESEARCH);
                }}
              />
              <NavLink
                active={isActive("diligence", [ROUTES.DILIGENCE])}
                label="Due Diligence"
                variant="accent"
                rightSection={<span className="material-symbols-outlined">text_compare</span>}
                onClick={() => {
                  navigate(ROUTES.DILIGENCE);
                }}
              />
              <NavLink
                active={isActive("help", [ROUTES.LEGAL_HELP])}
                label="Legal Help"
                variant="accent"
                rightSection={<span className="material-symbols-outlined">gavel</span>}
                onClick={() => {
                  navigate(ROUTES.LEGAL_HELP);
                }}
              />
            </>
          )}
        </div>
        <div className="mt-auto flex flex-col gap-3">
          {appSettings?.FEATURE_MS_WORD_PLUGIN_ENABLED && (
            <NavLink
              active={isActive("word_plugin", [ROUTES.HOWTO_WORD_PLUGIN])}
              label="MS Word Plugin"
              variant="accent"
              color="shadow"
              rightSection={
                <IconWordPlugin
                  fill={
                    isActive("settings", [ROUTES.HOWTO_WORD_PLUGIN])
                      ? "rgb(255,255,255)"
                      : "rgba(255,255,255,.7)"
                  }
                />
              }
              className="second-step"
              onClick={() => navigate(ROUTES.HOWTO_WORD_PLUGIN)}
            />
          )}
          <HoverCard position="right" offset={24}>
            <HoverCard.Target>
              <NavLink
                active={isActive("help", [ROUTES.SUPPORT, ROUTES.FAQ])}
                label="Help"
                variant="accent"
                color="shadow"
                rightSection={<span className="material-symbols-outlined min-w">info</span>}
              />
            </HoverCard.Target>
            <HoverCard.Dropdown
              classNames={{
                dropdown: "flex flex-col justify-center items-start !bg-ar-dark !rounded-lg",
              }}
            >
              <NavLink
                label="Support"
                variant="accent"
                w={100}
                onClick={() => navigate(ROUTES.SUPPORT)}
              />
              <NavLink label="FAQ" variant="accent" w={100} onClick={() => navigate(ROUTES.FAQ)} />
              <NavLink label="DPA" variant="accent" w={100} onClick={() => navigate(ROUTES.DPA)} />
            </HoverCard.Dropdown>
          </HoverCard>
          <NavLink
            active={isActive("settings", [ROUTES.SETTINGS])}
            label="Settings"
            variant="accent"
            color="shadow"
            rightSection={<span className="material-symbols-outlined">settings</span>}
            onClick={() => navigate(ROUTES.SETTINGS)}
          />
          <NavLink
            label="Logout"
            rightSection={<span className="material-symbols-outlined text-white/50">logout</span>}
            variant="accent"
            color="shadow"
            onClick={handleLogout}
          />
          <div className="flex flex-row justify-between items-center px-3 pt-0.5 text-xs text-white/20 border-t border-white border-opacity-20">
            <Link to={ROUTES.TERMS}>Terms of Service</Link>
            <span>•</span>
            <Link to={ROUTES.PRIVACY}>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </>
  );
};
