import { Provider, Providers } from "@/common/types";

export const SharePointIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="25px" height="25px">
    <path fill="#13504f" d="M23 4A12 12 0 1 0 23 28A12 12 0 1 0 23 4Z" />
    <path fill="#13a3a9" d="M33.5 16A10.5 10.5 0 1 0 33.5 37A10.5 10.5 0 1 0 33.5 16Z" />
    <path fill="#1ddaea" d="M24 28A8 8 0 1 0 24 44A8 8 0 1 0 24 28Z" />
    <path
      fill="#027178"
      d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"
    />
    <path
      fill="#fff"
      d="M10.432,28.75v-2.298c0.426,0.349,2.391,1.048,2.9,1.048c0.299,0,2.138,0.088,2.138-1.041 c0-1.633-5.082-1.494-5.082-4.725c0-0.536,0.066-3.059,4.133-3.059c1.041,0,2.271,0.261,2.628,0.395v2.147 c-0.176-0.12-0.89-0.718-2.496-0.718c-1.877,0-2.04,0.883-2.04,1.041c0,1.359,4.998,1.544,4.998,4.711 c0,3.172-3.614,3.074-4.177,3.074C12.857,29.325,10.814,28.942,10.432,28.75z"
    />
  </svg>
);

export const OneDriveIcon = (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_87_7796" maskUnits="userSpaceOnUse" x="0" y="6" width="32" height="20">
      <path
        d="M7.82979 26C3.50549 26 0 22.5675 0 18.3333C0 14.1921 3.35322 10.8179 7.54613 10.6716C9.27535 7.87166 12.4144 6 16 6C20.6308 6 24.5169 9.12183 25.5829 13.3335C29.1316 13.3603 32 16.1855 32 19.6667C32 23.0527 29 26 25.8723 25.9914L7.82979 26Z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0_87_7796)">
      <path
        d="M7.83017 26.0001C5.37824 26.0001 3.18957 24.8966 1.75391 23.1691L18.0429 16.3335L30.7089 23.4647C29.5926 24.9211 27.9066 26.0001 26.0004 25.9915C23.1254 26.0001 12.0629 26.0001 7.83017 26.0001Z"
        fill="url(#paint0_linear_87_7796)"
      />
      <path
        d="M25.5785 13.3149L18.043 16.3334L30.709 23.4647C31.5199 22.4065 32.0004 21.0916 32.0004 19.6669C32.0004 16.1857 29.1321 13.3605 25.5833 13.3337C25.5817 13.3274 25.5801 13.3212 25.5785 13.3149Z"
        fill="url(#paint1_linear_87_7796)"
      />
      <path
        d="M7.06445 10.7028L18.0423 16.3333L25.5779 13.3148C24.5051 9.11261 20.6237 6 15.9997 6C12.4141 6 9.27508 7.87166 7.54586 10.6716C7.3841 10.6773 7.22358 10.6877 7.06445 10.7028Z"
        fill="url(#paint2_linear_87_7796)"
      />
      <path
        d="M1.7535 23.1687L18.0425 16.3331L7.06471 10.7026C3.09947 11.0792 0 14.3517 0 18.3331C0 20.1665 0.657197 21.8495 1.7535 23.1687Z"
        fill="url(#paint3_linear_87_7796)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_87_7796"
        x1="4.42591"
        y1="24.6668"
        x2="27.2309"
        y2="23.2764"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2086B8" />
        <stop offset="1" stopColor="#46D3F6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_87_7796"
        x1="23.8302"
        y1="19.6668"
        x2="30.2108"
        y2="15.2082"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1694DB" />
        <stop offset="1" stopColor="#62C3FE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_87_7796"
        x1="8.51037"
        y1="7.33333"
        x2="23.3335"
        y2="15.9348"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0D3D78" />
        <stop offset="1" stopColor="#063B83" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_87_7796"
        x1="-0.340429"
        y1="19.9998"
        x2="14.5634"
        y2="14.4649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16589B" />
        <stop offset="1" stopColor="#1464B7" />
      </linearGradient>
    </defs>
  </svg>
);

export const BoxComIcon = (
  <svg
    fill="#0061D5"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="25px"
    height="25px"
    viewBox="0 0 512 512"
  >
    <g id="8f1168dc195123f48a6bb7cb59003f22">
      <path
        d="M275.055,198.252c-37.993,0-70.986,21.362-87.66,52.724c-16.673-31.362-49.665-52.724-87.662-52.724
		c-22.333,0-42.939,7.379-59.521,19.832v-83.586h-0.01c-0.229-10.772-9.018-19.439-19.847-19.439S0.739,123.726,0.51,134.498H0.5
		v164.635h0.042c0.882,54.036,44.937,97.584,99.191,97.584c37.997,0,70.988-21.362,87.662-52.722
		c16.674,31.359,49.667,52.722,87.66,52.722c54.805,0,99.234-44.43,99.234-99.23C374.289,242.68,329.859,198.252,275.055,198.252z
		 M99.733,357.007c-32.873,0-59.521-26.647-59.521-59.521c0-32.878,26.648-59.524,59.521-59.524s59.523,26.646,59.523,59.524
		C159.256,330.359,132.606,357.007,99.733,357.007z M275.055,357.007c-32.871,0-59.52-26.647-59.52-59.521
		c0-32.878,26.648-59.524,59.52-59.524c32.873,0,59.525,26.646,59.525,59.524C334.58,330.359,307.928,357.007,275.055,357.007z
		 M507.766,365.499c6.288,8.753,4.466,20.959-4.191,27.463c-8.658,6.504-20.893,4.853-27.547-3.622h-0.004l-43.859-58.636
		L388.3,389.34l-0.009-0.009c-6.653,8.479-18.884,10.13-27.546,3.626c-8.658-6.504-10.479-18.709-4.192-27.463h-0.008l50.821-67.934
		l-50.821-67.934l0.008-0.004c-6.287-8.75-4.466-20.957,4.192-27.463c8.662-6.504,20.893-4.851,27.546,3.626l0.009-0.008
		l43.864,58.636l43.859-58.636l0.004,0.004c6.654-8.476,18.889-10.128,27.547-3.626c8.657,6.506,10.479,18.713,4.191,27.465
		l0.013,0.006l-50.821,67.934l50.821,67.934L507.766,365.499z"
      ></path>
    </g>
  </svg>
);

export const DropBoxIcon = (
  <svg
    width="25px"
    height="25px"
    viewBox="0 -1.5 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs></defs>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Color-" transform="translate(-800.000000, -363.000000)" fill="#0F82E2">
        <path
          d="M824,389.033255 L814.1195,397.34573 L800,388.053538 L809.7635,380.17347 L823.999971,389.033238 L838.2362,380.172109 L847.9997,388.05369 L833.8802,397.345881 L823.9997,389.033406 Z M814.1198,363 L800.0003,372.292191 L809.7638,380.17226 L824.0003,371.312475 L814.1198,363 Z M824.02895,390.821692 L814.11995,399.109976 L809.87945,396.318993 L809.87945,399.447132 L824.02895,408 L838.17845,399.447132 L838.17845,396.318993 L833.93795,399.109976 L824.02895,390.821692 Z M848,372.292343 L833.8805,363.000151 L824,371.312626 L838.2365,380.172411 L848,372.292343 Z"
          id="Dropbox"
        ></path>
      </g>
    </g>
  </svg>
);

// TODO: Move to better placement or recieve from BE in future phases
export const providers: Provider[] = [
  {
    id: Providers.SHAREPOINT,
    name: "Microsoft SharePoint",
    icon: SharePointIcon,
  },
  {
    id: Providers.ONEDRIVE,
    name: "Microsoft OneDrive",
    icon: OneDriveIcon,
  },
  {
    id: Providers.DROPBOX,
    name: "DropBox",
    icon: DropBoxIcon,
  },
  {
    id: Providers.BOX_COM,
    name: "Box.com",
    icon: BoxComIcon,
  },
];
