import { FileTreeItem } from "@common/types";
import React from "react";
import { ActionIcon, Card, Menu, Tooltip } from "@mantine/core";
import {
  IconDotsVertical,
  IconFilePencil,
  IconFileText,
  IconFolderShare,
} from "@tabler/icons-react";

interface DocProps {
  document: FileTreeItem;
  isProcessing: boolean;
  onDeleteClick: (document: FileTreeItem) => void;
  onDocumentClick: (document: FileTreeItem) => void;
  onDownloadClick: (document: FileTreeItem, redacted?: boolean) => void;
}

const DocumentCard: React.FC<DocProps> = React.memo(
  ({ document, isProcessing, onDeleteClick, onDocumentClick, onDownloadClick }) => {
    const isFolder = document?.children && document.children.length;

    const isRedacted = document?.document_redacted_extension;

    const documentType = isFolder ? (
      <Tooltip label="Folder">
        <IconFolderShare />
      </Tooltip>
    ) : isRedacted ? (
      <Tooltip label="Redacted document">
        <IconFilePencil />
      </Tooltip>
    ) : (
      <Tooltip label="Document">
        <IconFileText />
      </Tooltip>
    );

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
    };

    return (
      <Card
        radius="lg"
        className="h-32 max-h-32 w-full min-w-full
        hover:bg-indigo-200/50
        cursor-pointer"
        onClick={() => onDocumentClick(document)}
      >
        <div className="flex flex-row justify-between items-center h-full w-full pl-10 pr-0">
          <span
            className={`text-2xl max-w-72 truncate cursor-pointer ${
              isProcessing ? "animate-bounce" : ""
            }`}
          >
            {document.name}
            {document.document_original_extension ?? ""}
          </span>
          <div className="flex flex-col justify-between items-center h-full">
            <Menu withinPortal position="bottom-start" shadow="sm">
              <Menu.Target>
                <ActionIcon variant="subtle" color="gray" onClick={handleMenuClick}>
                  <IconDotsVertical />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown className="!rounded-xl">
                <Menu.Item
                  onClick={(event) => {
                    event.stopPropagation();
                    onDownloadClick(document);
                  }}
                >
                  Download original
                </Menu.Item>
                {isRedacted ? (
                  <Menu.Item
                    onClick={(event) => {
                      event.stopPropagation();
                      onDownloadClick(document, true);
                    }}
                  >
                    Download redacted
                  </Menu.Item>
                ) : null}
                <Menu.Item
                  onClick={(event) => {
                    event.stopPropagation();
                    onDeleteClick(document);
                  }}
                  color="red"
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <div className="text-gray-400">{documentType}</div>
          </div>
        </div>
      </Card>
    );
  }
);

export default DocumentCard;
