import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "@/App.tsx";
import Home from "@/components/Home";
import SignInWithGoogle from "@/components/SignInWithGoogle.tsx";
import NotAuthorized from "@/components/NotAuthorized.tsx";
import ConfirmEmail from "@/components/ConfirmEmail";
import { Layout } from "@/components/Rio";
import NotFound from "@/components/NotFound.tsx";
import ResetPassword, { EmailToResetForm, ResetPasswordForm } from "@/components/ResetPassword";
import ConfirmProvider from "./components/CloudProvider/ConfirmProvider";
import { WordPlugin } from "./components/HowTo";
import New, {
  DocumentsAndFolders,
  DueDiligence,
  Home as NewHome,
  LegalHelp,
  Research,
  SignUp as NewSignUp,
  SignIn as NewSignIn,
  DPA as NewDPA,
  Terms,
  Privacy as NewPrivacy,
  Settings as NewSettings,
  EmailConfirmed,
  ResetPassword as NewResetPassword,
  Support,
  WordPluginInstallationManual,
  Faq,
  FaqPublic,
} from "@/components/New";
import { ROUTES } from "@/constants";
import { useMemo } from "react";
import { getUIStyle } from "./redux/slices";
import { useAppSelector } from "./redux/hooks";
import { Dashboard, Settings, Rio } from "@/components/Rio";
import { DPA, Privacy, SignIn, SignUp, Tos } from "@/components";

const newRouter = createBrowserRouter([
  {
    Component: NotFound,
    path: "*",
  },
  {
    Component: App,
    path: "",
    children: [
      {
        Component: New,
        path: ROUTES.APP,
        children: [
          {
            Component: NewHome,
            path: "",
            handle: {
              title: "Home",
            },
          },
          {
            Component: Layout,
            path: ROUTES.VIEWER,
          },
          {
            Component: DocumentsAndFolders,
            path: `${ROUTES.DOCUMENTS}/:entity?`,
            handle: {
              title: "Files & Folders",
            },
          },
          {
            Component: Research,
            path: ROUTES.RESEARCH,
            handle: {
              title: "Research",
            },
          },
          {
            Component: DueDiligence,
            path: ROUTES.DILIGENCE,
            handle: {
              title: "Due Diligence",
            },
          },
          {
            Component: LegalHelp,
            path: ROUTES.LEGAL_HELP,
            handle: {
              title: "Legal Help",
            },
          },
          {
            Component: NewSettings,
            path: ROUTES.SETTINGS,
            handle: {
              title: "Settings",
            },
          },
          {
            Component: WordPluginInstallationManual,
            path: ROUTES.HOWTO_WORD_PLUGIN,
            handle: {
              title: "Word Plugin",
            },
          },
          {
            Component: NewDPA,
            path: ROUTES.DPA,
            handle: {
              title: "DPA",
            },
          },
          {
            Component: Terms,
            path: ROUTES.TERMS,
            handle: {
              title: "Terms",
            },
          },
          {
            Component: NewPrivacy,
            path: ROUTES.PRIVACY,
            handle: {
              title: "Privacy Policy",
            },
          },
          {
            path: ROUTES.SUPPORT,
            Component: Support,
            handle: {
              title: "Support",
            },
          },
          {
            path: ROUTES.FAQ,
            Component: Faq,
            handle: {
              title: "FAQ",
            },
          },
        ],
      },
      {
        Component: NewSignIn,
        path: "",
      },
    ],
  },
  {
    Component: NewResetPassword,
    path: ROUTES.RESET_PASSWORD,
    children: [
      {
        Component: EmailToResetForm,
        path: "request",
      },
      {
        Component: ResetPasswordForm,
        path: "",
      },
    ],
  },
  {
    path: ROUTES.FAQ_PUBLIC,
    Component: FaqPublic,
  },
  {
    path: ROUTES.SUPPORT_PUBLIC,
    Component: Support,
  },
  {
    path: ROUTES.SIGN_IN,
    Component: NewSignIn,
  },
  {
    path: ROUTES.SIGN_UP,
    Component: NewSignUp,
  },
  {
    path: ROUTES.DPA_PUBLIC,
    Component: NewDPA,
  },
  {
    path: ROUTES.TERMS_PUBLIC,
    Component: Terms,
  },
  {
    path: ROUTES.PRIVACY_PUBLIC,
    Component: NewPrivacy,
  },
  {
    path: ROUTES.HOWTO_WORD_PLUGIN_PUBLIC,
    Component: WordPluginInstallationManual,
  },
  {
    path: ROUTES.GOOGLE,
    Component: SignInWithGoogle,
  },
  {
    path: ROUTES.NOT_AUTHORIZED,
    Component: NotAuthorized,
  },
  {
    path: ROUTES.CONFIRM_EMAIL,
    Component: EmailConfirmed,
  },
  {
    path: ROUTES.SHARE_POINT_CALLBACK,
    Component: ConfirmProvider,
  },
  {
    path: ROUTES.ONE_DRIVE_CALLBACK,
    Component: ConfirmProvider,
  },
  {
    path: ROUTES.DROP_BOX_CALLBACK,
    Component: ConfirmProvider,
  },
]);

export const router = createBrowserRouter([
  {
    Component: NotFound,
    path: "*",
  },
  {
    Component: App,
    path: "",
    children: [
      {
        Component: Rio,
        path: ROUTES.APP,
        children: [
          {
            Component: Layout,
            path: "",
          },
          {
            Component: Dashboard,
            path: `${ROUTES.DOCUMENTS}/:entity?`,
          },
          {
            Component: Settings,
            path: ROUTES.SETTINGS,
          },
          {
            Component: WordPlugin,
            path: ROUTES.HOWTO_WORD_PLUGIN,
          },
          {
            Component: DPA,
            path: ROUTES.DPA,
          },
          {
            Component: Tos,
            path: ROUTES.TERMS,
          },
          {
            Component: Privacy,
            path: ROUTES.PRIVACY,
          },
        ],
      },
      {
        Component: Home,
        path: "",
      },
    ],
  },
  {
    Component: ResetPassword,
    path: ROUTES.RESET_PASSWORD,
    children: [
      {
        Component: EmailToResetForm,
        path: "request",
      },
      {
        Component: ResetPasswordForm,
        path: "",
      },
    ],
  },
  {
    path: ROUTES.SUPPORT,
    Component: Support,
  },
  {
    path: ROUTES.SIGN_IN,
    Component: SignIn,
  },
  {
    path: ROUTES.SIGN_UP,
    Component: SignUp,
  },
  {
    path: ROUTES.GOOGLE,
    Component: SignInWithGoogle,
  },
  {
    path: ROUTES.NOT_AUTHORIZED,
    Component: NotAuthorized,
  },
  {
    path: ROUTES.CONFIRM_EMAIL,
    Component: ConfirmEmail,
  },
  {
    path: ROUTES.SHARE_POINT_CALLBACK,
    Component: ConfirmProvider,
  },
  {
    path: ROUTES.ONE_DRIVE_CALLBACK,
    Component: ConfirmProvider,
  },
  {
    path: ROUTES.DROP_BOX_CALLBACK,
    Component: ConfirmProvider,
  },
  {
    path: ROUTES.BOX_COM_CALLBACK,
    Component: ConfirmProvider,
  },
]);

const Navigation = () => {
  const uiStyle = useAppSelector(getUIStyle);

  const currentRouter = useMemo(() => (uiStyle === "new" ? newRouter : router), [uiStyle]);

  return <RouterProvider router={currentRouter} />;
};

export default Navigation;
