import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Loader } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ROUTES } from "@/constants";
import { useLazyConfirmEmailQuery } from "@/redux/api";
import { FormLayout } from "@/components/New/components";

export const EmailConfirmed = () => {
  const [searchParams] = useSearchParams();

  const [message, setMessage] = useState("Confirming your email...");

  const heightMatched = useMediaQuery("(min-height: 56.25em)");

  const [confirmEmail, { isSuccess, isFetching }] = useLazyConfirmEmailQuery();

  useEffect(() => {
    const token = searchParams.get("token");

    if (!token) {
      setMessage("No email confirmation token provided.");
      return;
    }

    confirmEmail(token)
      .then((result) => {
        if (result.isSuccess) {
          setMessage("Your email address has been confirmed!");
        } else {
          setMessage(result?.error ? (result.error as string) : "Email confirmation failed");
        }
      })
      .catch((error) => {
        setMessage((error as Error).message);
      });
  }, [confirmEmail, searchParams]);

  const inputSize = heightMatched ? "xl" : "lg";

  return (
    <FormLayout>
      <div className="flex flex-col items-center justify-center gap-10">
        <div className="text-2xl">{message}</div>
        {isFetching ? <Loader type="dots" color="ar-accent" className="mt-4" /> : null}
        {isSuccess ? (
          <Button
            variant="accent"
            radius="md"
            size={inputSize}
            component={Link}
            to={ROUTES.SIGN_IN}
          >
            Proceed to Sign In
          </Button>
        ) : null}
      </div>
    </FormLayout>
  );
};
