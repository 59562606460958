import { Dispatch, SetStateAction } from "react";
import { Button } from "@mantine/core";

interface CustomButtonProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  currentStep: number;
  stepsLength: number;
}

export const CustomNextButton: React.FC<CustomButtonProps> = ({
  setCurrentStep,
  stepsLength,
  currentStep,
}) => {
  const isLastStep = currentStep === stepsLength - 1;

  return (
    <Button
      variant="transparent"
      onClick={() => {
        if (!isLastStep) {
          setCurrentStep((prev) => prev + 1);
        }
      }}
    >
      <span
        className="material-symbols-outlined"
        style={{ fontVariationSettings: isLastStep ? "" : '"FILL" 1' }}
      >
        arrow_circle_right
      </span>
    </Button>
  );
};

export const CustomPrevButton: React.FC<CustomButtonProps> = ({ setCurrentStep, currentStep }) => {
  const isFirstStep = currentStep === 0;

  return (
    <Button
      variant="transparent"
      onClick={() => setCurrentStep((prev: number) => Math.max(prev - 1, 0))}
    >
      <span
        className="material-symbols-outlined"
        style={{ fontVariationSettings: isFirstStep ? "" : '"FILL" 1' }}
      >
        arrow_circle_left
      </span>
    </Button>
  );
};

export const stepsData = [
  {
    selector: ".first-step",
    content: "This is main navigation panel to switch between the platform models",
  },
  {
    selector: ".second-step",
    content: "This is the secondary navigation panel to navigate to settings or logout",
  },
];
