import { FC, PropsWithChildren } from "react";
import clsx from "clsx/lite";

interface CodeProps {
  red?: boolean;
}
const Code: FC<PropsWithChildren<CodeProps>> = ({ children, red }) => (
  <code
    className={clsx(
      "bg-gray-100 font-mono text-sm px-2 py-1 rounded-md shadow-sm",
      red ? "text-ar-accent" : "text-purple-600"
    )}
  >
    {children}
  </code>
);
export default Code;
