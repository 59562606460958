import React, { memo } from "react";
import { Button, Textarea, TextInput } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useSaveUserCriteriaMutation } from "@/redux/api";
import { Controller, useForm } from "react-hook-form";
import { notifications } from "@mantine/notifications";
import { useAppSelector } from "@/redux/hooks";
import { getUIStyle } from "@/redux/slices";

interface AddUserCriteriaProps {
  documentId: string;
}

interface AddUserCriteriaFormValues {
  criteria: string;
  document_id: string;
  name: string;
}

export const AddUserCriteria: React.FC<AddUserCriteriaProps> = memo(({ documentId }) => {
  const [saveUserCriteria] = useSaveUserCriteriaMutation();

  const uiStyle = useAppSelector(getUIStyle);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<AddUserCriteriaFormValues>({
    defaultValues: {
      criteria: "",
      document_id: documentId,
      name: "",
    },
  });

  const handleSaveUserCriteria = async (data: AddUserCriteriaFormValues) => {
    const { criteria, name } = data;

    if (name.length && criteria.length) {
      notifications.show({
        id: "SAVE_USER_CRITERIA_IN_PROGRESS",
        loading: true,
        title: "Saving",
        message: `Saving custom playbook "${name}"...`,
      });

      await saveUserCriteria(data);

      notifications.hide("SAVE_USER_CRITERIA_IN_PROGRESS");
      notifications.show({
        title: "Playbook Saved",
        message: `Custom playbook "${name}" saved successfully.`,
        color: "green",
      });
      modals.closeAll();
    } else if (!name.length) {
      setError("name", { message: "Name is missing" });
    } else if (!criteria.length) {
      setError("criteria", { message: "Criteria is missing" });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSaveUserCriteria)}>
      <div className="flex flex-col gap-2 w-full">
        <Controller
          name="name"
          control={control}
          render={({ field }) => <TextInput {...field} label="Name" error={errors.name?.message} />}
        />
        <Controller
          name="criteria"
          control={control}
          render={({ field }) => (
            <Textarea {...field} label="Criteria" error={errors.criteria?.message} />
          )}
        />
        {uiStyle === "new" ? (
          <div className="mt-4">
            <Button type="submit" fullWidth variant="filled" color="ar-accent">
              Add
            </Button>
          </div>
        ) : (
          <div className="flex flex-row justify-end items-center gap-2 mt-4">
            <Button variant="transparent" onClick={modals.closeAll}>
              Cancel
            </Button>
            <Button type="submit">Add</Button>
          </div>
        )}
      </div>
    </form>
  );
});
