import { FC, SVGProps } from "react";

interface IconWordPluginProps extends SVGProps<SVGSVGElement> {
  size?: number;
  fill?: string;
}

export const IconWordPlugin: FC<IconWordPluginProps> = ({ size = 24, fill = "#FFFFFF" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon-24">
        <mask id="mask0_4001_815" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect id="Bounding box" width="24" height="24" fill={fill} />
        </mask>
        <g mask="url(#mask0_4001_815)">
          <g id="Vector">
            <path
              d="M19 19H12V21H19C19.55 21 20.0208 20.8042 20.4125 20.4125C20.8042 20.0208 21 19.55 21 19V5C21 4.45 20.8042 3.97917 20.4125 3.5875C20.0208 3.19583 19.55 3 19 3H5C4.45 3 3.97917 3.19583 3.5875 3.5875C3.19583 3.97917 3 4.45 3 5V9H5V5H19V19Z"
              fill={fill}
            />
            <path d="M7 9V7H17V9H7Z" fill="white" />
            <path d="M12 11V13H17V11H12Z" fill={fill} />
            <path d="M12 15V17H14V15H12Z" fill={fill} />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 11C0.895431 11 0 11.8954 0 13V19C0 20.1046 0.89543 21 2 21H8C9.10457 21 10 20.1046 10 19V13C10 11.8954 9.10457 11 8 11H2ZM6.31508 16.1292L5.77091 13.375H4.27856L3.75913 16.1382C3.62721 16.8512 3.553 17.6356 3.553 17.6356H3.52002C3.52002 17.6356 3.44582 16.8601 3.3139 16.1292L2.81095 13.375H1.5L2.50589 18.625H4.25383L4.78151 16.2095C4.93816 15.4786 4.97939 14.7031 4.97939 14.7031H5.01237C5.01237 14.7031 5.04535 15.4875 5.19376 16.2006L5.68846 18.625H7.49411L8.5 13.375H7.23852L6.76031 16.1382C6.63663 16.8423 6.57067 17.6356 6.57067 17.6356H6.53769C6.53769 17.6356 6.45524 16.8601 6.31508 16.1292Z"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconWordPlugin;
