import { ROUTES } from "@/constants";

const NotAuthorized = () => (
  <div className="flex flex-col justify-center items-center bg-white h-screen w-screen text-slate-800">
    <div className="text-2xl py-8">ERROR</div>
    <p className="text-xl">
      You need to be authorized to continue. <a href={ROUTES.SIGN_IN}>Sign In</a>
    </p>
  </div>
);
export default NotAuthorized;
