import React from "react";
import { ActionIcon, Button, Progress, Tooltip } from "@mantine/core";
import {
  getChatNotificationById,
  getChatErrorNotificationById,
  getMode,
  getSelectedDocument,
  setLastExplainProcessingTimestamp,
  setLastMarkupProcessingTimestamp,
  clearChatNotifications,
} from "@/redux/slices";
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts";
import { IChatNotification, ModeEnum } from "@/common/types";
import { LocalStorageService } from "@/services";

const notificationsMap = new Map<"ghost" | "info" | "success" | "warning" | "error", string>([
  ["ghost", "bg-white"],
  ["info", "bg-gray-200"],
  ["success", "bg-green-100"],
  ["warning", "bg-orange-100"],
  ["error", "bg-red-200"],
]);

interface ChatNotificationProps {
  collectionId: string;
}

const ChatNotification: React.FC<ChatNotificationProps> = React.memo(({ collectionId }) => {
  const dispatch = useAppDispatch();

  const mode = useAppSelector(getMode);

  const selectedDocument = useAppSelector(getSelectedDocument);

  const normalNotification = useAppSelector((state) =>
    getChatNotificationById(state, collectionId)
  );

  const errorNotification = useAppSelector((state) =>
    getChatErrorNotificationById(state, collectionId)
  );

  const actionsMap = new Map([
    [
      "close",
      <Tooltip key="close" label="Close">
        <ActionIcon
          variant="subtle"
          onClick={() => {
            dispatch(clearChatNotifications(collectionId));
          }}
        >
          <span className="material-symbols-outlined text-ar-dark">close</span>
        </ActionIcon>
      </Tooltip>,
    ],
    [
      "reload",
      <Tooltip key="reload" label="Retry">
        <Button
          rightSection={<span className="material-symbols-outlined text-ar-dark">refresh</span>}
          variant="subtle"
          onClick={async () => {
            if (selectedDocument) {
              switch (mode) {
                case ModeEnum.BRIEF:
                  dispatch(setLastExplainProcessingTimestamp(Date.now()));
                  break;
                case ModeEnum.REDLINE:
                  dispatch(setLastMarkupProcessingTimestamp(Date.now()));
                  break;
                default:
                  break;
              }
            }
          }}
        >
          <span className="font-semibold uppercase text-ar-dark">Retry</span>
        </Button>
      </Tooltip>,
    ],
  ]);

  const renderNotification = (notification: IChatNotification | null) => {
    if (!notification?.message) return null;

    const { actions, message, progress, type } = notification;
    const highlightingClass = notificationsMap.get(type || "ghost");

    const accentColor = LocalStorageService.isOldUI() ? "" : "ar-accent";

    return (
      <div>
        <div
          className={`grid grid-cols-[1fr_auto] gap-2 mx-4 my-2 p-4 rounded-md animate-[fade-in-keyframe_0.5s_ease-in-out]
          ${message ? "visible" : "hidden"} ${highlightingClass}`}
        >
          <div className="flex flex-col">
            <div>{message}</div>
            <div className="flex flex-row justify-end items-center">
              {actions?.length ? [...actions].map((action) => actionsMap.get(action)) : null}
            </div>
          </div>
          {progress ? (
            <Progress value={100} animated color={accentColor} className="col-span-2" />
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderNotification(normalNotification)}
      {renderNotification(errorNotification)}
    </div>
  );
});

export default ChatNotification;
