import { AxiosResponse } from "axios";
import api from "@/api.ts";

import { EStorageFileType } from "@common/types";

const getDocumentBlob = async (
  documentId: string,
  storageFileType: EStorageFileType,
  apply_markup: boolean = false
): Promise<Blob | null> => {
  try {
    return api
      .get("/documents/get_document_blob", {
        headers: {
          accept: "application/json",
        },
        params: {
          document_id: documentId,
          storage_file_type: storageFileType,
          apply_redlines: apply_markup,
        },
        responseType: "blob",
      })
      .then(({ data, status }: AxiosResponse<Blob>) => (status === 200 ? data : null))
      .catch(() => Promise.reject(null));
  } catch {
    return null;
  }
};

const FilesService = {
  getDocumentBlob,
};

export default FilesService;
