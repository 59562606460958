import { FormLayout } from "@/components/New/components";
import { Faq } from "@/components/New";

export const FaqPublic = () => {
  return (
    <FormLayout customContent>
      <div className="w-2/3 mx-auto my-5 p-24 bg-white rounded-md">
        <Faq />
      </div>
    </FormLayout>
  );
};
