import api from "./api.ts";
import { ApiPayload } from "@common/types";

export const feedbackApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addFeedback: builder.mutation({
      query: ({ document_id, feedback, reaction }) => ({
        url: "feedback/add_feedback",
        method: "POST",
        headers: {
          accept: "application/json",
        },
        params: {
          document_id,
          feedback,
          reaction,
        },
      }),
      transformResponse: (response: ApiPayload<boolean>) => response?.data,
    }),
  }),
});

export const { useAddFeedbackMutation } = feedbackApi;
