import React, { useEffect } from "react";
import { Logo } from "@/components/common";
import {
  Alert,
  Anchor,
  Button,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  useMantineColorScheme,
} from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { useLazyGetUserDataQuery, useLazySignInQuery } from "@/redux/api";
import GoogleAuthButton from "@/components/SignIn/components/GoogleAuthButton.tsx";
import { IconAlertOctagon } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import ThemeService from "@/services/theme.ts";
import { Controller, useForm } from "react-hook-form";
import { LocalStorageService } from "@/services";
import { ROUTES } from "@/constants";
import { getErrorMessage } from "@/utils";

const SignIn: React.FC = () => {
  const { colorScheme } = useMantineColorScheme();

  const [getUserData] = useLazyGetUserDataQuery();

  const [signIn, { isFetching }] = useLazySignInQuery();

  const heightMatched = useMediaQuery("(min-height: 56.25em)");

  const navigate = useNavigate();

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    setError,
    handleSubmit,
  } = useForm<{
    username: string;
    password: string;
    details: string;
  }>();

  useEffect(() => {
    ThemeService.checkAndApplyTheme();
  }, [colorScheme]);

  useEffect(() => {
    if (LocalStorageService.getAuthToken()) {
      getUserData(undefined)
        .unwrap()
        .then((userData) => {
          if (userData) {
            navigate(ROUTES.APP);
          }
        });
    }
  }, [getUserData, navigate]);

  async function onFormSubmit() {
    const { error } = await signIn(getValues());

    if (error) {
      setError("details", {
        type: "manual",
        message: getErrorMessage(error) || "Unknown error",
      });
    } else {
      navigate(ROUTES.DOCUMENTS);
    }
  }

  const inputSize = heightMatched ? "xl" : "lg";

  return (
    <section
      className={`grid grid-cols-1 md:grid-cols-2 grid-rows-[6rem_1fr_1fr]
      h-dvh w-dvw min-w-[390px]
      px-2 md:px-6 lg:px-24 pb-2 md:pb-4 lg:pb-16
      bg-stone-200 dark:bg-slate-800`}
    >
      <div
        className={`col-span-2
        flex flex-row justify-between items-center`}
      >
        <Link to="/" className="">
          <Logo />
        </Link>
      </div>
      <div
        className={`row-span-2
        grid place-items-center
        px-0 py-8 
        bg-white dark:bg-slate-700
        rounded-l-3xl rounded-r-3xl md:rounded-r-none
        overflow-y-auto`}
      >
        <form
          onSubmit={(event) => {
            clearErrors();
            handleSubmit(onFormSubmit)(event);
          }}
        >
          <div className="block md:hidden pb-8 font-semibold text-3xl text-left">Sign In</div>
          <Stack className="[&>*]:box-border">
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label="Username or Email address"
                  radius="md"
                  size={inputSize}
                  required
                  error={errors.username?.message}
                  tabIndex={1}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  label={
                    <div className="flex flex-row justify-between items-center w-full">
                      <div>
                        Password<span className="text-red-500 ml-2">*</span>
                      </div>
                      <Anchor component={Link} to={`${ROUTES.RESET_PASSWORD}/request`} tabIndex={3}>
                        Forgot Password?
                      </Anchor>
                    </div>
                  }
                  radius="md"
                  size={inputSize}
                  required
                  error={errors.password?.message}
                  tabIndex={2}
                  className="[&_.mantine-InputWrapper-label]:w-full [&_.mantine-PasswordInput-required]:hidden"
                />
              )}
            />
            <div className="my-2">
              {errors.details ? (
                <Alert
                  color="red"
                  icon={<IconAlertOctagon />}
                  radius="md"
                  title={<Text fw={500}>Sign In failed</Text>}
                  variant="light"
                  className="w-80"
                >
                  {errors.details.message}
                </Alert>
              ) : null}
            </div>
            <Button
              type="submit"
              loading={isFetching}
              radius="md"
              size={inputSize}
              variant="gradient"
              gradient={{ from: "#22d3ee", to: "#2dd4bf", deg: 90 }}
              tabIndex={4}
            >
              Sign in
            </Button>
            <GoogleAuthButton size={inputSize} />
            <div className="flex flex-col items-center gap-2 mt-4">
              <div className="flex flex-row justify-between items-center gap-2">
                <Text size="md">Don't have an account?</Text>
                <Anchor component={Link} to={ROUTES.SIGN_UP}>
                  Sign up
                </Anchor>
              </div>
            </div>
          </Stack>
        </form>
      </div>
      <div
        className={`row-span-2
        hidden md:flex justify-end
        bg-gradient-to-tr from-blue-600 via-teal-300 to-sky-600 bg-[length:400%_400%] animate-live-gradient
        rounded-r-3xl
        overflow-y-hidden`}
      >
        <div
          className={`h-52 mt-20
          bg-gradient-to-tr from-blue-600 via-teal-300 to-sky-600 bg-[length:400%_400%] animate-live-gradient
          shadow-md 
          rounded-l-3xl`}
        >
          <div className="p-4 md:p-14">
            <p className="text-xl md:text-5xl text-white font-semibold text-opacity-65">Sign In</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
