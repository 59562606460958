import { useState, useEffect } from "react";
import { CloudDocsService } from "@/services";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants";

const ConfirmProvider = () => {
  const navigate = useNavigate();
  const [hasAuthorizationCode, setHasAuthorizationCode] = useState<boolean | null>(null);

  useEffect(() => {
    const { code } = CloudDocsService.getQueryParamsFromProviderUrl();
    setHasAuthorizationCode(!!code);
  }, []);

  const handleRedirectToApp = async () => {
    if (hasAuthorizationCode) {
      await CloudDocsService.processProviderCallback();
    }
    navigate(`${ROUTES.DOCUMENTS}/files`);
  };

  return (
    <div className="grid grid-rows-[auto_1fr] h-dvh w-dvw p-16">
      <div className="place-self-center flex flex-col justify-center items-center gap-8">
        <div className="text-2xl">
          {hasAuthorizationCode
            ? "Provider has been connected successfully."
            : "Something went wrong, please try again later."}
        </div>
        <button
          className="bg-indigo-600 px-4 py-2 text-white font-semibold"
          onClick={handleRedirectToApp}
        >
          GO TO HOMEPAGE
        </button>
      </div>
    </div>
  );
};

export default ConfirmProvider;
