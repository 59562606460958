import React from "react";
import { ReviewCollectionSuggestion } from "@common/types";

interface MetCriteriaCardProps {
  criteria: ReviewCollectionSuggestion;
  isHighlighted: boolean;
  onClick: (criteria: ReviewCollectionSuggestion) => void;
}

export const MetCriteriaCard: React.FC<MetCriteriaCardProps> = React.memo(
  ({ criteria, isHighlighted, onClick }) => {
    const { id, criteria_name, comment } = criteria;

    return (
      <div
        key={id}
        onClick={() => onClick(criteria)}
        className={`grid grid-cols-[auto_1fr] !border-l-4 !border-ar-turquoise cursor-pointer rounded-tr-[4px] rounded-br-[4px] p-4 mb-1.5 ${isHighlighted ? "bg-violet-100" : "bg-white"}`}
      >
        <div>
          <div className="font-semibold pb-2">{criteria_name}</div>
          <span className="text-base">{comment}</span>
        </div>
      </div>
    );
  }
);
