import { UserSettings } from "@/common/types";

//TODO (Mykola) refactor to hook
export const isVisibleForDemoUser = (userSettings: UserSettings | undefined) => {
  if (userSettings?.is_demo_account === undefined) {
    return false;
  }
  if (userSettings?.is_demo_account === true) {
    return false;
  }
  if (import.meta.env.MODE === "production") {
    return false;
  }

  return true;
};
