import React, { useState } from "react";
import dayjs from "dayjs";
import { Checkbox, RenderTreeNodePayload, Tree, TreeNodeData } from "@mantine/core";
import { FileTreeItem, SelectedFileTreeItem } from "@common/types";

import { UseFormRegister } from "react-hook-form";
import { FileActions } from "@/components/New/components/General/components/DocumentsAndFolders/components/FileActions";
import { Upload } from "../../../Upload";

type SelectedRecord = Record<string, SelectedFileTreeItem>;

interface FormValues {
  search: string;
  selected: SelectedRecord;
  isSelectAll: boolean;
}

interface CollectionsProps {
  // eslint-disable-next-line
  data: FileTreeItem[] | any; // TODO(Ihor Dubas): Replace any by real type
  register: UseFormRegister<FormValues>;
  onDelete: (file: FileTreeItem) => void;
  onDocumentClick: (file: FileTreeItem) => void;
  onDownload: (file: FileTreeItem) => void;
  onSelectionChange: (
    id: string,
    parentFolderId: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onSendCopyClick: (file: FileTreeItem) => void;
  showDataAndActionButtons?: boolean;
}

const collectionMapFn = (file: FileTreeItem): TreeNodeData => {
  const { id, name, children } = file;

  return {
    value: id,
    label: name,
    children: Array.isArray(children) ? children.map(collectionMapFn) : children,
    nodeProps: { file },
  };
};

export const Folders: React.FC<CollectionsProps> = ({
  data,
  register,
  onDelete,
  onDocumentClick,
  onDownload,
  onSelectionChange,
  onSendCopyClick,
  showDataAndActionButtons = true,
}) => {
  const collectionsTreeData = data.map(collectionMapFn);

  const [uploadFolderId, setUploadFolderId] = useState<string | null>(null);

  const showUpload = (folderId: string) => {
    setUploadFolderId((prev) => (prev === folderId ? null : folderId));
  };

  const renderNodeFn = React.useCallback(
    ({ node, expanded, hasChildren, elementProps }: RenderTreeNodePayload) => {
      const file = node?.nodeProps?.file ?? {};
      const { id, parent_folder_id, created_at, updated_at } = file;

      return (
        <>
          <div
            {...elementProps}
            className={`
            ${hasChildren ? "bg-zinc-100 border border-neutral-200 ml-0 py-4" : "bg-zinc-100 ml-12 py-2 grid-cols-3"}
            grid ${showDataAndActionButtons ? "grid-cols-[auto_1fr_auto_8rem_8rem_auto]" : "grid-cols-[auto_1fr_auto]"}
            justify-start content-center gap-4 font-['National'] hover:bg-opacity-50 border-b border-neutral-200 border-t border-t-slate-100 px-4 group
          `}
          >
            <div className="place-self-center">
              <Checkbox
                color="ar-accent"
                variant="outline"
                size="xs"
                radius="xs"
                {...register(`selected.${node.value}.isSelected`, {
                  onChange: onSelectionChange.bind(null, id, parent_folder_id),
                })}
                styles={{
                  input: {
                    backgroundColor: "#F2F0EF",
                    borderColor: "#3C343199",
                    borderWidth: "1.5px",
                  },
                }}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <div
              className="justify-self-start self-center text-bottom hover:underline text-lg"
              onClick={() => onDocumentClick(file)}
            >
              {node.label}
            </div>
            {showDataAndActionButtons && (
              <>
                <FileActions
                  document={file}
                  onDelete={onDelete}
                  onDownload={hasChildren ? undefined : onDownload}
                  onUpload={hasChildren ? () => showUpload(id) : undefined}
                  onSendCopy={onSendCopyClick}
                />
                <div className="flex flex-col justify-center items-start w-full border-l border-stone-700/30 pl-2 text-xs leading-[1rem]">
                  <span className="font-semibold text-stone-700">Uploaded</span>
                  <div>{dayjs(created_at).format("DD.MM.YYYY, HH:mm")}</div>
                </div>
                <div className="flex flex-col justify-center items-start w-full border-l border-stone-700/30 pl-2 text-xs leading-[1rem]">
                  <span className="font-semibold text-stone-700">Edited</span>
                  <div>{dayjs(updated_at).format("DD.MM.YYYY, HH:mm")}</div>
                </div>
              </>
            )}
            <div className={`${expanded ? "rotate-180" : "rotate-0"} place-self-center`}>
              {hasChildren ? (
                <span className="material-symbols-outlined">keyboard_arrow_down</span>
              ) : null}
            </div>
          </div>
          {uploadFolderId === id ? (
            <Upload
              collection={file}
              isFolderInputForced={true}
              onCancelClick={() => setUploadFolderId(null)}
            />
          ) : null}
        </>
      );
    },
    [
      onDelete,
      onDocumentClick,
      onDownload,
      onSelectionChange,
      onSendCopyClick,
      register,
      showDataAndActionButtons,
      uploadFolderId,
    ]
  );

  return <Tree data={collectionsTreeData} renderNode={renderNodeFn} />;
};
