import { useLazyGenerateOnlyOfficeTokenQuery } from "@/redux/api";
import { useAppSelector } from "@/redux/hooks";
import { getSelectedDocument } from "@/redux/slices/documents";
import { User } from "@common/types";
import { Loader } from "@mantine/core";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import { useCallback, useEffect, useState } from "react";

// Extracting the IConfig type from the DocumentEditor component's props
type DocumentEditorProps = React.ComponentProps<typeof DocumentEditor>;
type IConfig = DocumentEditorProps["config"];

interface OnlyOfficeViewerProps {
  documentServerUrl: string;
  userData: User;
}

export const OnlyOfficeViewer: React.FC<OnlyOfficeViewerProps> = ({
  documentServerUrl,
  userData,
}) => {
  const editorId = "document-editor";

  const selectedDocument = useAppSelector(getSelectedDocument);

  const [generateOnlyOfficeToken, { data: tokenData, isLoading, isError }] =
    useLazyGenerateOnlyOfficeTokenQuery();

  const [documentConfig, setDocumentConfig] = useState<IConfig | null>(null);

  const handleLoadOnlyOfficeToken = useCallback(() => {
    if (selectedDocument) {
      const document = selectedDocument?.children?.[0] ?? selectedDocument;
      generateOnlyOfficeToken({
        documentId: document.id,
      });
    }
  }, [generateOnlyOfficeToken, selectedDocument]);

  useEffect(() => {
    handleLoadOnlyOfficeToken();
  }, [handleLoadOnlyOfficeToken]);

  useEffect(() => {
    if (tokenData && selectedDocument) {
      const document = selectedDocument?.children?.[0] ?? selectedDocument;
      setDocumentConfig({
        token: tokenData.access_token,
        document: {
          fileType: "docx",
          key: document.id, // Actually this field doesn't matter as OnlyOffice takes the URL directly from the token, but this field is required
          title: `${document.name}${document.document_original_extension ?? ""}`,
          url: `/onlyoffice/download/${document.id}/original.docx`, // Actually this field doesn't matter as OnlyOffice takes the URL directly from the token, but this field is required
        },
        documentType: "word",
        editorConfig: {
          callbackUrl: "/api/onlyoffice/document_callback", // Actually this field doesn't matter as OnlyOffice takes the URL directly from the token
          user: {
            id: userData.id.toString(),
            name: userData.full_name ?? userData.username,
          },
          customization: {
            anonymous: {
              request: false,
              label: "",
            },
            forcesave: true,
            uiTheme: "theme-light", // TODO: Adjust this once we have user preferences for UI themes
            zoom: -2, // -2 = Fit to editor width
          },
        },
        height: "100%",
        events: {
          onOutdatedVersion: () => {
            console.log("Outdated version of the document loaded. Attempting to reload...");
            handleLoadOnlyOfficeToken();
          }
        },
      });
    }
  }, [tokenData, userData, selectedDocument, handleLoadOnlyOfficeToken]);

  const onDocumentReady = () => {
    // TODO(Reinis): Probably can remove console.log
    console.log("Document is loaded");
    // TODO: When we have "Developer Edition" license, we should be able to use the following code to create a connector
    // https://api.onlyoffice.com/editors/methods
    // console.log(window?.DocEditor?.instances);
    // const docEditor = window.DocEditor.instances[editorId];
    // docEditor.createConnector();
  };

  // eslint-disable-next-line
  const onLoadComponentError = (errorCode: any, errorDescription: any) => {
    // TODO(Reinis): Probably can remove console.error
    switch (errorCode) {
      case -1: // Unknown error loading component
      case -2: // Error load DocsAPI from http://documentserver/
      case -3: // DocsAPI is not defined
        console.error(errorDescription);
        break;
      default:
        console.error("An unknown error occurred.");
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-dvh">
        <Loader size="xl" />
      </div>
    );
  }

  if (isError || !documentConfig) {
    return <div>Error loading document. Please try again.</div>;
  }

  return (
    <DocumentEditor
      id={editorId}
      documentServerUrl={documentServerUrl}
      config={documentConfig}
      events_onDocumentReady={onDocumentReady}
      onLoadComponentError={onLoadComponentError}
    />
  );
};

export default OnlyOfficeViewer;
