/* eslint-disable react/display-name */
import { AvailableLLMs, ChatMode, ModelChoice, UserSettingsUpdate } from "@common/types";
import { ModeEnum } from "@common/types";
import { ActionIcon, HoverCard, Menu, Tooltip } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { memo, useMemo } from "react";

import { useGetUserSettingsQuery, useUpdateUserSettingsMutation } from "@/redux/api";

import PreferredLlmMenu from "./PreferredLlmMenu";

interface ChatSettingsProps {
  mode: ModeEnum;
  onUserSettingsUpdate?: () => void;
}

export const ChatSettings: React.FC<ChatSettingsProps> = memo(({ mode, onUserSettingsUpdate }) => {
  const { data: userSettings } = useGetUserSettingsQuery();

  const [updateUserSettings] = useUpdateUserSettingsMutation();

  // TODO(Reinis): Probably the default should be set in env, or retrieved
  // from BE via an API call so that there is just one source of truth
  const defaultExplainModelChoice = useMemo(
    () => ({
      label: (
        <HoverCard width={400} shadow="md" openDelay={0} closeDelay={0} offset={20}>
          <HoverCard.Target>
            <div className="flex flex-row items-center">
              <span>Default - Anthropic Claude 3.5 Sonnet</span>
              <span className="text-ar-turquoise font-light ml-2">Recommended</span>
            </div>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <div className="p-4">
              <div className="text-lg font-semibold">Default</div>
              <div className="text-sm text-gray-600 mt-2">
                Use whichever LLM is recommended by us at the time. Our recommendations are based on
                regular evaluations of how well the LLMs perform overall on legal tasks. Response
                quality is favored over speed in our evaluations. The default LLM may change over
                time as new LLMs become available.
              </div>
            </div>
          </HoverCard.Dropdown>
        </HoverCard>
      ),
      value: null,
    }),
    []
  );

  // TODO(Reinis): Later we might have different defaults for different tools, so this should
  // be refactored to be more flexible
  const defaultMarkupModelChoice = useMemo(
    () => ({
      label: (
        <HoverCard width={400} shadow="md" openDelay={0} closeDelay={0} offset={20}>
          <HoverCard.Target>
            <div className="flex flex-row items-center">
              <span>Default - Anthropic Claude 3.5 Sonnet</span>
              <span className="text-ar-turquoise font-light ml-2">Recommended</span>
            </div>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <div className="p-4">
              <div className="text-lg font-semibold">Default</div>
              <div className="text-sm text-gray-600 mt-2">
                Use whichever LLM is recommended by us at the time. Our recommendations are based on
                regular evaluations of how well the LLMs perform overall on legal tasks. Response
                quality is favored over speed in our evaluations. The default LLM may change over
                time as new LLMs become available.
              </div>
            </div>
          </HoverCard.Dropdown>
        </HoverCard>
      ),
      value: null,
    }),
    []
  );

  const markupModelChoices: ModelChoice[] = useMemo(
    () => [
      defaultMarkupModelChoice,
      {
        label: "OpenAI o1-preview",
        value: AvailableLLMs.O1_PREVIEW,
      },
      {
        label: "OpenAI o1-mini",
        value: AvailableLLMs.O1_MINI,
      },
      {
        label: "Anthropic Claude 3.5 Sonnet",
        value: AvailableLLMs.CLAUDE_3_5_SONNET_V1,
      },
      {
        label: "OpenAI GPT-4o",
        value: AvailableLLMs.GPT_4O,
      },
      {
        label: "Google Gemini 1.5 Pro",
        value: AvailableLLMs.GEMINI_1_5_PRO,
      },
    ],
    [defaultMarkupModelChoice]
  );

  const explainModelChoices: ModelChoice[] = useMemo(
    () => [
      defaultExplainModelChoice,
      {
        label: "Anthropic Claude 3.5 Sonnet",
        value: AvailableLLMs.CLAUDE_3_5_SONNET,
      },
      {
        label: "OpenAI GPT-4o",
        value: AvailableLLMs.GPT_4O,
      },
      {
        label: "OpenAI GPT-4o mini",
        value: AvailableLLMs.GPT_4O_MINI,
      },
      {
        label: "OpenAI o1-preview",
        value: AvailableLLMs.O1_PREVIEW,
      },
      {
        label: "OpenAI o1-mini",
        value: AvailableLLMs.O1_MINI,
      },
      {
        label: "Google Gemini 1.5 Pro",
        value: AvailableLLMs.GEMINI_1_5_PRO,
      },
      {
        label: "Google Gemini 1.5 Flash",
        value: AvailableLLMs.GEMINI_1_5_FLASH,
      },
      {
        label: "Mistral Large 2",
        value: AvailableLLMs.MISTRAL_LARGE_2,
      },
      {
        label: "Llama 3.2 90B",
        value: AvailableLLMs.LLAMA_3_2_90B_VISION,
      },
      {
        label: "Aracor SLM",
        value: AvailableLLMs.ARACOR_SLM,
      },
    ],
    [defaultExplainModelChoice]
  );

  const explainChatModeChoices = useMemo(
    () => [
      {
        label: (
          <div className="flex flex-row items-center">
            <span>Pro</span>
            <span className="text-ar-turquoise font-light ml-2">Recommended</span>
          </div>
        ),
        value: ChatMode.PRO_MODE,
      },
      {
        label: "Discovery",
        value: ChatMode.DISCOVERY_MODE,
      },
    ],
    []
  );

  const chatModeHoverInfo = useMemo(
    () => ({
      [ChatMode.PRO_MODE]: {
        title: "Pro Mode",
        description:
          "Strict mode that puts more guardrails to the LLM to focus only on legal tasks and only on the currently opened document(s). Gives LLM less freedom and creativity, but leads to fewer hallucinations (incorrect or irrelevant responses).",
      },
      [ChatMode.DISCOVERY_MODE]: {
        title: "Discovery Mode",
        description: "Less restrictive mode that lets the LLM look up information on the Web.",
      },
    }),
    []
  );

  const handleUpdateUserSettings = async (updatedUserSettings: UserSettingsUpdate) => {
    if (!userSettings) {
      return;
    }

    notifications.show({
      id: "UPDATE_USER_SETTINGS_IN_PROGRESS",
      loading: true,
      title: "Updating",
      message: "Updating your settings...",
    });

    updateUserSettings({ ...userSettings, ...updatedUserSettings });
    onUserSettingsUpdate?.();

    notifications.hide("UPDATE_USER_SETTINGS_IN_PROGRESS");

    notifications.show({
      title: "Settings Updated",
      message: "Your settings have been updated.",
      color: "green",
    });
  };

  return (
    <Menu width={350} shadow="md">
      <Menu.Target>
        <Tooltip label="Settings">
          <ActionIcon variant="transparent">
            <span className="material-symbols-outlined text-white">settings</span>
          </ActionIcon>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown className="max-w-[calc(100%-16px)]">
        {mode === ModeEnum.BRIEF && (
          <PreferredLlmMenu
            title="Preferred LLM"
            choices={explainModelChoices}
            preferredValue={userSettings?.preferred_brief_llm}
            onClick={(value) => handleUpdateUserSettings({ preferred_brief_llm: value })}
          />
        )}

        {mode === ModeEnum.REDLINE && (
          <PreferredLlmMenu
            title="Preferred LLM"
            choices={markupModelChoices}
            preferredValue={userSettings?.preferred_redline_llm}
            onClick={(value) =>
              handleUpdateUserSettings({
                preferred_redline_llm: value,
                reloadSuggestions: true,
              })
            }
          />
        )}
        {mode === ModeEnum.BRIEF ? (
          <>
            <Menu.Label>
              <span className="text-sm text-ar-brown">Chat mode</span>
            </Menu.Label>
            {explainChatModeChoices.map(({ label, value }) => {
              const isSelectedChatMode = (userSettings?.chat_mode ?? ChatMode.PRO_MODE) === value;

              return (
                <HoverCard
                  key={value}
                  width={400}
                  shadow="md"
                  openDelay={0}
                  closeDelay={0}
                  offset={20}
                >
                  <HoverCard.Target>
                    <Menu.Item
                      rightSection={
                        isSelectedChatMode ? (
                          <span className="material-symbols-outlined">check</span>
                        ) : null
                      }
                      onClick={() => handleUpdateUserSettings({ chat_mode: value })}
                    >
                      <span className="font-semibold">{label}</span>
                    </Menu.Item>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <div className="p-4">
                      <div className="text-lg font-semibold">
                        {chatModeHoverInfo[value as ChatMode].title}
                      </div>
                      <div className="text-sm text-gray-600 mt-2">
                        {chatModeHoverInfo[value as ChatMode].description}
                      </div>
                    </div>
                  </HoverCard.Dropdown>
                </HoverCard>
              );
            })}
          </>
        ) : null}
      </Menu.Dropdown>
    </Menu>
  );
});
