import React, { useEffect } from "react";
import { Logo } from "@/components/common";
import { useMantineColorScheme } from "@mantine/core";
import { Link, Outlet } from "react-router-dom";
import ThemeService from "@/services/theme.ts";

const ResetPassword: React.FC = () => {
  const { colorScheme } = useMantineColorScheme();

  useEffect(() => {
    ThemeService.checkAndApplyTheme();
  }, [colorScheme]);

  return (
    <section
      className={`grid grid-cols-1 md:grid-cols-2 grid-rows-[6rem_1fr_1fr]
      h-dvh w-dvw min-w-[390px]
      px-2 md:px-6 lg:px-24 pb-2 md:pb-4 lg:pb-16
      bg-stone-200 dark:bg-slate-800`}
    >
      <div
        className={`col-span-2
        flex flex-row justify-between items-center`}
      >
        <Link to="/" className="">
          <Logo />
        </Link>
      </div>
      <div
        className={`row-span-2
        grid place-items-center
        px-0 py-8 
        bg-white dark:bg-slate-700
        rounded-l-3xl rounded-r-3xl md:rounded-r-none
        overflow-y-auto`}
      >
        <Outlet />
      </div>
      <div
        className={`row-span-2
        hidden md:flex justify-end
        bg-gradient-to-tr from-blue-600 via-violet-300 to-indigo-600 bg-[length:400%_400%] animate-live-gradient
        rounded-r-3xl
        overflow-y-hidden`}
      >
        <div
          className={`h-52 mt-20
          bg-gradient-to-tr from-blue-600 via-violet-300 to-indigo-600 bg-[length:400%_400%] animate-live-gradient
          shadow-md 
          rounded-l-3xl`}
        >
          <div className="p-4 md:p-14">
            <p className="text-xl md:text-5xl text-white font-semibold text-opacity-65">Reset</p>
            <p className="text-xl md:text-5xl text-white font-semibold text-opacity-65">Password</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
