import { modals } from "@mantine/modals";
import Upload from "./Upload.tsx";
import { FileTreeItem, Modals } from "@common/types";

interface OpenUploadArgs {
  collection?: FileTreeItem;
  selectedDocumentsToDownload?: string[];
  isFolderInputForced?: boolean;
  selectedProvider?: string;
}

export const openUpload = ({
  collection,
  selectedDocumentsToDownload,
  isFolderInputForced,
  selectedProvider,
}: OpenUploadArgs = {}) => {
  modals.open({
    modalId: Modals.UPLOAD,
    title: (
      <div className="text-2xl">
        {collection?.collection_id ? "Add files to collection" : "Upload Documents"}
      </div>
    ),
    children: (
      <Upload
        collection={collection}
        selectedDocumentsToDownload={selectedDocumentsToDownload}
        isFolderInputForced={isFolderInputForced}
        selectedProvider={selectedProvider}
      />
    ),
    centered: true,
    padding: 24,
    radius: "lg",
    closeOnClickOutside: false,
    size: "auto",
  });
};
