import { Theme } from "@common/types";

const THEME_KEY = "theme";

const checkAndApplyTheme = () => {
  if (!("theme" in localStorage)) {
    localStorage.setItem("theme", "light");
  }

  if (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
};

const checkIsDarkMode = () => {
  switch (localStorage.getItem(THEME_KEY)) {
    case Theme.dark:
      return true;
    case Theme.light:
      return false;
    default:
      return false;
  }
};

const getThemeValue = () => (checkIsDarkMode() ? "dark" : "light");

const removeTheme = () => localStorage.removeItem(THEME_KEY);

const setTheme = (variant: Theme) => localStorage.setItem(THEME_KEY, variant);

const toggleTheme = () => {
  const isDarkMode = checkIsDarkMode();

  setTheme(isDarkMode ? Theme.light : Theme.dark);
  checkAndApplyTheme();
};

export default {
  getThemeValue,
  checkAndApplyTheme,
  checkIsDarkMode,
  removeTheme,
  setTheme,
  toggleTheme,
};
