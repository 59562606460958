import { Link } from "react-router-dom";
import { ROUTES } from "@/constants";

export const PrivacyTosLinks = () => (
  <div className="flex flex-row justify-center items-center gap-6 text-white/20 p-5">
    <span>
      <Link to={ROUTES.TERMS_PUBLIC}>Terms of Service</Link>
    </span>
    <span>•</span>
    <span>
      <Link to={ROUTES.PRIVACY_PUBLIC}>Privacy Policy</Link>
    </span>
    <span>•</span>
    <span>
      <Link to={ROUTES.DPA_PUBLIC}>DPA</Link>
    </span>
  </div>
);
