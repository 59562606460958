import { Button, ScrollArea, Skeleton, TextInput } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useGetUserFileTreeQuery } from "@/redux/api";
import { useAppDispatch } from "@/redux/hooks.ts";
import { useSelector } from "react-redux";
import { getSelectedDocument, setHighlightedReference, setIsDocumentLoaded } from "@/redux/slices";
import { useCallback, useEffect, useState } from "react";
import { FilesTreeItem as TreeItem } from "./components";
import { Controller, useForm, useWatch } from "react-hook-form";
import { selectDocument } from "@/redux/thunk";
import { FileTreeItem } from "@common/types/File.ts";
import { ROUTES } from "@/constants";

export const FilesTree = () => {
  const navigate = useNavigate();

  const appDispatch = useAppDispatch();

  const selectedDocument = useSelector(getSelectedDocument);

  const { data: documents = [], isFetching: isUserFileTreeFetching } = useGetUserFileTreeQuery();

  const [openFolders, setOpenFolders] = useState<Set<string>>(new Set<string>());

  const firstDocument =
    Array.isArray(documents) && documents.length ? documents[0] : ({} as FileTreeItem);

  const { control } = useForm<{ filter: string }>({
    defaultValues: {
      filter: "",
    },
  });

  const filterWatch = useWatch({ control, name: "filter" });

  useEffect(() => {
    if (documents?.length && !selectedDocument) {
      appDispatch(
        selectDocument({
          id: firstDocument.id,
          collection_id: firstDocument.collection_id,
        })
      );
    }
  }, [documents, firstDocument]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // If the selected document has a parent folder, open it
    // Re-visit this in case we implement multi-level folders - we might need to open all parent folders
    if (selectedDocument && selectedDocument.parent_folder_id) {
      setOpenFolders((prevOpenFolders) => {
        return new Set<string>([...prevOpenFolders, selectedDocument.parent_folder_id!]);
      });
    }
  }, [selectedDocument, documents]);

  const filteredDocuments =
    filterWatch && filterWatch.length
      ? documents.reduce((acc, file) => {
          const parentLevelMatch = `${file.name}${file.document_original_extension ?? ""}`
            .toLowerCase()
            .includes(filterWatch.toLowerCase());
          let match: FileTreeItem | undefined;

          if (parentLevelMatch) {
            match = { ...file };
          }

          if (file.children) {
            const filteredChildren = file.children.filter(
              ({ name: childName, document_original_extension: childOriginalExtension }) =>
                `${childName}${childOriginalExtension ?? ""}`
                  .toLowerCase()
                  .includes(filterWatch.toLowerCase())
            );

            if (Array.isArray(filteredChildren) && filteredChildren.length) {
              if (!match) {
                match = { ...file };
              }

              match.children = filteredChildren;
            }
          }

          if (match) {
            acc.push(match);
          }

          return acc;
        }, [] as FileTreeItem[])
      : documents;

  const handleDocumentClick = useCallback(
    (document: FileTreeItem) => () => {
      if (Array.isArray(document.children)) {
        handleFolderToggle(document);
        return;
      }

      if ((selectedDocument && selectedDocument.id) !== document.id) {
        appDispatch(setIsDocumentLoaded(false));
        appDispatch(setHighlightedReference(null));
      }

      appDispatch(
        selectDocument({
          id: document.id,
          collection_id: document.collection_id,
        })
      );
    },
    [appDispatch, selectedDocument]
  );

  const handleFolderToggle = (folder: FileTreeItem) => {
    setOpenFolders((prevOpenFolders) => {
      if (prevOpenFolders.has(folder.id)) {
        return new Set([...prevOpenFolders].filter((id) => id !== folder.id));
      }
      return new Set([...prevOpenFolders, folder.id]);
    });
  };

  const isFolderOpened = !!filterWatch;

  return (
    <div className="grid grid-rows-[auto_auto_1fr] gap-2 overflow-y-hidden">
      <Button
        leftSection={<span className="material-symbols-outlined">arrow_back</span>}
        variant="subtle"
        justify="start"
        color="ar-dark"
        className="text-white"
        onClick={() => navigate(ROUTES.DOCUMENTS)}
      >
        Back
      </Button>
      <div className="py-1">
        <Controller
          name="filter"
          control={control}
          render={({ field }) => (
            <TextInput {...field} radius="sm" placeholder="Filter files" required />
          )}
        />
      </div>
      {!isUserFileTreeFetching && Array.isArray(filteredDocuments) ? (
        <ScrollArea
          type="auto"
          scrollbarSize={4}
          className="
            flex-1 flex flex-col justify-start items-start
            [&_.mantine-NavLink-collapse>:has(a[data-active=true])]:bg-ar-gray/10"
        >
          {filteredDocuments.map((document) => (
            <TreeItem
              key={document.id}
              document={document}
              isActive={document.collection_id === selectedDocument?.collection_id}
              isOpened={isFolderOpened || openFolders.has(document.id)}
              onClick={handleDocumentClick(document)}
            >
              {Array.isArray(document.children)
                ? document.children.map((child) => (
                    <TreeItem
                      key={child.id}
                      document={child}
                      isActive={child.id === selectedDocument?.id}
                      isOpened={openFolders.has(document.id)}
                      onClick={handleDocumentClick(child)}
                    />
                  ))
                : null}
            </TreeItem>
          ))}
        </ScrollArea>
      ) : (
        <div className="flex flex-col justify-start items-start">
          {Array(8)
            .fill("i")
            .map((_value, index) => (
              <Skeleton key={index} height={28} my={4} radius="sm" animate className="opacity-10" />
            ))}
        </div>
      )}
    </div>
  );
};
