export enum ResponseState {
  failure = "failure",
  success = "success",
  withErrorHandling = "withErrorHandling",
}

export enum ApiStatus {
  fail = "fail",
  success = "success",
}

export enum ApiErrorCodes {
  ACCESS_DENIED = "Access denied",
  AI_RESPONSE_WRONG_CONTENT_TYPE = "AI response wrong content type",
  BLOB_DOES_NOT_EXIST = "Blob does not exist",
  CANNOT_UPDATE_EMAIL_FOR_EXTERNAL_USERS = "Cannot update email for external users",
  CHAT_HISTORY_NOT_FOUND = "Chat history not found",
  CHAT_MESSAGE_NOT_FOUND = "Chat message not found",
  COLLECTION_NOT_FOUND = "Collection not found",
  COULD_NOT_CONFIRM_EMAIL = "Could not confirm email",
  COULD_NOT_RESET_PASSWORD = "Could not reset password",
  DIFFERENT_CONTRACT_TYPES = "Contract types of different types cannot be used for this action",
  DOCUMENT_NOT_FOUND = "Document not found",
  DOCUMENT_PROCESSING_FAILED = "Document processing failed",
  EMAIL_ALREADY_CONFIRMED = "User email is already confirmed",
  EMAIL_ALREADY_REQUESTED_INVITE = "Invitation has already been requested for this email address",
  EMAIL_NOT_CONFIRMED = "User email is not confirmed",
  EMAIL_NOT_IN_WHITELIST = "Your email address is not in our whitelist",
  EMAIL_SEND_ERROR = "There was an error while sending the email",
  EMPTY_DOCUMENT = "The provided document is empty",
  ENCRYPTION_DATA_NOT_FOUND = "Encryption data not found",
  FEATURE_IS_DISABLED = "Feature is disabled",
  FILENAME_NOT_PROVIDED = "Filename not provided",
  FILE_SIZE_NOT_PROVIDED = "File size not provided",
  FOLDER_HAS_NO_COLLECTION = "Folder has no collection",
  FOLDER_NOT_FOUND = "Folder not found",
  GOOGLE_LOGIN_ERROR = "Google login error",
  INVALID_CREDENTIALS = "Invalid credentials",
  INVALID_PLAYBOOK_TYPE = "Invalid playbook type",
  INVALID_REDLINE_FILE_TYPE = "Invalid file type for adding redlines",
  INVALID_RESPONSE = "Invalid response",
  INVALID_TIME_PERIOD = "Invalid time period provided",
  INVALID_EMAIL_CONFIRMATION_TOKEN = "The confirmation link you used is invalid or has expired",
  INVALID_PASSWORD_RESET_TOKEN = "The password reset link you used is invalid or has expired",
  INVALID_TOKEN = "Invalid token",
  INVALID_USERNAME_OR_EMAIL_OR_PASSWORD = "Invalid username/email address or password",
  LLM_RESPONSE_PARSING_ERROR = "Encountered an error while parsing LLM response",
  MESSAGE_TYPE_NOT_SUPPORTED = "Message type not supported",
  NO_CONTRACT_TYPE = "No contract type",
  NO_REPHRASED_MESSAGE = "No rephrased message",
  NO_SUMMARY_FOUND = "No summary found",
  NO_QDRANT_API_KEY = "No Qdrant api key",
  NOT_A_RESPONSE_MESSAGE = "Not a response message",
  NOT_ENOUGH_DOCUMENTS = "Not enough documents in the collection to perform this action",
  NUM_QUESTIONS_TOO_BIG = "Num questions too big",
  QUOTA_REACHED = "You have reached your daily quota for this action",
  UNDEFINED_ERROR = "Undefined error",
  UNEXPECTED_ERROR = "An unexpected error occurred",
  UNSUPPORTED_FILE_TYPE = "Unsupported file type",
  USERNAME_TAKEN = "Username taken",
  USER_EMAIL_TAKEN = "This email address is already in use",
  USER_IS_INACTIVE = "User is inactive",
  USER_NOT_FOUND = "User not found",
  COLLECTION_WITH_SINGLE_DOCUMENT_ONLY = "Only one document per collection is supported",
}

export interface GetChatHistoryResponse {
  response?: string[];
  chat_history?: string[];
}

export interface ApiPayload<T> {
  status: ApiStatus;
  message: string;
  data: T;
  error_code: ApiErrorCodes;
}

export interface ApiErrorPayload {
  data: ApiPayload<{ reason: string }>;
}

export interface ResponsePayload<T = void> {
  state: ResponseState;
  message: string;
  payload?: T;
}

export interface ISignInResponse {
  access_token: string;
  token_type: "bearer";
}
