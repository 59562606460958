import { useMatch } from "react-router-dom";
import { ROUTES, ASSETS } from "@/constants";
import { Viewer } from "@react-pdf-viewer/core";
import { PublicLayout } from "@/components/New/components";

export const DPA = () => {
  const isPublic = useMatch(ROUTES.DPA_PUBLIC);

  return isPublic ? (
    <PublicLayout palette="light">
      <div className="h-full w-2/3">
        <Viewer fileUrl={ASSETS.DPA_PDF} />
      </div>
    </PublicLayout>
  ) : (
    <Viewer fileUrl={ASSETS.DPA_PDF} />
  );
};
