import React, { useState } from "react";
import { ProviderItem } from "@/components/CloudProvider/components";
import { Folder, Modals, Provider, ProvidersFileTree, ProvidersFileTreeNode } from "@/common/types";
import { Button, ScrollArea } from "@mantine/core";
import { Folders } from "@/components/Rio/components/Dashboard/components";
import { useForm } from "react-hook-form";
import { openUpload } from "../Upload";
import { modals } from "@mantine/modals";
import { CloudDocsService } from "@/services";
import { FolderBreadcrumb } from "./FolderBreadcrumb";

interface ProviderModalProps {
  providers: Provider[];
  connectedProviders: string[];
}

// eslint-disable-next-line
type SelectedRecord = Record<string, any>; // TODO(Ihor Dubas): Replace any by real type

type FormValues = {
  search: string;
  selected: SelectedRecord;
};

const ProviderModal: React.FC<ProviderModalProps> = ({ providers, connectedProviders }) => {
  const [fileTree, setFileTree] = useState<ProvidersFileTree | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<string>("");
  const [path, setPath] = useState<string[]>([]);
  const [fullPaths, setFullPaths] = useState<string[]>([]);

  const { handleSubmit, getValues, register } = useForm<FormValues>({
    defaultValues: {
      selected: {},
    },
  });

  const handleFileTreeData = (data: ProvidersFileTree): void => {
    setFileTree(data);
  };

  const getSelectedDocumentsData = (): string[] => {
    const selectedValues = getValues("selected");

    const createSelectedPaths = (obj: SelectedRecord, currentPath: string) => {
      let selectedDocumentsPaths: string[] = [];

      for (const [key, value] of Object.entries(obj)) {
        const newPath = currentPath ? `${currentPath}.${key}` : key;

        if (value?.isSelected) {
          selectedDocumentsPaths.push(newPath);
        } else if (typeof value === "object" && value !== null) {
          selectedDocumentsPaths = selectedDocumentsPaths.concat(
            createSelectedPaths(value, newPath)
          );
        }
      }

      return selectedDocumentsPaths;
    };
    const selectedDocumentsPaths = createSelectedPaths(selectedValues, "");

    return selectedDocumentsPaths;
  };

  const handleDownloadSelectedDocuments = () => {
    const selectedDocumentsToDownload = getSelectedDocumentsData();
    openUpload({ selectedDocumentsToDownload, selectedProvider });
    modals.close(Modals.PROVIDERS);
  };

  const handleFolderClick = async (folderName: React.ReactNode | string, folderId: string) => {
    const fileTreeData = await CloudDocsService.getFileTreeDataFromProvider(
      selectedProvider,
      folderId
    );

    addFolderToPath(folderName, folderId);
    handleFileTreeData(fileTreeData);
  };

  const addFolderToPath = (folderName: string | React.ReactNode, folderId: string | undefined) => {
    if (typeof folderName !== "string") return;
    setPath((prevPath) =>
      prevPath[prevPath.length - 1] === folderName ? prevPath : [...prevPath, folderName]
    );

    setFullPaths((prevFullPaths) => [...prevFullPaths, [...prevFullPaths, folderId].join("/")]);
  };

  const handleBreadcrumbClick = (index: number) => {
    if (index === -1) {
      setPath([]);
      setFullPaths([]);
    }

    const newPath = path.slice(0, index + 1);
    const newFullPaths = fullPaths.slice(0, index + 1);

    setPath(newPath);
    setFullPaths(newFullPaths);

    handleFolderClick(newPath[index], newFullPaths[index]);
  };

  const transformFileTree = (node: ProvidersFileTree | Folder): ProvidersFileTreeNode[] => {
    const collections: ProvidersFileTreeNode[] = [];

    if (node.folders) {
      node.folders.forEach((folder) => {
        const folderData: ProvidersFileTreeNode = {
          id: folder.external_id,
          name: folder.name,
          children: transformFileTree(folder),
        };
        collections.push(folderData);
      });
    }

    if (node.files) {
      node.files.forEach((file) => {
        const fileData: ProvidersFileTreeNode = {
          id: file.external_id,
          name: file.name,
        };
        collections.push(fileData);
      });
    }

    return collections;
  };

  const transformedFiletreeCollections: ProvidersFileTreeNode[] = fileTree
    ? transformFileTree(fileTree)
    : [];

  const handleProviderItemClick = (provider: string) => {
    setSelectedProvider(provider);
  };

  const connectedProvidersData: Provider[] = providers.filter((provider) =>
    connectedProviders?.includes(provider.id)
  );
  const notConnectedProvidersData: Provider[] = providers.filter(
    (provider) => !connectedProviders?.includes(provider.id)
  );

  const renderProviderList = (providers: Provider[], title: string) => {
    if (!providers.length) return null;

    return (
      <>
        {title && <h3>{title}</h3>}
        <div className="grid grid-cols-2 gap-4 mb-3 mt-2">
          {providers.map(({ id, name, icon }) => (
            <ProviderItem
              key={id}
              id={id}
              name={name}
              icon={icon}
              connectedProviders={connectedProviders}
              onFileTreeLoad={handleFileTreeData}
              onProviderItemClick={handleProviderItemClick}
            />
          ))}
        </div>
      </>
    );
  };

  return (
    <div>
      {renderProviderList(connectedProvidersData, "")}
      {path.length > 0 ? (
        <FolderBreadcrumb onBreadcrumbItemClick={handleBreadcrumbClick} path={path} />
      ) : null}
      {fileTree?.name && (
        <div>
          <form onSubmit={handleSubmit(handleDownloadSelectedDocuments)}>
            <div className="overflow-scroll">
              <ScrollArea h="100%">
                {transformedFiletreeCollections.length > 0 ? (
                  <Folders
                    data={transformedFiletreeCollections}
                    register={register}
                    onDelete={() => {}}
                    onDocumentClick={() => {}}
                    onDownload={() => {}}
                    onSelectionChange={() => {}}
                    onSendCopyClick={() => {}}
                    showDataAndActionButtons={false}
                    onFolderClick={handleFolderClick}
                  />
                ) : null}
              </ScrollArea>
            </div>
            <div className="modal-footer sticky bottom-0 bg-white p-4">
              <Button radius="md" size="md" type="submit" variant="default" className="relative">
                Download Documents
              </Button>
            </div>
          </form>
        </div>
      )}
      {renderProviderList(notConnectedProvidersData, "Connect new Provider")}
    </div>
  );
};

export default ProviderModal;
