import { createSlice } from "@reduxjs/toolkit";
import { EFontSize, UIStyle } from "@common/types";
import { LocalStorageService } from "@/services";

interface SettingsState {
  isChatVisible: boolean;
  isRedactMode: boolean;
  isToolbarOpened: boolean;
  isViewerVisible: boolean;
  fontSize: EFontSize;
  uiStyle: UIStyle;
  mode: "brief" | "redline";
  sortOrder:
    | "edited-asc"
    | "edited-desc"
    | "name-asc"
    | "name-desc"
    | "uploaded-asc"
    | "uploaded-desc";
}

const initialState: SettingsState = {
  isChatVisible: true,
  isRedactMode: true,
  isToolbarOpened: true,
  isViewerVisible: true,
  fontSize: EFontSize.base,
  uiStyle: "new",
  mode: "brief",
  sortOrder: "uploaded-desc",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetSettings: () => initialState,
    setFontSize: (state, { payload }) => {
      state.fontSize = payload;
      localStorage.setItem(LocalStorageService.FONT_SIZE_KEY, payload);
    },
    setUIStyle: (state, { payload }) => {
      state.uiStyle = payload;
      localStorage.setItem(LocalStorageService.UI_KEY, payload);
    },
    setMode: (state, { payload }) => {
      state.mode = payload;
    },
    setSortOrder: (state, { payload }) => {
      state.sortOrder = payload;
    },
    toggleNavbar: (state) => {
      state.isToolbarOpened = !state.isToolbarOpened;
    },
  },
  selectors: {
    getFontSize: ({ fontSize }) =>
      localStorage.getItem(LocalStorageService.FONT_SIZE_KEY) ?? fontSize,
    getUIStyle: ({ uiStyle }) =>
      (localStorage.getItem(LocalStorageService.UI_KEY) as UIStyle) ?? uiStyle,
    getIsToolbarOpened: ({ isToolbarOpened }) => isToolbarOpened,
    getMode: ({ mode }) => mode,
    getSortOrder: ({ sortOrder }) => sortOrder,
  },
});

export const { setFontSize, setUIStyle, setMode, setSortOrder, toggleNavbar, resetSettings } =
  settingsSlice.actions;

export const { getFontSize, getUIStyle, getIsToolbarOpened, getMode, getSortOrder } =
  settingsSlice.selectors;

export default settingsSlice.reducer;
