import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  Anchor,
  Button,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  useMantineColorScheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Logo } from "@/components/common";
import { LocalStorageService } from "@/services";
import { IconAlertOctagon } from "@tabler/icons-react";
import ThemeService from "@/services/theme.ts";
import { useLazyGetUserDataQuery, useLazySignUpQuery } from "@/redux/api";
import { Controller, useForm } from "react-hook-form";
import { ROUTES } from "@/constants";
import { getErrorMessage } from "@/utils";

const SignUp: React.FC = () => {
  const { colorScheme } = useMantineColorScheme();

  useEffect(() => {
    ThemeService.checkAndApplyTheme();
  }, [colorScheme]);

  const [signUp] = useLazySignUpQuery();

  const [getUserData] = useLazyGetUserDataQuery();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState<boolean>(false);

  const heightMatched = useMediaQuery("(min-height: 56.25em)");

  const navigate = useNavigate();

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    setError,
    handleSubmit,
  } = useForm<{
    email: string;
    full_name: string;
    is_active: boolean;
    password: string;
    username: string;
    details: string;
  }>({ defaultValues: { is_active: true } });

  async function onFormSubmit() {
    setIsLoading(true);

    const { error } = await signUp(getValues());

    if (error) {
      setError("details", {
        type: "manual",
        message: getErrorMessage(error) || "Unknown error",
      });
    } else {
      setIsSignUpSuccessful(true);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (LocalStorageService.getAuthToken()) {
      getUserData(undefined)
        .unwrap()
        .then((userData) => {
          if (userData) {
            navigate(ROUTES.APP);
          }
        });
    }
  }, [getUserData, navigate]);

  const inputSize = heightMatched ? "xl" : "lg";

  return (
    <section
      className={`grid grid-cols-1 md:grid-cols-2 grid-rows-[6rem_1fr_1fr]
      h-dvh w-dvw min-w-[390px]
      px-2 md:px-6 lg:px-24 pb-2 md:pb-4 lg:pb-16
      bg-stone-200 dark:bg-slate-800`}
    >
      <div
        className={`col-span-2
        flex flex-row justify-between items-center`}
      >
        <Link to="/" className="">
          <Logo />
        </Link>
      </div>
      <div
        className={`row-span-2
        grid place-items-center
        px-0 py-8 
        bg-white dark:bg-slate-700
        rounded-l-3xl rounded-r-3xl md:rounded-r-none
        overflow-y-auto`}
      >
        {isSignUpSuccessful ? (
          <div className="flex justify-center items-center flex-col gap-4">
            <Text size="xl" fw={500} className="text-center">
              Your account has been created successfully!
            </Text>
            <Text size="lg" className="text-center">
              Please check your email to activate your account.
            </Text>
            <Text size="sm" className="text-center">
              You may now sign in to the site. However, please note that the full functionality of
              the site is only available after your account has been activated. If you do not see an
              email message from us within a few minutes, please check your spam folder. In case you
              have any questions or need assistance, please contact us at &nbsp;
              <Anchor href="mailto:support@aracor.ai">support@aracor.ai</Anchor>.
            </Text>
            <Button
              radius="md"
              size={inputSize}
              variant="gradient"
              gradient={{ from: "#6ee7b7", to: "#5eead4", deg: 90 }}
              component={Link}
              to={ROUTES.SIGN_IN}
            >
              Proceed to Sign In
            </Button>
          </div>
        ) : (
          <form
            onSubmit={(event) => {
              clearErrors();
              handleSubmit(onFormSubmit)(event);
            }}
          >
            <div className="w-80 max-w-80">
              <div className="block md:hidden pb-8 font-semibold text-3xl text-left">Sign Up</div>
              <Stack>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <TextInput {...field} label="Username" radius="md" size={inputSize} required />
                  )}
                />

                <Controller
                  name="full_name"
                  control={control}
                  render={({ field }) => (
                    <TextInput {...field} label="Full name" radius="md" size={inputSize} />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label="E-mail address"
                      radius="md"
                      size={inputSize}
                      required
                      type="email"
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <PasswordInput
                      {...field}
                      label="Password"
                      radius="md"
                      size={inputSize}
                      required
                    />
                  )}
                />
                <div className="my-2">
                  {errors.details ? (
                    <Alert
                      color="red"
                      icon={<IconAlertOctagon />}
                      radius="md"
                      title={<Text fw={500}>Sign Up failed</Text>}
                      variant="light"
                      className="w-80 [&_.mantine-Alert-body]:truncate"
                    >
                      {errors.details.message}
                    </Alert>
                  ) : null}
                </div>
                <Button
                  type="submit"
                  loading={isLoading}
                  radius="md"
                  size={inputSize}
                  variant="gradient"
                  gradient={{ from: "#6ee7b7", to: "#5eead4", deg: 90 }}
                >
                  Sign up
                </Button>
                <div className="flex flex-row items-center mt-4">
                  <Text size="md">
                    <span className="mr-1">Already have an account?</span>
                    <Anchor component={Link} to={ROUTES.SIGN_IN}>
                      Sign in
                    </Anchor>
                  </Text>
                </div>
              </Stack>
            </div>
          </form>
        )}
      </div>
      <div
        className={`row-span-2
        hidden md:flex justify-end
        bg-gradient-to-tr from-emerald-600 via-sky-300 to-green-600 bg-[length:400%_400%] animate-live-gradient
        rounded-r-3xl
        overflow-y-hidden`}
      >
        <div
          className={`h-52 mt-20
          bg-gradient-to-tr from-emerald-600 via-sky-300 to-green-600 bg-[length:400%_400%] animate-live-gradient
          shadow-md 
          rounded-l-3xl`}
        >
          <div className="p-4 md:p-14">
            <p className="text-xl md:text-5xl text-white font-semibold text-opacity-65">Sign Up</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
