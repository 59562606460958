import { MantineThemeColorsOverride } from "@mantine/core";

export const colors: MantineThemeColorsOverride = {
  "ar-accent": [
    "#ffecea",
    "#fad9d7",
    "#edb2ae",
    "#e28882",
    "#E35336", // main
    "#d34f44",
    "#d14238",
    "#b9342a",
    "#a62c24",
    "#92221c",
  ],
  "ar-dark": [
    "#f6f4f4",
    "#e7e7e7",
    "#cccccc",
    "#b0b0b0",
    "#3C3431", // main
    "#8d8987",
    "#88817e",
    "#766e6b",
    "#6a615e",
    "#5f534e",
  ],
  "ar-beige": [
    "#fff6e8",
    "#f9ecd6",
    "#f1d6ab",
    "#e9c07d",
    "#F7E6CA", // main
    "#dfa13d",
    "#de9b2f",
    "#c48622",
    "#af771a",
    "#98660e",
  ],
  "ar-gray": [
    "#f1f4ff",
    "#e4e6ee",
    "#c8cad4",
    "#aaadba",
    "#B6B9C4", // main
    "#808597",
    "#777d91",
    "#656b7f",
    "#595f73",
    "#4a5168",
  ],
  "ar-link": [
    "#eaf1ff",
    "#d4dffd",
    "#a9bbf3",
    "#7a96ea",
    "#6672AF", // main
    "#3962de",
    "#2b58dd",
    "#1d49c5",
    "#1440b1",
    "#01379d",
  ],
  "ar-turquoise": [
    "#e0fffd",
    "#cffaf6",
    "#a5f2ea",
    "#77eade",
    "#40E0D0", // main
    "#38dfce",
    "#24ddcb",
    "#06c4b3",
    "#00ae9f",
    "#009789",
  ],
  "ar-brown": [
    "#fff2ed",
    "#ede5e2",
    "#d4cac7",
    "#bbada8",
    "#7F6961", // main
    "#99857e",
    "#937d75",
    "#816a62",
    "#745e56",
    "#684f46",
  ],
  "ar-red": [
    "#ffedeb",
    "#f8d8d6",
    "#eeafab",
    "#e6827c",
    "#B3261E", // main
    "#db463c",
    "#d9392f",
    "#c12c22",
    "#ad251d",
    "#971a16",
  ],
};
