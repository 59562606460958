import React from "react";

export enum Providers {
  SHAREPOINT = "SHAREPOINT",
  ONEDRIVE = "ONEDRIVE",
  DROPBOX = "DROPBOX",
  BOX_COM = "BOX_COM",
}

export interface Provider {
  id: string;
  name: string;
  icon: React.ReactNode;
}

export interface File {
  name: string;
  full_path: string;
  external_id?: string;
}

export interface Folder {
  name: string;
  full_path: string;
  folders: Folder[];
  files: File[];
  external_id?: string;
}

export interface ProvidersFileTree {
  name: string;
  full_path: string;
  folders: Folder[];
  files: File[];
}

export interface ProvidersFileTreeNode {
  id?: string;
  name: string;
  children?: ProvidersFileTreeNode[];
}
