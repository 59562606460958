import api from "./api.ts";
import {
  ApiPayload,
  ISignInResponse,
  User,
  UserSettings,
  UserSettingsUpdate,
  AppSettings,
  ApiErrorPayload,
  ApiErrorCodes,
} from "@common/types";
import { LocalStorageService } from "@/services";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { resetDocuments } from "../slices/documents";
import { resetNotifications } from "../slices/notifications";
import { resetQa } from "../slices/qa";
import { resetSettings } from "../slices/settings";

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    confirmEmail: builder.query<string, string>({
      query: (token: string) => ({
        url: "/users/confirm_email",
        params: {
          token,
        },
      }),
      transformResponse: () => "Your email address has been confirmed!",
      transformErrorResponse: (error: FetchBaseQueryError | ApiErrorPayload) =>
        ApiErrorCodes[
          (error as ApiErrorPayload).data.error_code as unknown as keyof typeof ApiErrorCodes
        ],
    }),
    getUserData: builder.query({
      query: () => ({
        url: "/users/me",
      }),
      transformResponse: (response: ApiPayload<User>) => response?.data,
      providesTags: ["User"],
    }),
    getUserSettings: builder.query<UserSettings, void>({
      query: () => "/users/get_user_settings",
      transformResponse: (response: ApiPayload<UserSettings>) => response?.data,
      providesTags: ["Settings"],
    }),
    logout: builder.query({
      query: () => ({
        url: "/users/logout",
        method: "POST",
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch {
          /* empty */
        } finally {
          await Promise.all([
            dispatch(resetDocuments()),
            dispatch(resetNotifications()),
            dispatch(resetQa()),
            dispatch(resetSettings()),
          ]);
          LocalStorageService.removeAuthToken();
          LocalStorageService.removeMeta();
          LocalStorageService.removeSelectedDocumentId();
        }
      },
    }),
    resetPassword: builder.query({
      query: ({ token, password }) => ({
        url: "/users/reset_password",
        params: {
          token,
          password,
        },
      }),
    }),
    requestEmailConfirmation: builder.query({
      query: () => ({
        url: "/users/request_email_confirmation",
        method: "POST",
      }),
    }),
    requestResetPassword: builder.query({
      query: (email) => ({
        url: "/users/request_reset_password",
        method: "POST",
        body: {
          email,
        },
      }),
    }),
    signIn: builder.query({
      query: ({ username, password }) => ({
        url: "/users/token",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            LocalStorageService.setAuthToken(JSON.stringify(data as ISignInResponse));
          }
        } catch {
          /* empty */
        }
      },
    }),
    signUp: builder.query({
      query: (body) => ({
        url: "/users/signup",
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      }),
      transformResponse: (response: ApiPayload<User>) => response?.data,
    }),
    updateUserData: builder.mutation({
      query: ({ updated_full_name }) => ({
        url: "/users/update_user_data",
        method: "PUT",
        headers: {
          accept: "application/json",
        },
        params: {
          updated_full_name: updated_full_name,
        },
      }),
      transformResponse: (response: ApiPayload<User>) => response?.data,
      invalidatesTags: ["User"],
    }),
    updateUserSettings: builder.mutation<UserSettings, UserSettings>({
      query: (settings: UserSettingsUpdate) => ({
        url: "/users/update_user_settings",
        method: "PUT",
        body: settings,
      }),
      onQueryStarted: async (settings: UserSettingsUpdate, { dispatch, queryFulfilled }) => {
        if (settings && settings.reloadSuggestions) {
          dispatch(api.util.invalidateTags(["Suggestions"]));
        }

        await queryFulfilled;
      },
      invalidatesTags: ["Settings"],
    }),
    getAppSettings: builder.query<AppSettings, void>({
      query: () => "/users/get_app_settings",
      transformResponse: (response: ApiPayload<AppSettings>) => response?.data,
      providesTags: ["AppSettings"],
    }),
  }),
});

export const {
  useGetUserDataQuery,
  useGetUserSettingsQuery,
  useUpdateUserDataMutation,
  useUpdateUserSettingsMutation,
  useLazyConfirmEmailQuery,
  useLazyResetPasswordQuery,
  useLazyRequestEmailConfirmationQuery,
  useLazyRequestResetPasswordQuery,
  useLazyGetUserDataQuery,
  useLazySignInQuery,
  useLazySignUpQuery,
  useLazyLogoutQuery,
  useGetAppSettingsQuery,
} = usersApi;
