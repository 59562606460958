import { isRejectedWithValue, configureStore } from "@reduxjs/toolkit";
import { documentsReducer, notificationsReducer, settingsReducer, qaReducer } from "@/redux/slices";

import api from "@/redux/api";

import type { Middleware } from "@reduxjs/toolkit";

const apiErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // TODO: Re-visit this
    console.error(action);
  }

  return next(action);
};

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    documents: documentsReducer,
    notifications: notificationsReducer,
    settings: settingsReducer,
    qa: qaReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, apiErrorLogger),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
