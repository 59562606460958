import React from "react";
import { Alert, Anchor, Button, Stack, Text, TextInput } from "@mantine/core";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { IconAlertOctagon } from "@tabler/icons-react";
import { useLazyRequestResetPasswordQuery } from "@/redux/api";
import { ROUTES } from "@/constants";
import { getErrorMessage } from "@/utils";

export const EmailToResetForm: React.FC = () => {
  const [requestResetPassword, { isFetching, isSuccess }] = useLazyRequestResetPasswordQuery();

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    setError,
    handleSubmit,
  } = useForm<{
    email: string;
    details: string;
  }>();

  async function onFormSubmit() {
    const { error } = await requestResetPassword(getValues("email"));

    if (error) {
      setError("details", {
        type: "manual",
        message: getErrorMessage(error) || "Unknown error",
      });
    }
  }

  return (
    <form
      onSubmit={(event) => {
        clearErrors();
        handleSubmit(onFormSubmit)(event);
      }}
    >
      <div className="block md:hidden pb-8 font-semibold text-3xl text-left">Reset Password</div>
      {isSuccess ? (
        <div className="flex justify-center items-center flex-col gap-4 p-4">
          <Text size="xl" fw={500} className="text-center">
            Your password reset request was sent successfully!
          </Text>
          <Text size="lg" className="text-center">
            Please check your email to reset your password. In case you have any questions or need
            assistance, please contact us at &nbsp;
            <Anchor href="mailto:support@aracor.ai">support@aracor.ai</Anchor>.
          </Text>
          <Button
            radius="md"
            size="lg"
            variant="gradient"
            gradient={{ from: "#a855f7", to: "#6366f1", deg: 90 }}
            component={Link}
            to={ROUTES.SIGN_IN}
          >
            Proceed to Sign In
          </Button>
        </div>
      ) : (
        <Stack className="[&>*]:box-border">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Enter the verified email address associated with your account"
                radius="md"
                size="lg"
                required
                type="email"
                error={errors.email?.message}
              />
            )}
          />
          <div className="my-2">
            {errors.details ? (
              <Alert
                color="red"
                icon={<IconAlertOctagon />}
                radius="md"
                title={<Text fw={500}>Password Reset Failed</Text>}
                variant="light"
                className="w-80 [&_.mantine-Alert-body]:truncate"
              >
                {errors.details.message}
              </Alert>
            ) : null}
          </div>
          <Button
            type="submit"
            loading={isFetching}
            radius="md"
            size="lg"
            variant="gradient"
            gradient={{ from: "#a855f7", to: "#6366f1", deg: 90 }}
          >
            Reset Password
          </Button>
        </Stack>
      )}
    </form>
  );
};
