import { useMatch } from "react-router-dom";
import { FC } from "react";
import { ASSETS, ROUTES } from "@/constants";
import { Viewer } from "@react-pdf-viewer/core";
import { PublicLayout } from "@/components/New/components";

export const Terms: FC = () => {
  const isPublic = useMatch(ROUTES.TERMS_PUBLIC);

  return isPublic ? (
    <PublicLayout palette="light">
      <div className="h-full w-2/3">
        <Viewer fileUrl={ASSETS.TOS_PDF} />
      </div>
    </PublicLayout>
  ) : (
    <Viewer fileUrl={ASSETS.TOS_PDF} />
  );
};
