import React from "react";
import { ChatHeader } from "./ChatHeader";

interface ChatWrapperProps {
  children: React.ReactNode;
}

const ChatWrapper: React.FC<ChatWrapperProps> = ({ children }) => {
  return (
    <div className="grid grid-rows-[auto_auto_1fr_auto] h-full min-h-full w-full min-w-full bg-ar-dark">
      <ChatHeader />
      {children}
    </div>
  );
};

export default ChatWrapper;
