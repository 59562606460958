import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { IChatNotification, IChatNotificationWithId, NotificationStatus } from "@common/types";

interface ChatNotifications {
  notification: IChatNotification | null;
  error: IChatNotification | null;
}

interface NotificationsState {
  chatNotifications: Record<string, ChatNotifications>;
  status: NotificationStatus;
}

const initialState: NotificationsState = {
  chatNotifications: {},
  status: NotificationStatus.ghost,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    resetNotifications: () => initialState,
    setChatNotification: (state, { payload }: PayloadAction<IChatNotificationWithId>) => {
      const { id, ...notification } = payload;
      if (id) {
        state.chatNotifications[id] = {
          ...state.chatNotifications[id],
          notification,
        };
      }
    },
    setChatErrorNotification: (state, { payload }: PayloadAction<IChatNotificationWithId>) => {
      const { id, ...errorNotification } = payload;
      if (id) {
        state.chatNotifications[id] = {
          ...state.chatNotifications[id],
          error: errorNotification,
        };
      }
    },
    clearChatNotifications: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        state.chatNotifications[payload] = {
          notification: null,
          error: null,
        };
      }
    },
  },
  selectors: {
    getChatNotificationById: createSelector(
      (state: NotificationsState) => state.chatNotifications,
      (_: NotificationsState, id: string) => id,
      (chatNotifications, id) => chatNotifications[id]?.notification
    ),
    getChatErrorNotificationById: createSelector(
      (state: NotificationsState) => state.chatNotifications,
      (_: NotificationsState, id: string) => id,
      (chatNotifications, id) => chatNotifications[id]?.error
    ),
  },
});

export const {
  setChatNotification,
  setChatErrorNotification,
  clearChatNotifications,
  resetNotifications,
} = notificationsSlice.actions;

export const { getChatNotificationById, getChatErrorNotificationById } =
  notificationsSlice.selectors;

export default notificationsSlice.reducer;
