import clsx from "clsx/lite";
import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "@/constants";

interface PublicLayoutProps {
  palette: "dark" | "light";
}

export const PublicLayout: FC<PropsWithChildren<PublicLayoutProps>> = ({ children, palette }) => {
  const isDark = palette === "dark";

  return (
    <div className={clsx("h-dvh w-dvw overflow-auto", isDark ? "bg-ar-dark" : "bg-white")}>
      <div className="h-full flex flex-col justify-center items-center">
        <div className="self-start absolute top-6 left-9 z-0">
          <Link to={ROUTES.APP} className="font-['FinancierText'] text-2xl">
            <span className={clsx(isDark ? "text-white" : "text-ar-dark")}>Aracor</span>
            <span className="text-ar-accent">.</span>
          </Link>
        </div>
        <div className="self-end absolute top-6 right-9 z-0">
          <Link to={ROUTES.HOME} className="text-ar-brown underline">
            ← Back
          </Link>
        </div>
        {children}
      </div>
    </div>
  );
};
