import React from "react";
import { Progress } from "@mantine/core";

type Props = {
  message?: string;
  red?: boolean;
};

const Loader: React.FC<Props> = ({ message = "Loading...", red }: Props) => {
  return (
    <div className="grid grid-cols-[1fr_auto] gap-2 mx-4 my-2 p-4 rounded-md animate-[fade-in-keyframe_0.5s_ease-in-out] bg-white">
      <div>{message}</div>
      <Progress value={100} animated color={red ? "ar-accent" : ""} className="col-span-2" />
    </div>
  );
};

export default Loader;
