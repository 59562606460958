import React from "react";
import { saveAs } from "file-saver";
import { Button, Kbd, Tabs } from "@mantine/core";
import { useGetAppSettingsQuery } from "@/redux/api";
import { Code } from "@/components/HowTo/WordPlugin/components";

const URLS = {
  manifest: `/download/word_plugin/manifest.xml`,
  prerequisites: `/download/word_plugin/scripts/install_prerequisite.cmd`,
  install: `/download/word_plugin/scripts/install_Aracor_plugin.cmd`,
};
import NotFound from "@/components/NotFound";
import { Alert } from "@/components/New";
import { Helmet } from "react-helmet";
import { ASSETS_PATH, DEFAULT_HEADER, DEFAULT_HEADER_PREFIX } from "@/constants";

export const WordPluginInstallationManual: React.FC = () => {
  const { data: appSettings } = useGetAppSettingsQuery(undefined);

  const handleDownload = async (url: string, fileName: string) => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/xml",
      },
    });

    if (response.ok) {
      saveAs(await response.blob(), fileName);
    } else {
      console.error("File download failed.");
    }
  };

  if (!appSettings?.FEATURE_MS_WORD_PLUGIN_ENABLED) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {DEFAULT_HEADER_PREFIX}
          {DEFAULT_HEADER}
        </title>
      </Helmet>
      <div className="grid grid-rows-[auto_1fr] p-10 overflow-y-auto">
        <div className="place-self-center mb-10">
          <Alert visible type="info">
            <div className="flex flex-row justify-start items-center gap-8 py-4">
              <span className="text-lg">
                Before starting the installation, download the manifest file to your computer.
              </span>
              <Button
                variant="accent"
                size="lg"
                onClick={() => handleDownload(URLS.manifest, "manifest.xml")}
              >
                Download Word plugin
              </Button>
            </div>
          </Alert>
        </div>

        <Tabs defaultValue="web" color="ar-accent">
          <Tabs.List>
            <Tabs.Tab value="web">WEB</Tabs.Tab>
            <Tabs.Tab value="macos">MACOS</Tabs.Tab>
            <Tabs.Tab value="windows">WINDOWS (DEVELOPERS ONLY)</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="web">
            <div className="flex flex-col justify-start items-start gap-10 pt-10">
              <div className="flex flex-col justify-start items-start gap-5">
                <h1 className="text-2xl">1. Download the manifest file to your computer.</h1>
                <Button
                  color="black"
                  variant="outline"
                  rightSection={<span className="material-symbols-outlined">download</span>}
                  className="ml-5"
                  onClick={() => handleDownload(URLS.manifest, "manifest.xml")}
                >
                  Download Word plugin
                </Button>
              </div>

              <h1 className="text-2xl">2. Open Office on the Web. Open a document in Word.</h1>

              <div className="flex flex-col justify-start items-start gap-5">
                <h1 className="text-2xl">
                  3. Select Home {">"} Add-ins, then select More Add-ins.
                </h1>
                <img
                  src={ASSETS_PATH + "images/word_plugin/wp1.png"}
                  alt="Step 3"
                  className="border border-ar-gray rounded-lg"
                />
              </div>

              <div className="flex flex-col justify-start items-start gap-5">
                <h1 className="text-2xl">
                  4. On the Office Add-ins dialog, select the MY ADD-INS tab, and click Upload My
                  Add-in.
                </h1>
                <img
                  src={ASSETS_PATH + "images/word_plugin/wp2.png"}
                  alt="Step 4"
                  className="border border-ar-gray rounded-lg"
                />
              </div>

              <div className="flex flex-col justify-start items-start gap-5">
                <h1 className="text-2xl">
                  5. In this modal Browse to the add-in manifest file that you downloaded, and then
                  select Upload.
                </h1>
                <img
                  src={ASSETS_PATH + "images/word_plugin/wp3.png"}
                  alt="Step 5"
                  className="border border-ar-gray rounded-lg"
                />
              </div>

              <div className="flex flex-col justify-start items-start gap-5">
                <h1 className="text-2xl">
                  6. Once the manifest file is successfully uploaded, now click on More Options and
                  you will be able to see Word Plugin installed. Select add-in from the menu.
                </h1>
                <img
                  src={ASSETS_PATH + "images/word_plugin/wp4.png"}
                  alt="Step 6"
                  className="border border-ar-gray rounded-lg"
                />
              </div>

              <div className="flex flex-col justify-start items-start gap-5">
                <h1 className="text-2xl">
                  7. Once the manifest file is successfully uploaded, now click on More Options and
                  you will be able to see Word Plugin installed. Select add-in from the menu.
                </h1>
                <img
                  src={ASSETS_PATH + "images/word_plugin/wp5.png"}
                  alt="Step 7"
                  className="border border-ar-gray rounded-lg"
                />
              </div>
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="macos">
            <div className="flex flex-col justify-start items-start gap-10 pt-10">
              <h1 className="text-2xl">
                1. Use <strong>Finder</strong> to sideload the manifest file. Open&nbsp;
                <strong>Finder</strong> and then enter <Kbd size="lg">⌘</Kbd> +{" "}
                <Kbd size="lg">Shift</Kbd> + <Kbd size="lg">G</Kbd> to open the Go to folder dialog.
              </h1>

              <div className="flex flex-col justify-start items-start gap-5">
                <h1 className="text-2xl">
                  2. Enter the following <strong>filepath</strong>:
                </h1>
                <img
                  src={ASSETS_PATH + "images/word_plugin/wp6.png"}
                  alt="Step 2"
                  className="border border-ar-gray rounded-lg"
                />
                <p className="text-2xl">
                  The folder <strong>Documents</strong>&nbsp; should be opened.
                </p>
              </div>

              <h1 className="text-2xl">
                3. Inside the<strong className="px-2">Documents</strong>folder, you should see
                <strong className="px-2">wef</strong>folder. If the
                <strong className="px-2">wef</strong>folder doesn&#x27;t exist on your computer,
                create it inside the<strong className="px-2">Documents</strong>folder and open it.
              </h1>

              <div className="flex flex-col justify-start items-start gap-5">
                <h1 className="text-2xl">
                  4. Copy the provided manifest file to this <strong className="px-2">wef</strong>
                  folder.
                </h1>
                <img
                  src={ASSETS_PATH + "images/word_plugin/wp7.png"}
                  alt="Step 4"
                  className="border border-ar-gray rounded-lg"
                />
              </div>

              <h1 className="text-2xl">
                5. Open <strong className="px-2">Word</strong>(or restart
                <strong className="px-2">Word</strong>if it&#x27;s already running), then open a
                document.
              </h1>

              <div className="flex flex-col justify-start items-start gap-5">
                <h1 className="text-2xl">
                  6. Select <strong className="px-2">Home</strong> &gt;
                  <strong className="px-2">Add-ins,</strong>and then select add-in from the menu.
                </h1>
                <img
                  src={ASSETS_PATH + "images/word_plugin/wp8.png"}
                  alt="Step 6"
                  className="border border-ar-gray rounded-lg"
                />
              </div>

              <div className="flex flex-col justify-start items-start gap-5">
                <h1 className="text-2xl">
                  7. Verify that Word Plugin is displayed in<strong className="px-2">Word.</strong>
                  You can log in with your credentials and start using the Word Plugin.
                </h1>
                <img
                  src={ASSETS_PATH + "images/word_plugin/wp9.png"}
                  alt="Step 7"
                  className="border border-ar-gray rounded-lg"
                />
              </div>
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="windows">
            <div className="flex flex-col justify-start items-start gap-10 pt-10">
              <div className="flex flex-row justify-center items-center w-full">
                <Alert visible type="warning">
                  Currently, there is no easy option to install the Aracor Word Plugin on Windows
                  using the <strong>manifest.xml</strong> file. It requires additional software
                  (Node.js) to be installed in your system.
                </Alert>
              </div>

              <div className="flex flex-col justify-start items-start gap-5">
                <h1 className="text-3xl">Prerequisites</h1>
                <div className="flex flex-col justify-start items-start gap-5">
                  <h1 className="text-2xl">
                    1. Download the manifest file to your computer.{" "}
                    <Code red>install_prerequisite.cmd</Code>
                  </h1>
                  <Button
                    color="black"
                    variant="outline"
                    rightSection={<span className="material-symbols-outlined">download</span>}
                    className="ml-5"
                    onClick={() => handleDownload(URLS.prerequisites, "install_prerequisite.cmd")}
                  >
                    Download prerequisite checker
                  </Button>
                </div>
                <h1 className="text-2xl">
                  2. Double-click the installed file. This will check if your system is ready. If
                  not, then it will install all necessary dependencies.
                </h1>
              </div>

              <div className="flex flex-col justify-start items-start gap-5">
                <h1 className="text-3xl">Installation</h1>
                <div className="flex flex-col justify-start items-start gap-5">
                  <h1 className="text-2xl">
                    1. Download
                    <Code red>install_Aracor_plugin.cmd</Code>
                  </h1>
                  <Button
                    color="black"
                    variant="outline"
                    rightSection={<span className="material-symbols-outlined">download</span>}
                    className="ml-5"
                    onClick={() => handleDownload(URLS.install, "install_Aracor_plugin.cmd")}
                  >
                    Download word plugin installer
                  </Button>
                </div>
                <h1 className="text-2xl">
                  2. Double-click the installed file. This will install Word Plugin.
                </h1>
              </div>

              <Alert visible type="danger">
                <strong>IMPORTANT:</strong> <Code red>install_Aracor_plugin.cmd</Code> should be in
                the same folder as <Code red>manifest.xml</Code>
              </Alert>

              <img
                src={ASSETS_PATH + "images/word_plugin/wp10.png"}
                alt="Step 2"
                className="border border-ar-gray rounded-lg"
              />
            </div>
          </Tabs.Panel>
        </Tabs>
      </div>
    </>
  );
};
