import { IQaChatHistory, User, Meta, UIStyle } from "@common/types";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { LocalStorageService } from "@/services/index.ts";

const DOCUMENT_KEY = "aracor-document";
const META_KEY = "aracor-meta";
const TOKEN_KEY = "aracor-token";
const TOKEN_EXPIRY_KEY = "aracor-token-expiry";
const USER_KEY = "aracor-user";
const UI_KEY = "aracor-ui";
const FONT_SIZE_KEY = "aracor-font-size";
const IS_ONLYOFFICE_ENABLED_KEY = "aracor-onlyoffice-enabled";

const useUserPreference = <T>(key: string, defaultValue: T): [T, (value: T) => void] => {
  const [preference, setPreference] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? (JSON.parse(storedValue) as T) : defaultValue;
  });

  const setUserPreference = (value: T) => {
    setPreference(value);
    localStorage.setItem(key, JSON.stringify(value));
  };

  return [preference, setUserPreference];
};

const isJwtTokenExists = () => localStorage.getItem(TOKEN_KEY);

const getAuthToken = () => {
  const localStorageToken = localStorage.getItem(TOKEN_KEY);

  if (!localStorageToken) {
    return localStorageToken;
  }

  const tokenObject = JSON.parse(localStorageToken);

  return typeof tokenObject === "object" ? tokenObject.access_token : "";
};

const getAuthTokenExpiryInMs = () => {
  const expiry = localStorage.getItem(TOKEN_EXPIRY_KEY);

  if (!expiry) {
    return 0;
  }

  const expireTimestamp = parseInt(expiry);

  const nowMs = Date.now(); // In milliseconds
  const expiresAtMs = expireTimestamp * 1000; // In milliseconds
  const timeLeftMs = expiresAtMs - nowMs;

  return timeLeftMs;
};

const getFontSize = () => localStorage.getItem(FONT_SIZE_KEY) || "base";

const getMetaByKey = <T extends Meta, K extends keyof T>(key: K): T[K] => {
  const meta: string = localStorage.getItem(META_KEY) || "";
  const parsedMeta: T = meta ? JSON.parse(meta) : ({} as T);

  return parsedMeta[key];
};

const getSelectedDocumentId = () => localStorage.getItem(DOCUMENT_KEY);

const getUI = (): UIStyle => localStorage.getItem(UI_KEY) as UIStyle;

const setUI = (ui: UIStyle) => {
  localStorage.setItem(UI_KEY, ui);
};

const removeAuthToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_EXPIRY_KEY);
};

const setAuthToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
  try {
    const decoded = jwtDecode(token);
    const exp = decoded.exp;
    if (exp) {
      localStorage.setItem(TOKEN_EXPIRY_KEY, exp.toString());
    }
  } catch (error) {
    console.error("Error decoding JWT token", error);
  }
};

const setChatHistory = (collectionId: string, content: IQaChatHistory[]) => {
  if (Array.isArray(content)) {
    localStorage.setItem(collectionId, JSON.stringify(content.slice(-10)));
  }
};

const setFontSize = (fontSize: string) => {
  localStorage.setItem(FONT_SIZE_KEY, fontSize);
};

const setSelectedDocumentId = (id: string) => localStorage.setItem(DOCUMENT_KEY, id);

const removeSelectedDocumentId = () => localStorage.removeItem(DOCUMENT_KEY);

const setUserData = (userData: User) => localStorage.setItem(USER_KEY, JSON.stringify(userData));

const setMeta = (meta: Meta) => {
  localStorage.setItem(META_KEY, JSON.stringify(meta));
};

const removeMeta = () => localStorage.removeItem(META_KEY);

const isOldUI = () => localStorage.getItem(LocalStorageService.UI_KEY) === "old";

export default {
  IS_ONLYOFFICE_ENABLED_KEY,
  TOKEN_EXPIRY_KEY,
  UI_KEY,
  FONT_SIZE_KEY,
  isJwtTokenExists,
  useUserPreference,
  getAuthToken,
  getAuthTokenExpiryInMs,
  getFontSize,
  getMetaByKey,
  getSelectedDocumentId,
  getUI,
  removeAuthToken,
  removeMeta,
  removeSelectedDocumentId,
  setAuthToken,
  setFontSize,
  setChatHistory,
  setMeta,
  setSelectedDocumentId,
  setUI,
  setUserData,
  isOldUI,
};
