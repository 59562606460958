import React from "react";
import { ScrollArea } from "@mantine/core";

const Team: React.FC = React.memo(() => {
  const JulesColeman = () => (
    <div className="arc-info-card p-4">
      <div className="grayscale bg-[url(/images/jules-coleman.jpg)] bg-center bg-cover h-[256px] rounded-xl"></div>
      <div>
        <p className="font-semibold text-2xl my-4">Jules Coleman</p>
        <ul className="list-disc leading-tight text-sm text-slate-600 dark:text-slate-300 pl-4">
          <li>Chief Learning Officer of Constructor Knowledge</li>
          <li>
            Former Wesley Newcomb Professor of Jurisprudence, Yale Law School
          </li>
          <li>
            Former Senior Vice Provost Academic Planning, New York University
          </li>
        </ul>
      </div>
    </div>
  );

  const DavidSchwarts = () => (
    <div className="arc-info-card p-4">
      <div className="grayscale bg-[url(/images/david-j-schwartz.jpg)] bg-center bg-cover h-[256px] rounded-xl"></div>
      <div>
        <p className="font-semibold text-2xl my-4">David J. Schwartz</p>
        <ul className="list-disc leading-tight text-sm text-slate-600 dark:text-slate-300 pl-4">
          <li>
            Chief Legal Officer with 25 years of experience at global consumer
            businesses, including HBC and Toys "R" Us, Inc.
          </li>
          <li>
            Most recently, co-led the launch of Saks Cloud Services, an IT
            services company focused on the retail industry.
          </li>
        </ul>
      </div>
    </div>
  );

  const GordonCaplan = () => (
    <div className="arc-info-card p-4">
      <div className="grayscale bg-[url(/images/gordon-caplan.jpg)] bg-center bg-cover h-[256px] rounded-xl"></div>
      <div>
        <p className="font-semibold text-2xl my-4">Gordon Caplan</p>
        <ul className="list-disc leading-tight text-sm text-slate-600 dark:text-slate-300 pl-4">
          <li>Chief Executive Officer of Dutchess Management LLC.</li>
          <li>Former Co-Chairman, Willkie Farr & Gallager LLP.</li>
        </ul>
      </div>
    </div>
  );

  const PaulJeffries = () => (
    <div className="arc-info-card p-4">
      <div className="grayscale bg-[url(/images/paul-jeffries.jpg)] bg-center bg-cover h-[256px] rounded-xl"></div>
      <div>
        <p className="font-semibold text-2xl my-4">Paul Jeffries</p>
        <ul className="list-disc leading-tight text-sm text-slate-600 dark:text-slate-300 pl-4">
          <li>FounderPool, Managing Director of Slipstream Venture Capital</li>
          <li>Former Facebook (Meta) Head of Legal Operations</li>
        </ul>
      </div>
    </div>
  );

  const RuslanFazlyev = () => (
    <div className="arc-info-card p-4">
      <div className="grayscale bg-[url(/images/ruslan-fazlyev.png)] bg-center bg-cover h-[256px] rounded-xl"></div>
      <div>
        <p className="font-semibold text-2xl my-4">Ruslan Fazlyev</p>
        <ul className="list-disc leading-tight text-sm text-slate-600 dark:text-slate-300 pl-4">
          <li>
            Serial entrepreneur and eCommerce pioneer, founder and CEO of X-Cart
            and Ecwid, platforms that enabled hundreds of thousands small
            businesses to sell online successfully.
          </li>
          <li>
            X-Cart was acquired by SellerLabs, Ecwid was acquired by NYSE:LSPD
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <section className="pt-10">
      <div className="grid grid-cols-1 max-w-[1280px] px-4 lg:px-8 xl:px-1">
        <h1 className="text-2xl lg:mt-12 lg:text-4xl xl:text-5xl font-semibold dark:text-gray-100 py-4">
          Meet the team
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-11 gap-4 pb-6">
          <div className="col-span-4">
            <span className="font-semibold text-xl lg:text-2xl xl:text-3xl w-full text-center">
              Founder and CEO
            </span>
          </div>
          <div className="col-span-7 hidden lg:inline-block">
            <span className="font-semibold text-xl lg:text-2xl xl:text-3xl w-full text-center">
              Industry Advisors
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-11 lg:grid-rows-1 gap-4">
          <div className="col-span-7 lg:col-span-4">
            <div className="arc-info-card p-4 w-full md:w-8/12 lg:w-full m-auto">
              <div className="grayscale bg-[url(/images/katya-fisher.png)] bg-center bg-cover h-[24rem] sm:h-[36rem] md:h-[40rem] w-full rounded-xl"></div>
              <div>
                <p className="font-semibold text-2xl my-4">Katya Fisher</p>
                <ul className="list-disc leading-tight text-sm text-slate-600 dark:text-slate-300 pl-4">
                  <li>
                    Vice Chairman of Constructor Group www.constructor.org
                    overseeing legal teams and governance across the global
                    ecosystem
                  </li>
                  <li>Former AMLaw 200 partner, technology transactions</li>
                  <li>
                    Adjunct Professor, Law & Technology, Florida State
                    University School of Law
                  </li>
                  <li>
                    Boardmember, Global Blockchain Acquisition Corp (NASDAQ:
                    GBBKU)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-span-7 flex lg:hidden flex-row items-start pt-4">
            <span className="font-semibold text-xl lg:text-2xl xl:text-3xl w-full">
              Industry Advisors
            </span>
          </div>
          <div className="col-span-7 grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="hidden md:flex flex-col gap-4">
              <JulesColeman />
              <DavidSchwarts />
            </div>
            <div className="hidden md:flex flex-col gap-4">
              <GordonCaplan />
              <PaulJeffries />
            </div>
            <div className="hidden md:flex flex-col gap-4">
              <RuslanFazlyev />
            </div>
            <ScrollArea
              type="always"
              className="[&_.mantine-ScrollArea-thumb]:bg-teal-400"
            >
              <div className="grid md:hidden grid-cols-[repeat(5,auto)] gap-4 pb-4 overflow-y-auto">
                <div className="min-w-[300px] [&>div]:h-full">
                  <JulesColeman />
                </div>
                <div className="min-w-[300px] [&>div]:h-full">
                  <GordonCaplan />
                </div>
                <div className="min-w-[300px] [&>div]:h-full">
                  <DavidSchwarts />
                </div>
                <div className="min-w-[300px] [&>div]:h-full">
                  <PaulJeffries />
                </div>
                <div className="min-w-[300px] [&>div]:h-full">
                  <RuslanFazlyev />
                </div>
              </div>
            </ScrollArea>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Team;
