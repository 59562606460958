import { FC, PropsWithChildren } from "react";

interface AlertProps {
  type: "success" | "info" | "warning" | "danger";
  visible?: boolean;
}

const typeTailwindColorMap = {
  success: {
    textColor: "ar-dark",
    bgColor: "bg-lime-100",
    borderColor: "border-2 border-lime-600",
  },
  info: {
    textColor: "ar-dark",
    bgColor: "bg-sky-100",
    borderColor: "border border-sky-600",
  },
  warning: {
    textColor: "ar-dark",
    bgColor: "bg-orange-100",
    borderColor: "border border-orange-600",
  },
  danger: {
    textColor: "ar-dark",
    bgColor: "bg-red-100",
    borderColor: "border border-red-600",
  },
};

export const Alert: FC<PropsWithChildren<AlertProps>> = ({ type = "info", visible, children }) => {
  const { textColor, bgColor, borderColor } = typeTailwindColorMap[type];

  return visible ? (
    <div className={`${[textColor, bgColor, borderColor].join(" ")} px-8 py-2 rounded-lg`}>
      {children}
    </div>
  ) : null;
};
