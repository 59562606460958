export enum EQaMessageType {
  USER = "USER",
  BOT = "BOT",
  RESPONSE_WITH_OPTIONS = "RESPONSE_WITH_OPTIONS",
}

export interface IQaQuestionSuggestions {
  options: string[];
}

export interface IQaChatReference {
  id: number;
  message_id: number;
  source_document_id: string;
  referenced_text: string;
  location_indices_in_message: number[];
}

export interface IQaChatHistory {
  id: number;
  message: string;
  message_type: EQaMessageType;
  additional_data: IQaQuestionSuggestions | null;
  responds_to: string | number | null;
  references: IQaChatReference[] | null;
  created_at: string;
  updated_at: string;
}

export enum ChatResponseType {
  JSON = "JSON",
  TEXT_STREAM = "TEXT_STREAM",
}

export interface ChatResponse {
  chat_response_type: ChatResponseType;
  message: IQaChatHistory | null;
  full_response_url: string | null;
  query_message: IQaChatHistory;
  response_message: IQaChatHistory;
}
