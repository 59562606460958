import React, { useEffect, useState } from "react";
import { useAppDispatch } from "@/redux/hooks.ts";
import { setIsDocumentLoaded } from "@/redux/slices/documents.ts";
import { setHighlightedReference } from "@/redux/slices/qa.ts";

import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  HoverCard,
  TextInput,
  ScrollArea,
  Tooltip,
} from "@mantine/core";
import { useForm, Controller } from "react-hook-form";
import { IconCircleX } from "@tabler/icons-react";
import { humanFileSize } from "@/utils";
import { useAddDocumentsToCollectionMutation, useUploadDocumentMutation } from "@/redux/api";
import { Drop } from "@/components/Rio";
import { modals } from "@mantine/modals";
import { FileTreeItem, Modals } from "@common/types";
import { CloudDocsService } from "@/services/index.ts";
import { ROUTES } from "@/constants";

interface UploadProps {
  collection?: FileTreeItem;
  selectedDocumentsToDownload?: string[];
  isFolderInputForced?: boolean;
  selectedProvider?: string;
}

interface FormValues {
  folder: string;
  files: File[];
  isRedact: boolean;
}

const Upload: React.FC<UploadProps> = ({
  collection,
  selectedDocumentsToDownload,
  isFolderInputForced = false,
  selectedProvider,
}) => {
  const [filesToUpload, setFilesToUpload] = useState<
    ({ name: string; size: number | null } | File)[]
  >([]);
  const [showInputFolder, setShowInputFolder] = useState<boolean>(false);

  const appDispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      folder: "",
      files: [],
      isRedact: false,
    },
  });

  const watchFiles = watch("files", []);
  const files = getValues("files");

  const [uploadDocument, { isLoading: isUploadDocumentLoading }] = useUploadDocumentMutation();

  const [addDocumentsToCollection, { isLoading: isAddDocumentsToCollectionLoading }] =
    useAddDocumentsToCollectionMutation();

  useEffect(() => {
    if (collection) {
      setValue("folder", collection.name);
    }
  }, [setValue, collection]);

  useEffect(() => {
    if (selectedDocumentsToDownload?.length) {
      // This code is necessary for now due to differing file structures between documents retrieved from providers and those manually uploaded by the user.
      const transformedFiles =
        selectedDocumentsToDownload?.map((path) => {
          const fileName = path.split("/").pop();
          return { name: fileName || "", size: null };
        }) || [];

      setFilesToUpload(transformedFiles);
    } else {
      setFilesToUpload(files);
    }
  }, [selectedDocumentsToDownload, files]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isFolderInputForced) {
      setShowInputFolder(event.currentTarget.checked);
    }
  };

  const closeAndResetForm = () => {
    modals.close(Modals.UPLOAD);
    reset();
  };

  const handleRemoveFile = (fileIndex: number) => {
    // The first setFilesToUpload() works for the files that are added from Provider and the part with newFilesSet works only for manual uploaded documents
    setFilesToUpload((prevFiles) => prevFiles.filter((_, index) => index !== fileIndex));
    const newFilesSet = getValues("files")?.filter((_file, index) => fileIndex !== index);

    if (newFilesSet) {
      setValue("files", newFilesSet);
      setFilesToUpload(newFilesSet);
    }
    // TODO(Ihor Dubas): Think how to use only one setFilesToUpload()
  };

  const handleUploadClick = async ({ folder, isRedact }: FormValues) => {
    if (selectedDocumentsToDownload?.length) {
      await CloudDocsService.downloadDocumentsFromProvider(
        selectedDocumentsToDownload,
        isRedact,
        folder,
        selectedProvider
      );
    } else {
      const formData = new FormData();

      if (watchFiles?.length) {
        Array.from(files).forEach((file) => {
          formData.append("files", file);
        });
      }
  
      appDispatch(setIsDocumentLoaded(false));
      appDispatch(setHighlightedReference(null));
  
      const options = folder ? { folder_name: folder, redact: isRedact } : { redact: isRedact };
  
      if (collection) {
        await addDocumentsToCollection({ collection_id: collection.collection_id, data: formData });
      } else {
        await uploadDocument({ data: formData, ...options });
      }
    }
    // TODO(Ihor Dubas) Replace with more elegant soltion as navigate() won't work here because current component isn't part of Router
    if (showInputFolder || isFolderInputForced) {
      window.location.href = `${ROUTES.DOCUMENTS}/folders`;
    } else {
      window.location.href = `${ROUTES.DOCUMENTS}/files`;
    }

    closeAndResetForm();
  };

  return (
    <>
      <div className="flex flex-col gap-2 min-h-[20dvh]">
        {process.env.VITE_IS_MULTI_CHAT_ENABLED === "true" ? (
          <>
            <div className="flex flex-row gap-1">
              <Checkbox
                label="Upload all documents to a folder"
                checked={isFolderInputForced || showInputFolder}
                onChange={handleCheckboxChange}
                disabled={isFolderInputForced}
                color="violet"
              />
              <HoverCard>
                <HoverCard.Target>
                  <span className="place-content-start material-symbols-outlined text-ar-dark">
                    info
                  </span>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <div className="max-w-72">
                    Allows grouping up documents into a folder and chatting with all of the
                    documents in the folder at the same time. Use this to easily retrieve
                    information from multiple documents, compare documents, and more.
                  </div>
                </HoverCard.Dropdown>
              </HoverCard>
            </div>
            {(showInputFolder || isFolderInputForced) && (
              <Controller
                name="folder"
                control={control}
                render={({ field }) => (
                  <TextInput
                    type="text"
                    size="lg"
                    placeholder="Enter folder name*"
                    error={errors.folder?.type === "required"}
                    disabled={!!collection}
                    className="flex-shrink"
                    {...field}
                  />
                )}
                rules={{
                  validate: {
                    required: (value) => !!value.length,
                  },
                }}
              />
            )}
          </>
        ) : null}
        <div className="flex flex-col gap-2 overflow-y-scroll">
          <ScrollArea type="auto">
            <div className="max-h-[200px] flex flex-col gap-0.5">
              {filesToUpload?.length
                ? filesToUpload?.map(({ name, size }, index) => (
                    <div
                      className={`grid ${selectedDocumentsToDownload?.length ? "grid-cols-[1fr_6rem]" : "grid-cols-[1fr_6rem_6rem]"} items-center gap-2 h-12 min-h-12 px-3 bg-slate-100 hover:bg-transparent`}
                      key={index}
                    >
                      <div className="truncate">
                        <Tooltip label={name}>
                          <span className="truncate w-full">{name}</span>
                        </Tooltip>
                      </div>
                      {size && <div className="justify-self-end">{humanFileSize(size)}</div>}
                      <div className="justify-self-end">
                        <ActionIcon
                          variant="transparent"
                          radius={50}
                          color="#f87171"
                          onClick={() => handleRemoveFile(index)}
                        >
                          <IconCircleX stroke={1.5} />
                        </ActionIcon>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </ScrollArea>
        </div>
        {!selectedDocumentsToDownload?.length ? <Drop files={files} setValue={setValue} /> : null}
        <form onSubmit={handleSubmit(handleUploadClick)}>
          <div className="grid grid-cols-[1fr_auto] gap-6 pt-4">
            {process.env.VITE_IS_REDACTION_ENABLED === "true" && !collection?.collection_id ? (
              <>
                <Controller
                  name="isRedact"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Checkbox.Card
                      className="p-4 hover:bg-gray-100"
                      checked={value}
                      radius="md"
                      onClick={() => setValue("isRedact", !value)}
                      {...field}
                    >
                      <Group className="m-4" wrap="nowrap" align="flex-start">
                        <Checkbox.Indicator color="violet" />
                        <div>
                          <div className="flex flex-row gap-1 font-semibold">
                            Redact Documents
                            <sup>
                              <HoverCard>
                                <HoverCard.Target>
                                  <span className="place-content-start material-symbols-outlined text-ar-dark">
                                    info
                                  </span>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                  <div className="max-w-72">
                                    <strong>
                                      Note: This feature is work-in-progress and may not redact all
                                      sensitive information yet.
                                    </strong>
                                    <br />
                                    <br />
                                    Redact sensitive information such as names, addresses, phone
                                    numbers, etc. from the documents you upload so that your or your
                                    client's privacy is protected from the AIs that we use to
                                    analyze the documents. You will have a chance to check the
                                    redacted version of the document before starting a conversation
                                    with the AI.
                                    <br />
                                    <br />
                                    <i>
                                      Note: Redaction of your documents takes extra resources and
                                      may noticeably slow down the workflow with your redacted
                                      documents.
                                    </i>
                                  </div>
                                </HoverCard.Dropdown>
                              </HoverCard>
                            </sup>
                          </div>
                          <div className="text-sm text-slate-600">
                            Clear documents from sensitive information
                          </div>
                        </div>
                      </Group>
                    </Checkbox.Card>
                  )}
                />
              </>
            ) : (
              <div />
            )}
            <Button
              className="self-end"
              color="#8b5cf6"
              loading={isAddDocumentsToCollectionLoading || isUploadDocumentLoading}
              disabled={!filesToUpload?.length}
              size="lg"
              type="submit"
            >
              Upload
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Upload;
