import api from "@/api.ts";
import { ApiPayload, ResponsePayload, ProvidersFileTree, Providers } from "@/common/types";
import { mapToResponsePayload, normalizeResponseError } from "@/utils";

const getQueryParamsFromProviderUrl = () => {
  const parsedUrl = new URL(window.location.href);
  const params = new URLSearchParams(parsedUrl.search);

  const code = params.get("code");
  const state = params.get("state");
  const sessionState = params.get("session_state");

  const match = parsedUrl.pathname.match(/\/rio\/(.+)_callback/);

  let provider;
  if (match) {
    provider = match[1] === Providers.BOX_COM.toLowerCase() ? match[1] : match[1].replace(/_/g, "");
  }

  return { code, state, sessionState, provider };
};

const processProviderCallback = async () => {
  const { code, state, sessionState, provider } = getQueryParamsFromProviderUrl();

  let params: {
    code: string | null;
    provider_name?: string | null;
    state?: string | null;
    session_state?: string | null;
  } = {
    code,
    provider_name: provider?.toUpperCase(),
  };

  if (provider === Providers.SHAREPOINT) {
    params = {
      code,
      provider_name: provider?.toUpperCase(),
      state,
      session_state: sessionState,
    };
  }

  try {
    await api.get("/storage-providers/auth_callback", {
      params,
    });
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const handleProviderConnection = async (provider_name: string) => {
  try {
    const { data } = await api.get("/storage-providers/get_authorize_url", {
      params: {
        provider_name,
      },
    });
    window.open(data.data.url, "_blank");
  } catch (error) {
    console.error("Error during authorization:", error);
  }
};

const getFileTreeDataFromProvider = async (
  provider_name: string,
  path?: string
): Promise<ProvidersFileTree> => {
  try {
    const { data } = await api.get("/storage-providers/filetree", {
      params: {
        provider_name,
        path,
      },
    });
    return data.data;
  } catch (error) {
    console.error("Error fetching file tree data:", error);
    throw error;
  }
};

const downloadDocumentsFromProvider = async (
  documents: string[],
  isRedacted: boolean,
  folder_name: string,
  provider_name?: string
): Promise<ResponsePayload<boolean | void>> => {
  try {
    const { data } = await api.post<ApiPayload<boolean>>(
      `/storage-providers/download_files?provider_name=${provider_name}&folder_name=${folder_name}&redact=${isRedacted}`,
      documents,
      {
        headers: {
          accept: "application/json",
        },
      }
    );

    return mapToResponsePayload<boolean>(data);
  } catch (error: unknown) {
    throw new Error(normalizeResponseError(error));
  }
};

export default {
  processProviderCallback,
  handleProviderConnection,
  getFileTreeDataFromProvider,
  downloadDocumentsFromProvider,
  getQueryParamsFromProviderUrl,
};
