import React, { memo } from "react";
import { NavLink, Tooltip } from "@mantine/core";
import { FileTreeItem } from "@common/types";

interface TreeItemProps {
  document: FileTreeItem;
  isActive: boolean;
  isOpened?: boolean;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

export const FilesTreeItem: React.FC<React.PropsWithChildren<TreeItemProps>> = memo(
  ({ children, document, isOpened = false, isActive = false, onClick }) => {
    const isFolder = Array.isArray(document.children) && !document.parent_folder_id;
    const isChild = document.parent_folder_id;

    return (
      <NavLink
        label={
          <Tooltip label={`${document.name}${document.document_original_extension ?? ""}`}>
            <div className="grid grid-cols-[1fr] group">
              <div className="truncate w-full text-white">
                <span className={isChild ? "text-base" : "text-base font-semibold"}>
                  {document.name}
                  {document.document_original_extension ?? ""}
                </span>
              </div>
            </div>
          </Tooltip>
        }
        leftSection={
          <div className={isChild ? "pl-4" : ""}>
            <span className="material-symbols-outlined text-white">
              {isFolder ? (isOpened ? "folder_open" : "folder") : "article"}
            </span>
          </div>
        }
        active={isActive}
        opened={isOpened}
        onClick={onClick}
        childrenOffset={0}
        color="ar-accent"
        variant="filled"
        className={`
          ${isChild ? (isActive ? "!bg-ar-gray/30 last-of-type:rounded-b-md" : "") : ""}
          ${isFolder ? (isActive ? "!bg-ar-accent rounded-t-md " : "[&_.mantine-NavLink-root]:!bg-yellow-200") : ""}
          [&_.mantine-NavLink-chevron]:text-white
          hover:!bg-white/10
        `}
      >
        {children}
      </NavLink>
    );
  }
);
