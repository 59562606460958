import React from "react";
import { Checkbox, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import { FileTreeItem, SelectedFileTreeItem, Providers } from "@common/types";
import { UseFormRegister } from "react-hook-form";
import { SharePointIcon, OneDriveIcon } from "@/components/CloudProvider/components/ProviderIcons";
import { FileActions } from "@/components/New/components/General/components/DocumentsAndFolders/components/FileActions";

type SelectedRecord = Record<string, SelectedFileTreeItem>;

interface FormValues {
  search: string;
  selected: SelectedRecord;
  isSelectAll: boolean;
}

interface DocumentProps {
  document: FileTreeItem;
  register: UseFormRegister<FormValues>;
  onDocumentClick: (document: FileTreeItem) => void;
  onDownloadClick: (document: FileTreeItem) => void;
  onDeleteClick: (document: FileTreeItem) => void;
  onSendCopyClick: (file: FileTreeItem) => void;
}

export const File: React.FC<DocumentProps> = React.memo(
  ({ document, register, onDeleteClick, onDocumentClick, onDownloadClick, onSendCopyClick }) => {
    const renderIcon = (name: string | null): JSX.Element | null => {
      switch (name) {
        case Providers.SHAREPOINT:
          return SharePointIcon;
        case Providers.ONEDRIVE:
          return OneDriveIcon;
        default:
          return null;
      }
    };

    return (
      <div
        key={document.id}
        className="grid grid-cols-[2rem_1fr_auto_auto_auto] justify-start items-center gap-4
                  p-4 font-['National']
                  bg-zinc-100 hover:bg-transparent
                  border-b border-neutral-200
                  group"
      >
        <div className="z-0">
          <div className="flex items-center">
            <Checkbox
              color="ar-accent"
              variant="outline"
              size="xs"
              radius="xs"
              {...register(`selected.${document.id}.isSelected`)}
              className="mr-2"
              styles={{
                input: {
                  backgroundColor: "#F2F0EF",
                  borderColor: "#3C343199",
                  borderWidth: "1.5px",
                },
              }}
            />
            <div>{renderIcon(document.external_provider)}</div>
          </div>
        </div>
        <div className="justify-self-start truncate w-full ml-5 text-lg">
          <Tooltip label={`${document.name}${document.document_original_extension ?? ""}`}>
            <span
              className="hover:underline cursor-pointer"
              onClick={() => onDocumentClick(document)}
            >
              {document.name}
              {document.document_original_extension ?? ""}
            </span>
          </Tooltip>
        </div>
        <FileActions
          document={document}
          onDelete={onDeleteClick}
          onDownload={onDownloadClick}
          onSendCopy={onSendCopyClick}
        />
        <div className="flex flex-col justify-center items-start w-full border-l border-stone-700/30 pl-2 text-xs leading-[1rem]">
          <span className="font-semibold text-stone-700">Uploaded</span>
          <div>{dayjs(document.created_at).format("DD.MM.YYYY, HH:mm")}</div>
        </div>
        <div className="flex flex-col justify-center items-start w-full border-l border-stone-700/30 pl-2 text-xs leading-[1rem]">
          <span className="font-semibold text-stone-700">Edited</span>
          <div>{dayjs(document.updated_at).format("DD.MM.YYYY, HH:mm")}</div>
        </div>
      </div>
    );
  }
);
