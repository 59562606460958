import { Button } from "@mantine/core";
import buttonClasses from "./components/button.module.css";
import navLinkClasses from "./components/nav-link.module.css";
import { colors } from "./theme/colors.ts";

export default {
  colors,
  components: {
    Button: Button.extend({
      classNames: buttonClasses,
    }),
    NavLink: Button.extend({
      classNames: navLinkClasses,
    }),
  },
};
