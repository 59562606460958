import React from "react";
import { EFontSize } from "@common/types";
import { useSelector } from "react-redux";
import { getFontSize } from "@/redux/slices";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'

const fontSizeClassMap: Record<EFontSize, string> = {
  xs: "prose-xs",
  sm: "prose-sm",
  base: "prose-base",
  lg: "prose-lg",
  xl: "prose-xl",
};

type Props = {
  text: string | undefined;
};

const MessageItem: React.FC<Props> = ({ text }: Props) => {
  const fontSize = useSelector(getFontSize);

  return (
    <div className="bg-white p-2 mb-4 rounded-tr-xl rounded-br-xl rounded-bl-xl">
      <div
        className={`bg-white p-2 rounded-tr-xl rounded-br-xl rounded-bl-xl prose ${fontSizeClassMap[(fontSize as EFontSize) || "base"]} prose-strong:text-[--mantine-color-text] text-[color:var(--mantine-color-text)] pl-1 py-1 [overflow-wrap:anywhere]`}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
      </div>
    </div>
  );
};

export default MessageItem;
