import React from "react";
import { AracorRIO, Footer, Header, HowItWorks, Team, WhatWeDo } from "./components";

const Home: React.FC = () => (
  <div className="flex flex-row justify-center bg-sky-100 dark:bg-slate-700 font-['Commissioner']">
    <div className="flex flex-col items-center w-full min-w-[375px] max-w-[1920px] z-10">
      <Header />
      <WhatWeDo />
      <AracorRIO />
      <HowItWorks />
      <Team />
      <Footer />
    </div>
  </div>
);

export default Home;
