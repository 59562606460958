import React, { Dispatch } from "react";
import { ActionIcon, Tooltip } from "@mantine/core";
import { DueDiligenceMessage } from "@/common/types";
import ChatWrapper from "../../ChatWrapper";
import MessageItem from "@/components/Rio/components/MessageItem";
import Loader from "@/components/Rio/components/Loader";

type Props = {
  isLoading: boolean;
  errorText: string | undefined;
  setErrorText: Dispatch<React.SetStateAction<string>>;
  handleRetry?: () => void;
  text?: string | undefined;
  documentSummaries?: DueDiligenceMessage[] | undefined;
};

const DueDiligenceTabContent: React.FC<Props> = ({
  isLoading,
  errorText,
  text,
  documentSummaries,
  setErrorText,
  handleRetry,
}: Props) => {
  return (
    <ChatWrapper>
      {errorText && !isLoading && (
        <div
          className={`grid grid-cols-[1fr_auto] gap-2 mx-4 my-2 p-4 rounded-xl animate-[fade-in-keyframe_0.5s_ease-in-out] bg-red-200`}
        >
          <div>{errorText}</div>
          <div className="flex flex-row justify-between items-center">
            {handleRetry && (
              <Tooltip label="Retry">
                <ActionIcon key="reload" variant="transparent" onClick={handleRetry}>
                  <span className="material-symbols-outlined text-ar-dark">refresh</span>
                </ActionIcon>
              </Tooltip>
            )}
            <Tooltip label="Close">
              <ActionIcon
                key="close"
                variant="transparent"
                onClick={() => {
                  setErrorText("");
                }}
              >
                <span className="material-symbols-outlined text-ar-dark">close</span>
              </ActionIcon>
            </Tooltip>
          </div>
        </div>
      )}
      {isLoading && <Loader red />}
      <div
        className="
          flex flex-col gap-3 flex-1
          overflow-y-auto mx-4
          [-webkit-scrollbar]:w-0.5 [-webkit-scrollbar-thumb]:!bg-slate-400"
      >
        {text && <MessageItem text={text} />}
        {documentSummaries &&
          documentSummaries.map((doc) => <MessageItem key={doc.document_id} text={doc.text} />)}
      </div>
    </ChatWrapper>
  );
};

export default DueDiligenceTabContent;
