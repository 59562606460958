import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { Worker } from "@react-pdf-viewer/core";
import {
  CSSVariablesResolver,
  MantineProvider,
  createTheme,
  localStorageColorSchemeManager,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";

import store from "@/redux/store";
import * as Sentry from "@sentry/react";

import "./index.css";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import mantineThemeConfig from "./mantine";

import {
  CustomNextButton,
  CustomPrevButton,
  stepsData,
} from "./components/New/components/General/components/UserGuideTour";
import { TourProvider } from "@reactour/tour";
import Navigation from "@/router";
import { pdfjsUrl } from "./constants/other";

const aracorDefaultLight = createTheme({
  ...mantineThemeConfig,
  fontFamily: "National, sans-serif",
  headings: { fontFamily: "National, sans-serif" },
});

const colorSchemeManager = localStorageColorSchemeManager({
  key: "theme", // for compatibility with tailwindcss
});

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    "--mantine-line-height": "1.5rem",
  },
  light: {
    "--mantine-color-deep-orange": theme.other.deepOrangeLight,
  },
  dark: {
    "--mantine-color-deep-orange": theme.other.deepOrangeDark,
  },
});

Sentry.init({
  dsn: "https://6e64ae213beb7b0109bb5601db9717da@o4505964268224512.ingest.us.sentry.io/4506432829652992",
  integrations: [Sentry.replayIntegration()],
  // Session Replay settings
  // This sets the sample rate at 10%. You may want to change it to 100% while in development
  // and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.1,
  // If you're not already sampling the entire session, change the sample rate to 100% when
  // sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
  enabled:
    process.env.VITE_APP_MODE === "production" || process.env.VITE_APP_MODE === "development",
  environment: process.env.VITE_APP_MODE === "production" ? "prod" : "dev",
});

ReactDOM.createRoot(document.getElementById("root") as Element).render(
  <React.StrictMode>
    <Provider store={store}>
      <TourProvider
        steps={stepsData}
        showDots={false}
        showBadge={false}
        styles={{
          popover: (base) => ({
            ...base,
            borderRadius: 20,
          }),
          controls: (base) => ({ ...base, marginTop: 40, justifyContent: "flexStart", gap: 20 }),
        }}
        prevButton={CustomPrevButton}
        nextButton={CustomNextButton}
        position="right"
      >
        <MantineProvider
          theme={aracorDefaultLight}
          colorSchemeManager={colorSchemeManager}
          cssVariablesResolver={resolver}
          classNamesPrefix="mantine"
        >
          <Notifications position="bottom-center" />
          <ModalsProvider modalProps={{ centered: true, padding: "lg", radius: "md", size: "sm" }}>
            <Worker workerUrl={pdfjsUrl}>
              <Navigation />
            </Worker>
          </ModalsProvider>
        </MantineProvider>
      </TourProvider>
    </Provider>
  </React.StrictMode>
);
