import React from "react";
import { Button } from "@mantine/core";
import { Provider, ProvidersFileTree } from "@/common/types";
import { CloudDocsService } from "@/services";

interface ProviderItemProps extends Provider {
  connectedProviders: string[];
  onFileTreeLoad: (fileTreeData: ProvidersFileTree) => void;
  onProviderItemClick: (provider: string) => void;
}

export const ProviderItem: React.FC<ProviderItemProps> = ({
  id,
  name,
  icon,
  connectedProviders,
  onFileTreeLoad,
  onProviderItemClick,
}) => {
  const isProviderConnected = connectedProviders.includes(id);

  const handleProviderClick = async () => {
    if (!isProviderConnected) {
      CloudDocsService.handleProviderConnection(id);
    } else {
      const fileTreeData: ProvidersFileTree =
        await CloudDocsService.getFileTreeDataFromProvider(id);

      onProviderItemClick(id);
      onFileTreeLoad(fileTreeData);
    }
  };
  return (
    <Button
      radius="md"
      size="md"
      onClick={handleProviderClick}
      leftSection={icon}
      variant="default"
      className="relative"
    >
      {name}
    </Button>
  );
};

export default ProviderItem;
