import { ActionIcon } from "@mantine/core";
import clsx from "clsx/lite";

interface ActionCardProps {
  onClick?: () => void;
  title: string;
  description: string;
  icon: string;
  className?: string;
}

const ActionCard = ({ onClick, title, icon, description, className }: ActionCardProps) => {
  return (
    <div
      className={clsx(
        "flex flex-row gap-5 p-5 bg-ar-beige text-ar-dark rounded-lg hover:cursor-pointer",
        className
      )}
      onClick={onClick}
    >
      <div className="flex-grow flex flex-col gap-4">
        <div className="font-medium text-base">{title}</div>
        <div className="font-normal text-sm">{description}</div>
      </div>
      <div className="flex-shrink self-center">
        <ActionIcon
          variant="filled"
          size="xl"
          radius="xl"
          color="white"
          className="hover:!bg-ar-gray/40"
        >
          <span className="material-symbols-outlined text-ar-dark">{icon}</span>
        </ActionIcon>
      </div>
    </div>
  );
};

export default ActionCard;
