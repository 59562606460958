import React from "react";
import { Button, Menu, Tabs as MantineTabs, TextInput } from "@mantine/core";
import { useSelector } from "react-redux";
import { getSortOrder } from "@/redux/slices";
import { Control, Controller } from "react-hook-form";
import { SelectedFileTreeItem } from "@common/types";

type SelectedRecord = Record<string, SelectedFileTreeItem>;

interface FormValues {
  search: string;
  selected: SelectedRecord;
  isSelectAll: boolean;
}

interface ControlsProps {
  control: Control<FormValues>;
  onSortChange: (value: string) => void;
  fileCount: number;
  folderCount: number;
}

export const Tabs: React.FC<ControlsProps> = ({
  fileCount,
  folderCount,
  control,
  onSortChange = () => {},
}) => {
  const sortOrder = useSelector(getSortOrder);

  return (
    <div className="flex flex-row justify-between items-end h-20 min-h-20 max-h-20">
      <div className="flex-grow">
        <MantineTabs.List>
          <MantineTabs.Tab value="files" className="data-[active=true]:!font-bold">
            <div className="flex items-center gap-2">
              <span className="uppercase font-bold text-sm" style={{ letterSpacing: "2px" }}>
                Files
              </span>
              <span className="text-ar-accent">{fileCount}</span>
            </div>
          </MantineTabs.Tab>
          <MantineTabs.Tab value="folders" className="data-[active=true]:font-bold">
            <div className="flex items-center gap-2">
              <span className="uppercase font-bold text-sm" style={{ letterSpacing: "2px" }}>
                Folders
              </span>
              <span className="text-ar-accent">{folderCount}</span>
            </div>
          </MantineTabs.Tab>
        </MantineTabs.List>
      </div>
      <div className="flex-grow flex flex-row justify-end items-center gap-2 pb-1.5 border-b-2">
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              variant="unstyled"
              placeholder="Search"
              rightSection={<span className="material-symbols-outlined text-ar-dark">search</span>}
              classNames={{
                input:
                  "!w-24 focus:!w-64 placeholder:!text-ar-dark focus:placeholder:!text-ar-gray transition-all duration-300 ease-in-out",
              }}
            />
          )}
        />
        <Menu>
          <Menu.Target>
            <Button
              color="ar-accent"
              variant="transparent"
              size="md"
              rightSection={
                <span className="material-symbols-outlined text-md text-ar-accent">
                  keyboard_arrow_down
                </span>
              }
            >
              <span className="text-ar-dark pr-2">Sort:</span>
              {sortOrder.split("-").join(" (") + ")"}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>
              <span className=" font-semibold text-gray-600">Sort by (Asc)</span>
            </Menu.Label>
            <Menu.Item
              leftSection={
                <div>
                  <span className="material-symbols-outlined">sort_by_alpha</span>
                  <span className="material-symbols-outlined">arrow_drop_up</span>
                </div>
              }
              onClick={() => onSortChange("name-asc")}
            >
              Ascending
            </Menu.Item>
            <Menu.Item
              leftSection={
                <div>
                  <span className="material-symbols-outlined">sort_by_alpha</span>
                  <span className="material-symbols-outlined">arrow_drop_down</span>
                </div>
              }
              onClick={() => onSortChange("name-desc")}
            >
              Descending
            </Menu.Item>
            <Menu.Divider />
            <Menu.Label>
              <span className=" font-semibold text-gray-600">UPLOADED</span>
            </Menu.Label>
            <Menu.Item
              leftSection={
                <div>
                  <span className="material-symbols-outlined">sort</span>
                  <span className="material-symbols-outlined">arrow_drop_up</span>
                </div>
              }
              onClick={() => onSortChange("uploaded-asc")}
            >
              Ascending
            </Menu.Item>
            <Menu.Item
              leftSection={
                <div>
                  <span className="material-symbols-outlined">sort</span>
                  <span className="material-symbols-outlined">arrow_drop_down</span>
                </div>
              }
              onClick={() => onSortChange("uploaded-desc")}
            >
              Descending
            </Menu.Item>
            <Menu.Divider />
            <Menu.Label>
              <span className=" font-semibold text-gray-600">EDITED</span>
            </Menu.Label>
            <Menu.Item
              leftSection={
                <div>
                  <span className="material-symbols-outlined">sort</span>
                  <span className="material-symbols-outlined">arrow_drop_up</span>
                </div>
              }
              onClick={() => onSortChange("edited-asc")}
            >
              Ascending
            </Menu.Item>
            <Menu.Item
              leftSection={
                <div>
                  <span className="material-symbols-outlined">sort</span>
                  <span className="material-symbols-outlined">arrow_drop_down</span>
                </div>
              }
              onClick={() => onSortChange("edited-desc")}
            >
              Descending
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  );
};
