import api from "./api.ts";
import { ApiPayload, ContractType, Question } from "@common/types";

export const questionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addFavoriteQuestion: builder.mutation<
      ApiPayload<unknown>,
      { question: string; contract_type?: ContractType }
    >({
      query: ({ question, contract_type }) => ({
        url: "questions/add_favorite_question",
        method: "POST",
        headers: {
          accept: "application/json",
        },
        params: {
          question,
          contract_type,
        },
      }),
      invalidatesTags: ["FavoriteQuestions"],
    }),
    getFavoriteQuestions: builder.query({
      query: () => ({
        url: "questions/get_favorite_questions",
      }),
      transformResponse: (response: ApiPayload<{ questions: Question[] }>) =>
        response?.data.questions,
      providesTags: (data) =>
        data
          ? [
              ...data.map(({ id }) => ({ type: "FavoriteQuestions" as const, id })),
              { type: "FavoriteQuestions", id: "LIST" },
            ]
          : [{ type: "FavoriteQuestions", id: "LIST" }],
    }),
    removeFavoriteQuestion: builder.mutation({
      query: ({ question_id }) => ({
        url: "questions/remove_favorite_question",
        method: "DELETE",
        params: {
          question_id,
        },
      }),
      invalidatesTags: (_result, _error, { question_id }) => [
        { type: "FavoriteQuestions", id: question_id },
      ],
    }),
  }),
});

export const {
  useAddFavoriteQuestionMutation,
  useGetFavoriteQuestionsQuery,
  useRemoveFavoriteQuestionMutation,
} = questionsApi;
