import React, { useState } from "react";
import { Button } from "@mantine/core";
import { Upload } from "@/components/New/components/General/components/Upload";

export const Top: React.FC = React.memo(() => {
  const [isUploadVisible, setIsUploadVisible] = useState(false);

  const handleFileUploadClick = () => {
    setIsUploadVisible((prev) => !prev);
  };

  return (
    <>
      <div className="grid grid-cols-[1fr_auto] content-center h-24">
        <div className="text-5xl font-medium">Files & Folders</div>
        <div className="flex flex-row justify-start items-center gap-4 px-4">
          <Button
            color="#3C3431"
            rightSection={<span className="material-symbols-outlined text-lg">upload</span>}
            size="md"
            variant="outline"
            onClick={handleFileUploadClick}
          >
            <span className="text-sm">Upload Document or Folder</span>
          </Button>
        </div>
      </div>
      {isUploadVisible && <Upload />}
    </>
  );
});
