import { Menu, ScrollArea } from "@mantine/core";
import React from "react";

import { ModelChoice } from "@/common/types";

interface PreferredLlmMenuProps {
  title: string;
  choices: ModelChoice[];
  preferredValue: string | null | undefined;
  onClick: (value: string | null) => void;
}

const PreferredLlmMenu: React.FC<PreferredLlmMenuProps> = ({
  title,
  choices,
  preferredValue,
  onClick,
}) => (
  <>
    <Menu.Label>
      <span className="text-sm text-ar-brown">{title}</span>
    </Menu.Label>
    <ScrollArea type="auto" h={270}>
      {choices.map(({ label, value }) => (
        <Menu.Item
          key={value}
          rightSection={
            preferredValue === value ? (
              <span className="material-symbols-outlined">check</span>
            ) : null
          }
          onClick={() => onClick(value)}
        >
          <span className="font-semibold">{label}</span>
        </Menu.Item>
      ))}
    </ScrollArea>
  </>
);

export default PreferredLlmMenu;
