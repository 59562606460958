export const ROUTES = {
  // Public pages
  HOME: "/",
  FAQ_PUBLIC: "/help/faq",
  DPA_PUBLIC: "/help/dpa",
  TERMS_PUBLIC: "/help/tos",
  PRIVACY_PUBLIC: "/help/privacy",
  SUPPORT_PUBLIC: "/help/support",
  HOWTO_WORD_PLUGIN_PUBLIC: "/help/word-plugin",

  // Help pages
  FAQ: "/app/help/faq",
  SUPPORT: "/app/help/support",
  DPA: "/app/help/dpa",
  TERMS: "/app/help/tos",
  PRIVACY: "/app/help/privacy",
  HOWTO_WORD_PLUGIN: "/app/help/word-plugin",

  // Auth pages
  SIGN_IN: "/app/sign-in",
  SIGN_UP: "/app/sign-up",
  RESET_PASSWORD: "/app/reset-password",
  CONFIRM_EMAIL: "/app/confirm-email",
  NOT_AUTHORIZED: "/app/not-authorized",

  // App pages
  APP: "/app",
  VIEWER: "/app/viewer",
  DOCUMENTS: "/app/documents",
  SETTINGS: "/app/settings",
  RESEARCH: "/app/research",
  DILIGENCE: "/app/diligence",
  LEGAL_HELP: "/app/legal-help",

  // Callbacks
  GOOGLE: "auth/google/callback",
  SHARE_POINT_CALLBACK: "/app/share_point_callback",
  ONE_DRIVE_CALLBACK: "/app/one_drive_callback",
  DROP_BOX_CALLBACK: "/app/drop_box_callback",
  BOX_COM_CALLBACK: "/app/box_com_callback",
};
