export function sanitizeStringForSearch(str: string): string {
  let searchString = getBestCandidateSearchLine(str);
  // Replace all non-ASCII characters with an asterisk (wildcard) (https://stackoverflow.com/a/20856346)
  // This should help with ligatures etc.
  // eslint-disable-next-line no-control-regex
  searchString = searchString.replace(/[^\x00-\x7F]/g, "*");
  return searchString.trim();
}

function getBestCandidateSearchLine(text: string): string {
  const lines = text.split(/[\r\n]+/);
  // If the first line is sufficiently long, use it as search string
  // Otherwise use the longest line out of the first 3 lines as search string
  const firstLines = lines.slice(0, 15);
  if (firstLines[0].length > 50) {
    return firstLines[0];
  }
  return firstLines.reduce(function (a, b) {
    return a.length > b.length ? a : b;
  });
}

export function splitStringByWhitespaces(str: string): string[] {
  return str.split(/[\s\t\n\r]+/);
}

function escapeStringForRegex(str: string): string {
  // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function makeStringWhitespacesOptionalForRegex(str: string): string {
  return str.replace(/[\s\t\n\r]+/g, "\\s*");
}

function createSearchRegexFromString(str: string,): RegExp {
  const escapedString = escapeStringForRegex(str);
  const optionalWhitespacesString = makeStringWhitespacesOptionalForRegex(escapedString);
  return new RegExp(optionalWhitespacesString, "gi");
}

export function getMiddleIndex(minIndex: number, maxIndex: number): number {
  return Math.floor((minIndex + maxIndex) / 2);
}

export function prepareSearchFragmentRegex(
  searchWords: string[],
  cutoffIndex: number,
): RegExp {
  const searchFragment = searchWords.slice(0, cutoffIndex).join(" ");
  return createSearchRegexFromString(searchFragment);
}
