import React from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { Accordion, Button } from "@mantine/core";
import { ReviewCollectionSuggestion } from "@common/types";

interface SuggestionCardProps {
  suggestion: ReviewCollectionSuggestion;
  onAccept: (suggestion: ReviewCollectionSuggestion) => void;
  onDecline: (suggestion: ReviewCollectionSuggestion) => void;
}

export const SuggestionCard: React.FC<SuggestionCardProps> = ({
  suggestion,
  onAccept,
  onDecline,
}) => {
  return (
    <Accordion.Item
      key={suggestion.id}
      value={suggestion.id.toString()}
      className="relative bg-white !border-indigo-500 rounded-2xl mb-1.5"
    >
      <Accordion.Control className="hover:!bg-violet-100 rounded-2xl">
        <div>
          {suggestion.is_accepted ? (
            <sup className="bg-green-600 text-white font-semibold p-0.5">ACCEPTED</sup>
          ) : suggestion.is_ignored ? (
            <sup className="bg-red-600 text-white font-semibold p-0.5">DECLINED</sup>
          ) : null}
        </div>
        <div className="font-semibold text-red-800 pb-2">{suggestion.criteria_name}</div>
        <span className="text-base">{suggestion.comment}</span>
      </Accordion.Control>
      <Accordion.Panel>
        <ReactDiffViewer
          oldValue={suggestion.search_text}
          newValue={suggestion.replace_text}
          splitView={false}
          compareMethod={DiffMethod.WORDS}
          hideLineNumbers={true}
          styles={{
            marker: { display: "none" },
            wordDiff: {
              wordBreak: "break-word",
              fontFamily: "Commissioner",
            },
            titleBlock: { fontFamily: "Commissioner" },
          }}
        />
        <div className="grid grid-cols-[auto_1fr_auto] mt-4">
          <Button color="#8b5cf6" size="sm" onClick={() => onAccept(suggestion)}>
            Accept
          </Button>
          <div />
          <Button
            color="#8b5cf6"
            size="sm"
            variant="transparent"
            onClick={() => onDecline(suggestion)}
          >
            Decline
          </Button>
        </div>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
