import React from "react";
import { ActionIcon, Textarea } from "@mantine/core";
import { IconThumbDown, IconThumbUp } from "@tabler/icons-react";
import { useAddFeedbackMutation } from "@/redux/api";
import { Controller, useForm } from "react-hook-form";
import { useAppSelector } from "@/redux/hooks.ts";
import { getSelectedDocument } from "@/redux/slices";

export const FeedbackMessage: React.FC = () => {
  const [addFeedback, { isLoading }] = useAddFeedbackMutation();

  const { control, getValues, reset } = useForm<{
    feedback: string;
  }>();

  const selectedDocument = useAppSelector(getSelectedDocument);

  const handleSendFeedback = async (
    reaction: "LIKE" | "DISLIKE" | "NO_REACTION" = "NO_REACTION"
  ) => {
    if (selectedDocument) {
      await addFeedback({
        document_id: selectedDocument.id,
        feedback: getValues("feedback"),
        reaction,
      });

      reset({ feedback: "" });
    }
  };

  return (
    <div className="grid grid-cols-[1fr_auto] gap-2 p-2 bg-white/50 rounded-xl">
      <Controller
        name="feedback"
        control={control}
        render={({ field }) => (
          <Textarea
            {...field}
            radius="md"
            placeholder="Please provide detailed feedback here about your experience with this tool to help us improve."
            disabled={isLoading}
            autosize
            minRows={1}
            maxRows={3}
            className="[&_textarea:focus]:bg-white [&_textarea]:bg-violet-200 [&_textarea]:border-violet-200 [&_textarea::placeholder]:text-slate-600"
          />
        )}
      />
      <div className="flex flex-row gap-2 justify-self-center self-center">
        <ActionIcon variant="transparent" onClick={() => handleSendFeedback("LIKE")}>
          <IconThumbUp color="white" stroke={1.5} />
        </ActionIcon>
        <ActionIcon variant="transparent" onClick={() => handleSendFeedback("DISLIKE")}>
          <IconThumbDown color="white" stroke={1.5} />
        </ActionIcon>
      </div>
    </div>
  );
};
