import React from "react";
import { saveAs } from "file-saver";
import { Tabs } from "@mantine/core";
import { useGetAppSettingsQuery } from "@/redux/api";
import { Code, ListItem, DownloadButton, List } from "./components";

const URLS = {
  manifest: `${import.meta.env.VITE_API_URL}word_plugin/manifest.xml`,
  prerequisites: `${import.meta.env.VITE_API_URL}word_plugin/scripts/install_prerequisite.cmd`,
  install: `${import.meta.env.VITE_API_URL}word_plugin/scripts/install_Aracor_plugin.cmd`,
};
import NotFound from "@/components/NotFound";

const WordPlugin: React.FC = () => {
  const { data: appSettings } = useGetAppSettingsQuery(undefined);

  const handleDownload = async (url: string, fileName: string) => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/xml",
      },
    });

    if (response.ok) {
      saveAs(await response.blob(), fileName);
    } else {
      console.error("File download failed.");
    }
  };

  if (!appSettings?.FEATURE_MS_WORD_PLUGIN_ENABLED) {
    return <NotFound />;
  }

  return (
    <div className="flex flex-col max-h-full w-full items-center mt-10 overflow-y-auto">
      <div className="text-2xl font-bold mb-4">
        Before starting the installation, download the manifest file to your computer.
      </div>
      <DownloadButton onClick={() => handleDownload(URLS.manifest, "manifest.xml")}>
        Download Word plugin
      </DownloadButton>
      <div className="mx-auto p-4 min-w-full">
        <Tabs defaultValue="web">
          <Tabs.List>
            <Tabs.Tab value="web">Web</Tabs.Tab>
            <Tabs.Tab value="macos">MacOS</Tabs.Tab>
            <Tabs.Tab value="windows">Windows (devs only)</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="web">
            <h1 className="text-2xl font-bold my-4">
              How to add Word Plugin in Office on Web version
            </h1>
            <List>
              <ListItem>
                Open Office on the Web. Open a document in <strong>Word</strong>.
              </ListItem>
              <ListItem>
                Select <strong>Home</strong> &gt; <strong>Add-ins</strong>, then select{" "}
                <strong>More Add-ins</strong>.
                <div
                  className={`bg-[url("/images/word_plugin/wp1.png")] bg-center bg-contain bg-no-repeat h-[400px] rounded-xl my-4`}
                />
              </ListItem>
              <ListItem>
                On the <strong>Office Add-ins</strong> dialog, select the{" "}
                <strong>MY ADD-INS</strong> tab, and click <strong>Upload My Add-in</strong>.
                <div
                  className={`bg-[url("/images/word_plugin/wp2.png")] bg-center bg-contain bg-no-repeat h-[400px] rounded-xl my-4`}
                />
              </ListItem>
              <ListItem>
                In this modal<strong> Browse</strong> to the add-in manifest file that you
                downloaded, and then select <strong>Upload</strong>.
                <div
                  className={`bg-[url("/images/word_plugin/wp3.png")] bg-center bg-contain bg-no-repeat h-[400px] rounded-xl my-4`}
                />
              </ListItem>
              <ListItem>
                Once the manifest file is successfully uploaded, now click on{" "}
                <strong>More Options</strong> and you will be able to see Word Plugin installed.
                Select add-in from the menu.
                <div
                  className={`bg-[url("/images/word_plugin/wp4.png")] bg-center bg-contain bg-no-repeat h-[400px] rounded-xl my-4`}
                />
              </ListItem>
              <ListItem>
                Verify that Word Plugin is displayed in <strong>Word</strong>. You can log in with
                your credentials and start using the Word Plugin.
                <div
                  className={`bg-[url("/images/word_plugin/wp5.png")] bg-center bg-contain bg-no-repeat h-[400px] rounded-xl my-4`}
                />
              </ListItem>
            </List>
          </Tabs.Panel>

          <Tabs.Panel value="macos">
            <h1 className="text-2xl font-bold my-4">
              How to add Word Plugin in Office on Desktop version (for MacOS)
            </h1>
            <List>
              <ListItem>
                Use <strong>Finder</strong> to sideload the manifest file. Open{" "}
                <strong>Finder</strong>
                and then enter <strong>Command+Shift+G</strong> to open the Go to folder dialog.
              </ListItem>
              <ListItem>
                Enter the following <strong>filepath</strong>:{" "}
                <Code>
                  /Users/&lt;username&gt;/Library/Containers/com.microsoft.Word/Data/Documents
                </Code>
                <div
                  className={`bg-[url("/images/word_plugin/wp6.png")] bg-center bg-contain bg-no-repeat h-[400px] rounded-xl my-4 bg-clip-content`}
                />
              </ListItem>
              <p>
                The folder <strong>Documents</strong>
                should be opened.
              </p>
              <ListItem>
                Inside the <strong>Documents</strong> folder, you should see <strong>wef</strong>
                folder. If the <strong>wef</strong> folder doesn&#x27;t exist on your computer,
                create it inside the <strong>Documents</strong> folder and open it.
              </ListItem>
              <ListItem>
                Copy the provided manifest file to this <strong>wef</strong> folder.{" "}
                <div
                  className={`bg-[url("/images/word_plugin/wp7.png")] bg-center bg-contain bg-no-repeat h-[400px] rounded-xl my-4`}
                />
              </ListItem>
              <ListItem>
                Open <strong>Word</strong> (or restart <strong>Word</strong> if it&#x27;s already
                running), then open a document.
              </ListItem>
              <ListItem>
                Select <strong>Home</strong> &gt; <strong>Add-ins</strong>, and then select add-in
                from the menu.
                <div
                  className={`bg-[url("/images/word_plugin/wp8.png")] bg-center bg-contain bg-no-repeat h-[400px] rounded-xl my-4`}
                />
              </ListItem>
              <ListItem>
                Verify that Word Plugin is displayed in <strong>Word</strong>. You can log in with
                your credentials and start using the Word Plugin.
                <div
                  className={`bg-[url("/images/word_plugin/wp9.png")] bg-center bg-contain bg-no-repeat h-[400px] rounded-xl my-4`}
                />
              </ListItem>
            </List>
          </Tabs.Panel>

          <Tabs.Panel value="windows">
            <h1 className="text-2xl font-bold my-4">
              How to add Word Plugin in Office on Desktop version (for Windows)
            </h1>
            <div className="text-l">
              Currently, there is no easy option to install the Aracor Word Plugin on Windows using
              the <strong>manifest.xml</strong> file. It requires additional software (Node.js) to
              be installed in your system.
            </div>
            <h2 className="text-xl font-bold mt-4">Prerequisites</h2>
            <List>
              <ListItem>
                Download <Code>install_prerequisite.cmd</Code>
              </ListItem>
              <DownloadButton
                onClick={() => handleDownload(URLS.prerequisites, "install_prerequisite.cmd")}
              >
                Download Prerequisite checker
              </DownloadButton>
              <ListItem>
                Double-click the installed file. This will check if your system is ready. If not,
                then it will install all necessary dependencies.
              </ListItem>
            </List>
            <h2 className="text-xl font-bold">Installation</h2>
            <List>
              <ListItem>
                Download <Code>install_Aracor_plugin.cmd</Code>
              </ListItem>
              <DownloadButton
                onClick={() => handleDownload(URLS.install, "install_Aracor_plugin.cmd")}
              >
                Download Word plugin installer
              </DownloadButton>
              <ListItem>Double-click the installed file. This will install Word Plugin.</ListItem>
              <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded-md mt-4">
                <strong>IMPORTANT:</strong> <Code>install_Aracor_plugin.cmd</Code> should be in the
                same folder as <Code>manifest.xml</Code>
              </div>
              <div
                className={`bg-[url("/static/images/word_plugin/wp10.png")] bg-center bg-contain bg-no-repeat h-[200px] rounded-xl my-4`}
              />
            </List>
          </Tabs.Panel>
        </Tabs>
      </div>
    </div>
  );
};

export default WordPlugin;
