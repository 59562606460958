import React from "react";
import { Breadcrumbs, Anchor } from "@mantine/core";

interface FolderBreadcrumbProps {
  path: string[];
  onBreadcrumbItemClick: (index: number) => void;
}

export const FolderBreadcrumb: React.FC<FolderBreadcrumbProps> = ({
  path,
  onBreadcrumbItemClick,
}) => {
  const breadcrumbItems = path?.map((folder, index) => (
    <Anchor key={index} onClick={() => onBreadcrumbItemClick(index)}>
      {folder}
    </Anchor>
  ));

  return (
    <Breadcrumbs separator="→" m="sm">
      <Anchor onClick={() => onBreadcrumbItemClick(-1)}>My files</Anchor>
      {breadcrumbItems}
    </Breadcrumbs>
  );
};
