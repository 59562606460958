import React from "react";
import { IconBolt, IconCircleSquare, IconMoodSmile } from "@tabler/icons-react";
import { TextBlock } from "@/components/Home/components/common";

export const WhatWeDo: React.FC = React.memo(() => {
  return (
    <div className="flex flex-col items-center">
      <TextBlock
        title="What we do"
        subtitle="Digital Legal Innovation: Empowering Entrepreneurs and SMBs"
        text="Aracor is a legal technology company that simplifies contract review
          and negotiation. Our founders and advisors have led major law firms
          and global in-house teams. We have built tech unicorns and successful
          venture funds from scratch. We know how to help businesses and what it
          takes to get from zero to one."
      />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1280px] px-4 lg:px-8 xl:px-1 z-10">
        <div className="flex flex-col basis-1/3 gap-4 arc-info-card">
          <div className="flex justify-center rounded-[50%] text-white p-2 w-16 bg-gradient-to-br from-[#F72161]/100 to-[#F72161]/80">
            <IconBolt />
          </div>
          <span className="font-semibold text-2xl dark:text-slate-100 min-h-16">
            Automated contract review with RIO
          </span>
          <p className="lg:text-lg xl:text-xl !leading-8 my-0 text-gray-600 dark:text-slate-400">
            Select a template from our growing startup library or upload your
            own contract to the app or by email. RIO reviews, edits, and
            suggests changes within seconds.
          </p>
        </div>
        <div className="flex flex-col basis-1/3 gap-4 arc-info-card">
          <div className="flex justify-center rounded-[50%] text-white p-2 w-16 bg-gradient-to-br from-[#A230FC]/100 to-[#A230FC]/80">
            <IconCircleSquare />
          </div>
          <span className="font-semibold text-2xl dark:text-gray-100 min-h-16">
            Solutions for SMBs and legal teams
          </span>
          <p className="lg:text-lg xl:text-xl !leading-8 my-0 text-gray-600 dark:text-slate-400">
            With turnkey and bespoke solutions, RIO can customise contract
            drafting and review software solutions for law firms and
            professional teams. Review documents according to specific styles
            and objectives.
          </p>
        </div>
        <div className="flex flex-col basis-1/3 gap-4 arc-info-card">
          <div className="flex justify-center rounded-[50%] text-white p-2 w-16 bg-gradient-to-br from-[#5FC456]/100 to-[#5FC456]/80">
            <IconMoodSmile />
          </div>
          <span className="font-semibold text-2xl dark:text-gray-100 min-h-16">
            Building a better law practice
          </span>
          <p className="lg:text-lg xl:text-xl !leading-8 my-0 text-gray-600 dark:text-slate-400">
            To further our mission we are also building a separate law firm —
            Aracor Law, the next frontier in legal support. Currently under
            construction. Our digital law firm is coming soon, designed to
            provide “last mile” legal advice and more to entrepreneurs and SMBs.
          </p>
        </div>
      </div>
    </div>
  );
});

export default WhatWeDo;
