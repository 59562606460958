import React from "react";
import { ActionIcon } from "@mantine/core";
import { IconChevronsRight } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { getIsToolbarOpened, toggleNavbar } from "@/redux/slices";

interface NavbarSwitcherProps {
  visible: boolean;
}

const NavbarSwitcher: React.FC<NavbarSwitcherProps> = ({ visible }) => {
  const dispatch = useDispatch();

  const isNavbarOpened = useSelector(getIsToolbarOpened);

  const handleToggleNavbar = () => {
    dispatch(toggleNavbar());
  };

  return (
    <div className={`${visible ? "block" : "hidden"}`}>
      <ActionIcon variant="transparent" onClick={handleToggleNavbar}>
        <IconChevronsRight
          color="#8b5cf6"
          className={
            isNavbarOpened ? "rotate-180 ease-in duration-150" : "rotate-0 ease-in duration-150"
          }
        />
      </ActionIcon>
    </div>
  );
};

export default NavbarSwitcher;
