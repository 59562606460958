export const Faq = () => {
  return (
    <div className="flex flex-row justify-center items-start p-10 pt-20">
      <div className="flex flex-col gap-4 w-2/3">
        <h1 className="text-4xl">Frequently Asked Questions</h1>

        <section>
          <h2 className="text-3xl my-4">General Questions</h2>
          <ul className="flex flex-col gap-4 list-decimal ml-4">
            <li>
              <span className="text-2xl">
                What is&nbsp;
                <a href="https://aracor.ai" className="text-ar-link">
                  Aracor.ai
                </a>
                ?
              </span>
              <p className="text-lg py-4">
                Aracor.ai is a legal tech platform designed to streamline document review and
                analysis. Our tools help you interact with your documents, switch between advanced
                language models (LLMs), and receive professional-grade feedback with our&nbsp;
                <strong>Redline tool</strong>&nbsp; to ensure your documents are accurate and
                effective.
              </p>
            </li>
            <li>
              <span className="text-2xl">
                Who can use&nbsp;
                <a href="https://aracor.ai" className="text-ar-link">
                  Aracor.ai
                </a>
              </span>
              <p className="text-lg py-4">
                Our platform is designed for legal professionals, corporate teams, and anyone who
                needs to review and improve documents efficiently.
              </p>
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-3xl my-4">Web App FAQs</h2>
          <ul className="flex flex-col gap-4 list-decimal ml-4">
            <li>
              <span className="text-2xl">How do I upload and interact with my documents?</span>
              <p className="text-lg py-4">
                <ul className="list-disc ml-4">
                  <li>Log in to your account.</li>
                  <li>Click on the “Upload” button to add your document(s).</li>
                  <li>
                    Start a chat with the document by entering your queries in the chat window.
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <span className="text-2xl">What types of documents can I upload?</span>
              <p className="text-lg py-4">
                We support most common document formats, including&nbsp;<strong>.docx</strong>
                &nbsp;and&nbsp;
                <strong>.pdf</strong>.
              </p>
            </li>
            <li>
              <span className="text-2xl">Can I upload multiple documents at once?</span>
              <p className="text-lg py-4">
                Yes, you can upload multiple documents, and our system will let you interact with
                them collectively or individually.
              </p>
            </li>
            <li>
              <span className="text-2xl">How do I switch between LLMs?</span>
              <p className="text-lg py-4">
                <ul className="list-disc ml-4">
                  <li>Navigate to the settings panel in the chat window.</li>
                  <li>Select your preferred LLM from the list of available options.</li>
                </ul>
                <p className="pt-4">
                  This allows you to tailor the responses to your specific needs.
                </p>
              </p>
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-3xl my-4">Word Plugin FAQs</h2>
          <ul className="flex flex-col gap-4 list-decimal ml-4">
            <li>
              <span className="text-2xl">How do I install the Word plugin?</span>
              <p className="text-lg py-4">
                <ul className="list-disc ml-4">
                  <li>
                    Open Microsoft Word and go to <strong>Insert</strong> &rsaquo;&nbsp;
                    <strong>Add-ins</strong>&nbsp;&rsaquo;&nbsp;<strong>Get Add-ins</strong>.
                  </li>
                  <li>
                    Search for “
                    <a href="https://aracor.ai" className="text-ar-link">
                      Aracor.ai
                    </a>
                    ” and click <strong>Add</strong>.
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <span className="text-2xl">What can I do with the Word plugin?</span>
              <p className="text-lg py-4">
                <p className="pb-4">
                  The Word plugin brings the power of&nbsp;
                  <a href="https://aracor.ai" className="text-ar-link">
                    Aracor.ai
                  </a>
                  &nbsp; directly to your Word documents. You can:
                </p>
                <ul className="list-disc ml-4">
                  <li>
                    Analyze your document with the <strong>Redline tool</strong>.
                  </li>
                  <li>Receive suggestions on improvements directly in Word.</li>
                  <li>Chat with the document for clarity or deeper insights.</li>
                </ul>
              </p>
            </li>
            <li>
              <span className="text-2xl">
                Is the Word plugin compatible with all versions of Word?
              </span>
              <p className="text-lg py-4">
                The plugin is compatible with Microsoft Word 2016 and later (including Office 365).
              </p>
            </li>
            <li>
              <span className="text-2xl">How do I use the Redline tool in Word?</span>
              <p className="text-lg py-4">
                <ul className="list-disc ml-4">
                  <li>Open your document in Word.</li>
                  <li>
                    Click on the&nbsp;
                    <a href="https://aracor.ai" className="text-ar-link">
                      Aracor.ai
                    </a>
                    &nbsp;<strong>Add-in</strong> from the toolbar.
                  </li>
                  <li>
                    Select the &nbsp;<strong>Redline tool</strong>&nbsp;to review and improve your
                    document.
                  </li>
                </ul>
              </p>
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-3xl my-4">Redline Tool FAQs</h2>
          <ul className="flex flex-col gap-4 list-decimal ml-4">
            <li>
              <span className="text-2xl">What is the Redline tool?</span>
              <p className="text-lg py-4">
                <p className="pb-4">
                  The Redline tool provides in-depth feedback on your document. It highlights:
                </p>
                <ul className="list-disc ml-4">
                  <li>
                    <strong>Strengths:</strong>&nbsp; What works well in your document.
                  </li>
                  <li>
                    <strong>Weaknesses:</strong>&nbsp; Areas that need improvement.
                  </li>
                  <li>
                    <strong>Suggestions:</strong>&nbsp; Specific recommendations to refine your
                    content.
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <span className="text-2xl">
                What kind of suggestions does the Redline tool offer?
              </span>
              <p className="text-lg py-4">
                <span>The tool offers suggestions on:</span>
                <ul className="list-disc ml-6">
                  <li>Legal terminology.</li>
                  <li>Clarity and tone.</li>
                  <li>Grammar and formatting.</li>
                </ul>
              </p>
            </li>
            <li>
              <span className="text-2xl">Can I apply the suggested changes automatically?</span>
              <p className="text-lg py-4">
                At this time, changes need to be applied manually. We are working on features to
                streamline this process.
              </p>
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-3xl my-4">Support and Feedback</h2>
          <ul className="flex flex-col gap-4 list-decimal ml-4">
            <li>
              <span className="text-2xl">What should I do if I encounter an issue?</span>
              <p className="text-lg py-4">
                Please email us at&nbsp;
                <a href="mailto:support@aracor.ai" className="text-ar-link">
                  support@aracor.ai
                </a>
                &nbsp; with a description of the issue, including screenshots if possible.
              </p>
            </li>
            <li>
              <span className="text-2xl">How can I provide feedback?</span>
              <p className="text-lg py-4">
                We value your input! Feel free to email us at&nbsp;
                <a href="mailto:support@aracor.ai" className="text-ar-link">
                  support@aracor.ai
                </a>
                &nbsp;with your suggestions or feature requests.
              </p>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};
