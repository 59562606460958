import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSelectedDocument } from "@/redux/slices/documents.ts";
import {
  useLazyCheckIsSummaryCollectionPreparedQuery,
  usePrepareSummaryCollectionMutation,
  useLazyGetCollectionSummaryQuery,
} from "@/redux/api";
import DueDiligenceContent from "./components/DueDiligenceTabContent";
import { DEFAULT_ERROR_MESSAGE } from "../common/constants";

const Summary: React.FC = () => {
  const selectedDocument = useSelector(getSelectedDocument);
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [checkIsSummaryCollectionPrepared] = useLazyCheckIsSummaryCollectionPreparedQuery();
  const [prepareSummaryCollection] = usePrepareSummaryCollectionMutation();
  const [getCollectionSummary, { currentData: collectionSummary }] =
    useLazyGetCollectionSummaryQuery();

  const { collection_id: collectionId } = selectedDocument ?? {};

  const getCollectionSummaryData = async (collectionId: string | undefined) => {
    if (collectionId) {
      setIsLoading(true);
      try {
        const isSummaryCollectionPrepared = await checkIsSummaryCollectionPrepared({ collectionId }).unwrap();
        if (!isSummaryCollectionPrepared) {
          await prepareSummaryCollection({ collectionId }).unwrap();
        }
        await getCollectionSummary({ collectionId }).unwrap();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        setErrorText(e.data.data.detail[0] || DEFAULT_ERROR_MESSAGE);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (collectionId) {
      getCollectionSummaryData(collectionId);
    }
  }, [collectionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DueDiligenceContent
      isLoading={isLoading}
      errorText={errorText}
      documentSummaries={collectionSummary}
      setErrorText={setErrorText}
      handleRetry={() => getCollectionSummaryData(collectionId)}
    />
  );
};

export default Summary;
