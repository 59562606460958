import { IQaChatReference } from "@/common/types";
import { createSlice } from "@reduxjs/toolkit";

interface QaState {
  questionText: string | null;
  references: IQaChatReference[];
  highlightedReference: IQaChatReference | null;
}

const initialState: QaState = {
  questionText: null,
  references: [],
  highlightedReference: null,
};

export const qaSlice = createSlice({
  name: "qa",
  initialState,
  reducers: {
    resetQa: () => initialState,
    setQuestionText: (state, { payload }) => {
      state.questionText = payload;
    },
    setReferences: (state, { payload }) => {
      state.references = payload;
    },
    setReferencesForMessage: (state, { payload }) => {
      const messageId = payload.messageId;
      if (messageId) {
        const references = payload.references;
        // Remove previous references for the message, add new ones
        state.references = state.references.filter(
          (reference) => reference.message_id !== messageId
        );
        state.references = state.references.concat(references);
      }
    },
    setHighlightedReference: (state, { payload }) => {
      state.highlightedReference = payload;
    },
  },
  selectors: {
    getQuestionText: ({ questionText }) => questionText,
    getReferences: ({ references }) => references,
    getHighlightedReference: ({ highlightedReference }) => highlightedReference,
  },
});

export const {
  setQuestionText,
  setReferences,
  setReferencesForMessage,
  setHighlightedReference,
  resetQa,
} = qaSlice.actions;

export const { getQuestionText, getReferences, getHighlightedReference } = qaSlice.selectors;

export default qaSlice.reducer;
