export type TDocumentContractType =
  | "Non-Disclosure Agreement"
  | "Employment Agreement"
  | "Non-Compete Agreement"
  | "Franchise Agreement"
  | "Other";

export interface TDocument {
  id: string;
  parent_folder_id: string | null;
  collection_id: string;
  document_name: string;
  document_original_extension: string;
  document_display_extension: string;
  document_redacted_extension: string | null;
  document_text_content_extension: string | null;
  original_url: string;
  display_url: string;
  redacted_url: string | null;
  text_content_url: string | null;
  document_size_in_bytes: number;
  contract_type: TDocumentContractType | null;
  is_scanned_pdf: boolean;
  created_at: string;
  updated_at: string;
}

export enum EStorageFileType {
  ORIGINAL = "original",
  DISPLAY = "display",
  REDACTED = "redacted",
  TEXT_CONTENT = "text_content",
}
