import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { ActionIcon, Kbd, Tooltip, HoverCard, Stack, Group, Text, Divider } from "@mantine/core";
import { EFontSize, FileTreeItem, IQaChatReference } from "@common/types";
import { useAppDispatch } from "@/redux/hooks.ts";
import { getReferences, setHighlightedReference } from "@/redux/slices/qa.ts";
import { IconClick, IconFileText, IconQuote, IconRefresh } from "@tabler/icons-react";
import { useLazyGetChatMessageReferencesQuery } from "@/redux/api";
import { useSelector } from "react-redux";
import { getIsRedactView, getSelectedDocument } from "@/redux/slices";
import { findDocument } from "@/redux/thunk";
import { LocalStorageService } from "@/services";
import remarkGfm from 'remark-gfm'

interface ChatBotMessageProps {
  references?: IQaChatReference[] | null;
  stream?: boolean;
  fontSize?: EFontSize;
  id?: number;
}

const ChatBotMessage: React.FC<React.PropsWithChildren<ChatBotMessageProps>> = ({
  children,
  references,
  stream,
  fontSize = EFontSize.base,
  id,
}) => {
  const appDispatch = useAppDispatch();

  const selectedDocument = useSelector(getSelectedDocument);

  const isRedactView = useSelector(getIsRedactView);

  const currentCollectionReferences = useSelector(getReferences);

  const [getChatMessageReferences, { isFetching: isGetChatMessageReferencesFetching }] =
    useLazyGetChatMessageReferencesQuery();

  const handleRegenerateReferencesClick = () => {
    if (id) {
      getChatMessageReferences({
        message_id: id,
        collection_id: selectedDocument?.collection_id,
        refresh: true,
        decrypt_references: !isRedactView,
      });
    }
  };

  const accentColor = LocalStorageService.isOldUI() ? "MediumSlateBlue" : "ar-accent";

  const streamingClassNames = stream
    ? "[overflow-wrap:anywhere] first:after:content-['❚'] first:after:animate-pulse first:after:text-white"
    : "[overflow-wrap:anywhere]";

  const formatAnswerAndReferences = (
    answer: string,
    references: IQaChatReference[] | null | undefined
  ) => {
    try {
      if (!Array.isArray(references) || !references.length) {
        return answer;
      }
      // Need to sort references in ascending order to make it easier to number them in ascending
      // order and to more easily inject them into the answer message later
      const sortedReferences = getSortedReferences(references);
      // Create a map which maps reference locations to reference number and text object list
      const locationReferenceMap = createLocationReferenceMap(sortedReferences);
      // Format and inject custom references into the answer message in order of location index
      return injectReferencesIntoAnswer(answer, locationReferenceMap);
    } catch (error) {
      console.error("Error formatting answer and references:");
      console.error(error);
      return answer;
    }
  };

  const getSortedReferences = (references: IQaChatReference[]) => {
    // Sort each reference location array in ascending order
    // Need to re-create each reference object because the originals are read only
    for (let reference of references) {
      reference = {
        ...reference,
        location_indices_in_message: [...reference.location_indices_in_message].sort(
          (a, b) => a - b
        ),
      };
    }

    // Sort references by first location index
    // Need to re-create the array because the original is read only
    references = [...references].sort(
      (a, b) => a.location_indices_in_message[0] - b.location_indices_in_message[0]
    );
    return references;
  };

  const createLocationReferenceMap = (
    references: IQaChatReference[]
  ): Map<number, { referenceNumber: number; referenceId: number }[]> => {
    const locationReferenceMap = new Map();
    let currentReferenceNumber = 1;
    for (const reference of references) {
      for (const referenceLocation of reference.location_indices_in_message) {
        if (locationReferenceMap.has(referenceLocation)) {
          locationReferenceMap.get(referenceLocation).push({
            referenceNumber: currentReferenceNumber,
            referenceId: reference.id,
          });
        } else {
          locationReferenceMap.set(referenceLocation, [
            {
              referenceNumber: currentReferenceNumber,
              referenceId: reference.id,
            },
          ]);
        }
      }
      currentReferenceNumber += 1;
    }
    // Return the map sorted by location index
    return new Map([...locationReferenceMap.entries()].sort((a, b) => a[0] - b[0]));
  };

  const injectReferencesIntoAnswer = (
    answer: string,
    locationReferenceMap: Map<number, { referenceNumber: number; referenceId: number }[]>
  ) => {
    let currentIndexOffset = 0;
    let answerMessageWithReferences = answer;
    for (const [referenceLocation, referenceList] of locationReferenceMap) {
      for (const reference of referenceList) {
        const referenceLink = `[${reference.referenceId}](reference#${reference.referenceNumber})`;
        const referenceLocationWithOffset = referenceLocation + currentIndexOffset;
        answerMessageWithReferences =
          answerMessageWithReferences.slice(0, referenceLocationWithOffset) +
          referenceLink +
          answerMessageWithReferences.slice(referenceLocationWithOffset);
        currentIndexOffset += referenceLink.length;
      }
    }
    return answerMessageWithReferences;
  };

  const handleHighlightReference = (referenceId: number | null): void => {
    setHighlightedReference(null);
    if (!referenceId) {
      return;
    }
    const reference = currentCollectionReferences?.find((ref) => ref.id === referenceId);
    appDispatch(setHighlightedReference(reference));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const AnchorHandler = ({ href, children }: { href?: string; children?: any }) => {
    const [referencedDocument, setReferencedDocument] = useState<FileTreeItem | null>(null);
    // If the 'href' is our custom reference (in the format of `reference#<number>`),
    // we will attempt to render our custom component
    const isCustomReference = href?.startsWith("reference#");
    const referenceNumber = isCustomReference ? href?.split("#")[1] : null;
    const referenceId = isCustomReference && children ? parseInt(children) : null;
    const reference = isCustomReference
      ? currentCollectionReferences?.find((ref) => ref.id === referenceId)
      : null;

    // Fetch the referenced document if it exists
    useEffect(() => {
      if (isCustomReference && reference?.source_document_id) {
        appDispatch(
          findDocument({
            id: reference.source_document_id,
            collection_id: selectedDocument?.collection_id,
          })
        )
          .unwrap()
          .then((document) => {
            if (document) {
              setReferencedDocument(document);
            }
          })
          .catch((error) => {
            console.error("Error fetching referenced document:", error);
          });
      }
    }, [isCustomReference, reference?.source_document_id]);

    if (isCustomReference) {
      return (
        <HoverCard withArrow width={400} shadow="md" position="top" openDelay={200}>
          <HoverCard.Target>
            <Kbd
              className="cursor-pointer mx-0.5"
              onClick={() => handleHighlightReference(referenceId)}
            >
              {referenceNumber}
            </Kbd>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Stack gap="xs">
              <Group gap="xs">
                <IconFileText size={24} />
                <Text fw={500} truncate="end">
                  {referencedDocument
                    ? `${referencedDocument?.name}${
                        referencedDocument?.document_original_extension ?? ""
                      }`
                    : "Loading document..."}
                </Text>
              </Group>
              <Divider />
              <Group gap="xs">
                <IconQuote size={18} />
                <Text size="sm" fs="italic" lineClamp={8}>
                  {reference?.referenced_text || "Loading referenced text..."}
                </Text>
              </Group>
              <Divider />
              <div className="flex flex-row gap-1">
                <IconClick size={24} />
                <Text fw={300} size="sm">
                  Click the reference number <Kbd>{referenceNumber}</Kbd> to find and highlight the
                  referenced text in the document.
                </Text>
              </div>
            </Stack>
          </HoverCard.Dropdown>
        </HoverCard>
      );
    }
    // Otherwise render as a normal link which opens in a new tab
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  const fontSizeClassMap: Record<EFontSize, string> = {
    xs: "prose-xs",
    sm: "prose-sm",
    base: "prose-base",
    lg: "prose-lg",
    xl: "prose-xl",
  };

  return children ? (
    <div className="grid grid-cols-[1fr_auto] items-center bg-white rounded-md p-2 group/bot-message">
      <div
        className={`prose ${fontSizeClassMap[fontSize || "base"]} prose-strong:text-[--mantine-color-text] text-[color:var(--mantine-color-text)] pl-1 py-1`}
      >
        <ReactMarkdown
          className={streamingClassNames}
          remarkPlugins={[remarkGfm]}
          components={{
            pre: ({ children }) => <p>{children}</p>,
            code: ({ children }) => <>{children}</>,
            p: ({ children }) => <span>{children}</span>,
            a: AnchorHandler,
          }}
        >
          {formatAnswerAndReferences(children as string, references)}
        </ReactMarkdown>
      </div>
      {id ? (
        <div className="invisible group-hover/bot-message:visible self-start">
          <Tooltip label="Regenerate references" openDelay={1000}>
            <ActionIcon
              color={accentColor}
              variant="subtle"
              radius="md"
              onClick={handleRegenerateReferencesClick}
            >
              <IconRefresh className={isGetChatMessageReferencesFetching ? "animate-spin" : ""} />
            </ActionIcon>
          </Tooltip>
        </div>
      ) : null}
    </div>
  ) : stream ? (
    <div className="animate-pulse">❚</div>
  ) : null;
};

export default ChatBotMessage;
