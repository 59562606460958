import { ReactElement } from "react";
import {
  ActionIcon,
  Menu,
  TextInput,
  Tooltip as MantineTooltip,
  UnstyledButton,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconCircleChevronLeft,
  IconCircleChevronRight,
  IconDownload,
  IconSearch,
  IconX,
  IconZoomIn,
  IconZoomOut,
} from "@tabler/icons-react";

import { ToolbarProps, ToolbarSlot } from "@react-pdf-viewer/default-layout";
import { useAppSelector } from "@/redux/hooks.ts";
import {
  getSelectedDocument,
} from "@/redux/slices/documents.ts";
import { DocumentsService } from "@/services";
import { saveAs } from "file-saver";
import { EStorageFileType } from "@common/types";
import { getMode, getUIStyle } from "@/redux/slices/settings";
import clsx from "clsx/lite";

export const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const selectedDocument = useAppSelector(getSelectedDocument);
  
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const uiStyle = useAppSelector(getUIStyle);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const mode = useAppSelector(getMode);

  const handleClearClick = (clearKeyword: () => void) => {
    clearKeyword();
  };

  const handleDownloadClick = async (apply_markup?: boolean) => {
    notifications.show({
      id: "DOWNLOAD_IN_PROGRESS",
      loading: true,
      title: "Downloading",
      message: "Document download will begin soon...",
    });

    if (selectedDocument) {
      const { id } = selectedDocument?.children?.[0] ?? selectedDocument;
      const redactedExtension = selectedDocument?.document_redacted_extension;
      const getRedactedDoc = redactedExtension && !apply_markup;

      const blob = await DocumentsService.getDocumentBlob(
        id,
        getRedactedDoc ? EStorageFileType.REDACTED : EStorageFileType.ORIGINAL,
        apply_markup
      );

      if (blob) {
        saveAs(
          blob,
          `${selectedDocument.name}${
            getRedactedDoc ? redactedExtension : selectedDocument.document_original_extension
          }`
        );

        notifications.hide("DOWNLOAD_IN_PROGRESS");
      }
    }
  };

  return (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageLabel,
          NumberOfPages,
          GoToPreviousPage,
          GoToNextPage,
          Search,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;

        return (
          <div className={clsx(
            "grid grid-cols-1 grid-rows-2 w-full pr-4",
            uiStyle === 'new' && 'ml-20'
          )}>
            <div className="flex flex-row justify-center items-start">
              <MantineTooltip
                label={`${selectedDocument?.name}${
                  selectedDocument?.document_original_extension ?? ""
                }`}
              >
                <div className="justify-self-start self-center truncate w-full">
                  <span className="text-xl">
                    {selectedDocument?.name}
                    {selectedDocument?.document_original_extension}
                  </span>
                </div>
              </MantineTooltip>
            </div>
            <div className="flex flex-row gap-4 h-full w-full">
              <div className="flex-shrink flex flex-row justify-center items-center gap-1">
                <GoToPreviousPage>
                  {({ onClick }) => (
                    <ActionIcon size="sm" variant="transparent" onClick={onClick}>
                      <IconCircleChevronLeft stroke={1.5} color="black" />
                    </ActionIcon>
                  )}
                </GoToPreviousPage>
                <CurrentPageLabel />
                <span>/</span>
                <NumberOfPages />
                <GoToNextPage>
                  {({ onClick }) => (
                    <ActionIcon size="sm" variant="transparent" onClick={onClick}>
                      <IconCircleChevronRight stroke={1.5} color="black" />
                    </ActionIcon>
                  )}
                </GoToNextPage>
              </div>
              <div className="flex-shrink flex flex-row justify-center items-center gap-1">
                <ZoomOut>
                  {({ onClick }) => (
                    <ActionIcon size="sm" variant="transparent" onClick={onClick}>
                      <IconZoomOut stroke={1.5} color="black" />
                    </ActionIcon>
                  )}
                </ZoomOut>
                <div className="flex flex-row justify-center items-center">
                  Zoom
                  <Zoom />
                </div>
                <ZoomIn>
                  {({ onClick }) => (
                    <ActionIcon size="sm" variant="transparent" onClick={onClick}>
                      <IconZoomIn stroke={1.5} color="black" />
                    </ActionIcon>
                  )}
                </ZoomIn>
              </div>
              {mode === "redline" ? (
                <Menu position="bottom-start">
                  <Menu.Target>
                    <UnstyledButton
                      variant="transparent"
                      size="md"
                      className="flex flex-row justify-center items-center gap-1"
                    >
                      Download
                      <IconDownload stroke={1.5} />
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item onClick={() => handleDownloadClick()}>Download Original</Menu.Item>
                    <Menu.Item onClick={() => handleDownloadClick(true)}>
                      Download with Redline
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ) : (
                <UnstyledButton
                  variant="transparent"
                  size="md"
                  className="flex flex-row justify-center items-center gap-1"
                  onClick={() => handleDownloadClick()}
                >
                  Download
                  <IconDownload stroke={1.5} />
                </UnstyledButton>
              )}
              <div className="flex-grow flex flex-row justify-end items-center">
                <Search>
                  {(props) => (
                    <div className="flex flex-row [&_input]:!leading-5">
                      <TextInput
                        type="text"
                        size="sm"
                        radius="md"
                        placeholder="Search"
                        rightSection={
                          props.keyword ? (
                            <ActionIcon
                              variant="transparent"
                              onClick={() => handleClearClick(props.clearKeyword)}
                            >
                              <IconX size={16} color="gray" />
                            </ActionIcon>
                          ) : (
                            <IconSearch />
                          )
                        }
                        value={props.keyword}
                        onChange={(event) => {
                          props.setKeyword(event.target.value);
                        }}
                        onKeyDown={async (event) => {
                          if (event.key === "Enter") {
                            if (props.keyword) {
                              await props.search();
                            } else {
                              props.clearKeyword();
                            }
                          }
                        }}
                      />
                    </div>
                  )}
                </Search>
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
};
