import React, { useEffect } from "react";
import { SegmentedControl, TextInput, Select, Button } from "@mantine/core";
import { useGetAppSettingsQuery } from "@/redux/api";
import { notifications } from "@mantine/notifications";
import { LocalStorageService } from "@/services";
import { useGetUserDataQuery, useUpdateUserDataMutation } from "@/redux/api";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts";
import { getFontSize, getUIStyle, setFontSize, setUIStyle } from "@/redux/slices";
import { User } from "@common/types/User.ts";
import { UIStyle } from "@/common/types";

interface SettingsForm {
  fullName: string;
  fontSize: string;
  isDocxEditorEnabled: string;
  uiStyle: UIStyle;
}

export const Settings: React.FC = () => {
  const fontSize = useAppSelector(getFontSize);
  const uiStyle = useAppSelector(getUIStyle);

  const dispatch = useAppDispatch();

  const { data: appSettings } = useGetAppSettingsQuery(undefined);

  const {
    data: user,
    isLoading: isUserDataLoading,
    isSuccess: isUserDataSuccess,
  } = useGetUserDataQuery(undefined);

  const [updateUserData] = useUpdateUserDataMutation();

  const {
    control,
    clearErrors,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm<SettingsForm>({
    defaultValues: {
      fullName: "",
      isDocxEditorEnabled:
        localStorage.getItem(LocalStorageService.IS_ONLYOFFICE_ENABLED_KEY) || "false",
      fontSize,
      uiStyle,
    },
  });

  useEffect(() => {
    if (isUserDataSuccess) {
      setValue("fullName", (user as unknown as User)?.full_name || "");
    }
  }, [isUserDataSuccess, setValue, user]);

  const handleSubmitForm: SubmitHandler<SettingsForm> = (data) => {
    clearErrors();
    sendUpdateFormRequest(data);
  };

  const sendUpdateFormRequest: SubmitHandler<SettingsForm> = async ({
    fullName,
    fontSize,
    isDocxEditorEnabled,
    uiStyle,
  }) => {
    let isError = false;

    try {
      if (fullName !== user?.full_name) {
        await updateUserData({ updated_full_name: fullName });
      }

      if (fontSize) {
        dispatch(setFontSize(fontSize));
      }

      dispatch(setUIStyle(uiStyle));

      localStorage.setItem(LocalStorageService.IS_ONLYOFFICE_ENABLED_KEY, isDocxEditorEnabled);
    } catch {
      isError = true;
    } finally {
      notifications.show({
        id: "settings",
        color: isError ? "red" : "teal",
        title: isError ? "Failure" : "Success",
        message: isError ? "User settings haven't been updated" : "User settings have been updated",
        icon: (
          <span className="material-symbols-outlined text-white">
            {isError ? "cancel" : "task_alt"}
          </span>
        ),
        loading: false,
        autoClose: 2000,
        withBorder: true,
      });
    }
  };

  return (
    <div className="grid grid-rows-[auto_1fr] gap-8 h-dvh w-full p-16">
      <div className="justify-self-start">
        <span className="text-4xl">Settings</span>
      </div>

      <form onSubmit={handleSubmit(handleSubmitForm)} className="place-self-center w-full">
        <div className="grid grid-rows-5 gap-8 mb-10">
          <div className="justify-self-center w-1/3">
            <TextInput
              label="EMAIL"
              size="lg"
              defaultValue={user?.email}
              variant={isUserDataLoading ? "filled" : "unstyled"}
              className={isUserDataLoading ? "animate-pulse" : ""}
            />
          </div>
          <div className="justify-self-center w-1/3">
            <Controller
              name="fullName"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label="NAME"
                  size="lg"
                  radius="md"
                  error={errors.fullName?.type === "required" && "Full name is required"}
                  variant={isUserDataLoading ? "filled" : "default"}
                  className={isUserDataLoading ? "animate-pulse" : ""}
                />
              )}
              rules={{ required: true }}
            />
          </div>
          <div className="justify-self-center w-1/3">
            <Select
              size="lg"
              radius="md"
              label="FONT SIZE"
              placeholder="Pick value"
              data={[
                { value: "sm", label: "Small" },
                { value: "base", label: "Normal" },
                { value: "lg", label: "Large" },
                { value: "xl", label: "Extra Large" },
              ]}
              defaultValue={getValues("fontSize")}
              onChange={(fontSize: string | null) => setValue("fontSize", fontSize || "base")}
            />
          </div>
          {appSettings?.FEATURE_ENABLE_NEW_UI && (
            <div className="justify-self-center w-1/3">
              <div className="flex flex-col w-full">
                <span className="text-lg">UI Style</span>
                <SegmentedControl
                  data={[
                    { value: "old", label: "Old" },
                    { value: "new", label: "New" },
                  ]}
                  fullWidth
                  size="lg"
                  radius="md"
                  defaultValue={getValues("uiStyle")}
                  onChange={(value) => setValue("uiStyle", value as UIStyle)}
                />
              </div>
            </div>
          )}
          {appSettings?.FEATURE_ONLYOFFICE_ENABLED && (
            <div className="justify-self-center w-1/3">
              <div className="flex flex-col w-full">
                <span className="text-lg">DOCX EDITOR</span>
                <SegmentedControl
                  data={[
                    { value: "false", label: "Disabled" },
                    { value: "true", label: "Enabled" },
                  ]}
                  fullWidth
                  size="lg"
                  radius="md"
                  defaultValue={getValues("isDocxEditorEnabled")}
                  onChange={(value) => setValue("isDocxEditorEnabled", value)}
                />
              </div>
            </div>
          )}
          <div className="w-1/3 place-self-center">
            <Button type="submit" variant="accent" fullWidth>
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
