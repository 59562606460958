import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Alert, Button, PasswordInput, Text } from "@mantine/core";
import { useLazyResetPasswordQuery } from "@/redux/api";
import { IconAlertOctagon } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { ROUTES } from "@/constants";
import { getErrorMessage } from "@/utils";

interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
  details: string;
}

export const ResetPasswordForm: React.FC = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const [resetPassword, { isFetching }] = useLazyResetPasswordQuery();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
  } = useForm<ResetPasswordForm>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const handleNewPasswordSubmit = async ({ password, confirmPassword }: ResetPasswordForm) => {
    const token = searchParams.get("token");

    if (!token) {
      setError("details", {
        type: "manual",
        message: "The password reset link you used is invalid or has expired",
      });

      return;
    }

    if (password !== confirmPassword) {
      setError("details", {
        type: "manual",
        message: "Passwords do not match",
      });

      return;
    }

    const { isSuccess, error } = await resetPassword({ token, password });

    if (isSuccess) {
      notifications.show({
        title: "Password Reset Successful.",
        message:
          "Your password was updated successfully. You may now sign in with the new password.",
        color: "green",
      });

      navigate(ROUTES.SIGN_IN);
    }

    if (error) {
      setError("details", {
        type: "manual",
        message: getErrorMessage(error) || "Unknown error",
      });
    }
  };

  return (
    <form
      onSubmit={(event) => {
        clearErrors();
        handleSubmit(handleNewPasswordSubmit)(event);
      }}
    >
      <div className="flex flex-col justify-center items-center gap-8 w-72">
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              label="New Password"
              radius="md"
              size="lg"
              width="100"
              required
              error={errors.password?.message}
              className="w-full"
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              label="Confirm New Password"
              radius="md"
              size="lg"
              required
              error={errors.password?.message}
              className="w-full"
            />
          )}
        />
        <div className="my-2">
          {errors.details ? (
            <Alert
              color="red"
              icon={<IconAlertOctagon />}
              radius="md"
              title={<Text fw={500}>Password Reset Failed</Text>}
              variant="light"
              className="w-72"
            >
              {errors.details.message}
            </Alert>
          ) : null}
        </div>
        <Button
          type="submit"
          loading={isFetching}
          radius="md"
          size="lg"
          variant="gradient"
          gradient={{ from: "#a855f7", to: "#6366f1", deg: 90 }}
        >
          Reset Password
        </Button>
      </div>
    </form>
  );
};
