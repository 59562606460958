import React from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { Accordion, Button } from "@mantine/core";
import { ReviewCollectionSuggestion } from "@common/types";

interface SuggestionCardProps {
  suggestion: ReviewCollectionSuggestion;
  onAccept: (suggestion: ReviewCollectionSuggestion) => void;
  onDecline: (suggestion: ReviewCollectionSuggestion) => void;
}

export const SuggestionCard: React.FC<SuggestionCardProps> = ({
  suggestion,
  onAccept,
  onDecline,
}) => {
  return (
    <Accordion.Item
      key={suggestion.id}
      value={suggestion.id.toString()}
      className="relative bg-white !border-l-4 !border-ar-red rounded-tr-[4px] rounded-br-[4px] mb-1.5"
    >
      <Accordion.Control className="rounded-tr-[4px] rounded-br-[4px] !border-l-4 !border-ar-red">
        <div>
          {suggestion.is_accepted ? (
            <sup className="bg-ar-turquoise text-white font-semibold p-0.5">ACCEPTED</sup>
          ) : suggestion.is_ignored ? (
            <sup className="bg-ar-red text-white font-semibold p-0.5">DECLINED</sup>
          ) : null}
        </div>
        <div className="font-semibold pb-2">{suggestion.criteria_name}</div>
        <span className="text-base">{suggestion.comment}</span>
      </Accordion.Control>
      <Accordion.Panel>
        <ReactDiffViewer
          oldValue={suggestion.search_text}
          newValue={suggestion.replace_text}
          splitView={false}
          compareMethod={DiffMethod.WORDS}
          hideLineNumbers={true}
          styles={{
            marker: { display: "none" },
            wordDiff: {
              wordBreak: "break-word",
              fontFamily: "Commissioner",
            },
            titleBlock: { fontFamily: "Commissioner" },
            variables: {
              light: {
                addedBackground: "rgba(64, 224, 208, 0.15)",
                removedBackground: "rgba(179, 38, 30, 0.15)",
              },
            },
          }}
        />
        <div className="grid grid-cols-2 gap-2 mt-5">
          <Button
            disabled={suggestion.is_ignored}
            variant="outline"
            rightSection={<span className="material-symbols-outlined text-ar-dark">close</span>}
            onClick={() => onDecline(suggestion)}
            className="!border-ar-red text-ar-dark"
          >
            <span className="text-ar-dark text-lg">Decline</span>
          </Button>
          <Button
            disabled={suggestion.is_accepted}
            variant="outline"
            rightSection={<span className="material-symbols-outlined text-ar-dark">check</span>}
            onClick={() => onAccept(suggestion)}
            className="!border-ar-turquoise"
          >
            <span className="text-ar-dark text-lg">Accept</span>
          </Button>
        </div>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
